import {
  ComputedRef,
  Ref,
  computed,
} from 'vue';

import useStore from '@/store/useStore';
import useViewState from '@/composables/useViewState';
import { TDomainError } from '@/types';
import { createBillingAccount } from '@/contexts/billingContext/services';
import { EBillingPlanCode } from '@/contexts/billingContext/domain/constants';
import { EBillingPlanSelectionSteps } from '@/components/billing/BillingPlanSelectionModal/domain/constants';
import { TChangePlanSelectionStepFunction } from '@/components/billing/BillingPlanSelectionModal/domain/types';

import { TBillingShipperForm } from '../components/BillingShipperForm/domain/types';
import { TBillingCarrierForm } from '../components/BillingCarrierForm/domain/types';
import BillingShipperForm from '../components/BillingShipperForm/index.vue';
import BillingCarrierForm from '../components/BillingCarrierForm/index.vue';

type TUseView = {
  shipperFormRef: Ref<InstanceType<typeof BillingShipperForm> | null>,
  carrierFormRef: Ref<InstanceType<typeof BillingCarrierForm> | null>,
  planCode: Ref<EBillingPlanCode | null>,
  isShipperFormShown: ComputedRef<boolean>,

  emit: (event: 'close', ...args: unknown[]) => void,
  changePlanSelectionStep: TChangePlanSelectionStepFunction,
};

export const useConnectPlan = (
  {
    shipperFormRef,
    carrierFormRef,
    planCode,
    isShipperFormShown,

    emit,
    changePlanSelectionStep,
  }: TUseView,
) => {
  const store = useStore();

  const { isLoading, setViewStateAs } = useViewState();

  const isSubmitDisabled = computed(
    () => !!(shipperFormRef.value?.isSubmitDisabled || carrierFormRef.value?.isSubmitDisabled),
  );

  const onSubmit = () => {
    const planCodeValue = planCode.value;

    if (!planCodeValue) {
      return;
    }

    setViewStateAs.loading();

    if (isShipperFormShown.value) {
      shipperFormRef.value?.onSubmit()
        .then((payload: TBillingShipperForm) => (createBillingAccount({
          planCode: planCodeValue,
          ...payload,
        }))
          .then(() => {
            if (planCode.value !== EBillingPlanCode.cargoOwner) {
              changePlanSelectionStep(EBillingPlanSelectionSteps.completeConnection);
            } else {
              store.dispatch('tenants/fetchCurrentTenantInfo');

              emit('close');
            }
          })
          .catch((errors: TDomainError | null) => {
            if (errors) {
              shipperFormRef.value?.setResponseErrors(errors);
            }

            return Promise.reject();
          }),
        )
        .catch(() => {})
        .finally(setViewStateAs.done);
    } else {
      carrierFormRef.value?.onSubmit()
        .then((payload: TBillingCarrierForm) => createBillingAccount({
          planCode: planCodeValue,
          ...payload,
        })
          .then(() => {
            changePlanSelectionStep(EBillingPlanSelectionSteps.completeConnection);
          })
          .catch((errors: TDomainError | null) => {
            if (errors) {
              carrierFormRef.value?.setResponseErrors(errors);
            }

            return Promise.reject();
          }))
        .catch(() => {})
        .finally(setViewStateAs.done);
    }
  };

  return {
    isLoading,
    isSubmitDisabled,

    onSubmit,
  };
};
