import { computed } from 'vue';

import useStore from '@/store/useStore';
import tt from '@/i18n/utils/translateText';
import { getShortPlan } from '@/domains/billing/getShortPlan';
import { formatDate } from '@/utils/dateUtils';
import { DATE_FORMAT } from '@/constants';
import { EBillingPlanCode, EBillingPlanCodeStatus } from '@/contexts/billingContext/domain/constants';

export const useView = () => {
  const store = useStore();

  const currentTenantInfo = computed(() => store.state.tenants.currentTenantInfo);

  const subscriptionsRequest = computed(() => currentTenantInfo.value?.subscriptionRequest || null);
  const subscriptions = computed(() => currentTenantInfo.value?.subscriptions || []);
  const subscriptionWithPendingStatus = computed(() => subscriptions.value.find(
    (subscription) => subscription.status === EBillingPlanCodeStatus.pending) || null,
  );

  const descriptionText = computed(() => {
    if (subscriptionWithPendingStatus.value) {
      const { subscriptionAt } = subscriptionWithPendingStatus.value;
      const { planCode } = subscriptionWithPendingStatus.value;
      // здесь проверяем через startsWith, потому что при создании подписки для ГВ менеджером tms через интерфейс Лаго planCode может быть кастомным, но начинаться будет с EBillingPlanCode.cargoOwner, например, tms.plan.cargo_owner.sibur. В этом случае сравнение делаем через startsWith
      if (planCode?.startsWith(EBillingPlanCode.cargoOwner)) {
        return tt('billing.paywallMessage.yourPlanForCargoOwnerWillStartFrom', { param: { date: formatDate(subscriptionAt || '', { outputFormat: DATE_FORMAT.dayMonthYear }) } });
      }
      const planName = planCode && tt(`billing.planCode.${getShortPlan(planCode)}`);

      return tt('tenant.shared.verificationAndSubscriptionWarning.currentPlanStartedAt', {
        param: {
          name: planName,
          date: formatDate(subscriptionAt || '', { outputFormat: DATE_FORMAT.dayMonthYear }),
        },
      });
    }

    // Есть запрос на подписку
    if (subscriptionsRequest.value) {
      const { subscriptionAt } = subscriptionsRequest.value;
      const { planCode } = subscriptionsRequest.value;

      // Запрос на подписку можно создать только при выборе тарифа через модальное окно в интерфейсе tms. В этом случае planCode тарифа ЛК ГВ будет равен EBillingPlanCode.cargoOwner.
      if (planCode === EBillingPlanCode.cargoOwner) {
        return tt('billing.paywallMessage.connectionRequestHasBeenSuccessfullyCompleted');
      }
      const planName = planCode && tt(`billing.planCode.${getShortPlan(planCode)}`);

      return tt('tenant.shared.verificationAndSubscriptionWarning.currentPlanStartedAt', {
        param: {
          name: planName,
          date: formatDate(subscriptionAt || '', { outputFormat: DATE_FORMAT.dayMonthYear }),
        },
      });
    }

    return tt('tenant.shared.verificationAndSubscriptionWarning.shared.connectPlan');
  });

  return { descriptionText };
};
