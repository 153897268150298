import {
  computed,
  ref,
} from 'vue';

import tt from '@/i18n/utils/translateText';
import { EBillingPlanGroup } from '@/contexts/billingContext/domain/constants';

export const useView = () => {
  const billingPlanGroupType = ref<EBillingPlanGroup>(EBillingPlanGroup.carrier);

  const textForMoreAboutPlan = computed(() => {
    if (billingPlanGroupType.value === EBillingPlanGroup.cargoOwner) {
      return '';
    }
    if (billingPlanGroupType.value === EBillingPlanGroup.shipper) {
      return tt('billing.moreAboutPlan');
    }
    return tt('billing.modal.detailedComparisonAndDetailingOfPlans');
  });

  const planSelectionCards = computed(() => {
    switch (billingPlanGroupType.value) {
      case EBillingPlanGroup.carrier:
        return 'BillingCardsForCarrier';
      case EBillingPlanGroup.freightForwarder:
        return 'BillingCardsForFreightForwarder';
      case EBillingPlanGroup.shipper:
        return 'BillingCardsForShipper';
      case EBillingPlanGroup.cargoOwner:
        return 'BillingCardsForCargoOwner';
      default:
        return 'BillingCardsForCarrier';
    }
  });

  return {
    textForMoreAboutPlan,
    planSelectionCards,
    billingPlanGroupType,
  };
};
