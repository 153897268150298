import { hasProperty, replaceByIndex } from '@/utils';
import { TTableColumns } from '@/types';
import { TTableConfigLoaded } from '@/components/CustomTableLayout/types';

import * as types from './mutation-types';
import {
  EFoldersSection,
  TFoldersMutations,
  TFoldersWithSettings,
} from './types';

const getCurrentFolder = (
  folders: TFoldersWithSettings,
  currentFolderId: string | null) => folders?.find((folder) => folder.id === currentFolderId) || null;

const mutations: TFoldersMutations = {
  [types.SET_CURRENT_FOLDER_ID](state, { section, currentFolderId }) {
    if (!hasProperty(state, section)) {
      console.warn(`There is no section with "${section}" name.`);
      return;
    }
    state[section].currentFolderId = currentFolderId;
  },

  [types.SET_CURRENT_ORDER_IID](state, { section, currentOrderIid }) {
    if (!hasProperty(state, section)) {
      console.warn(`There is no section with "${section}" name.`);
      return;
    }
    if (section === EFoldersSection.orderList) {
      state[section].currentOrderIid = currentOrderIid;
    }
  },

  [types.SET_COLUMN_SETTINGS](state, {
    section,
    columnSettings,
  }: {
    section: EFoldersSection,
    columnSettings: TTableColumns | TTableConfigLoaded,
  }) {
    if (!hasProperty(state, section)) {
      console.warn(`There is no section with "${section}" name.`);
      return;
    }
    const currentFolder = getCurrentFolder(state[section].folders, state[section].currentFolderId);
    const currentFolderIndex = state[section].folders.findIndex((folder) => folder.id === currentFolder?.id);
    if (currentFolderIndex !== -1) {
      const updatedCurrentFolder = {
        ...currentFolder,
        columnSettings,
      };
      state[section] = {
        ...state[section],
        folders: replaceByIndex(state[section].folders, currentFolderIndex, updatedCurrentFolder),
      };
    }
  },

  [types.SET_SORT](state, { section, sort }) {
    if (!hasProperty(state, section)) {
      console.warn(`There is no section with "${section}" name.`);
      return;
    }
    const currentFolder = getCurrentFolder(state[section].folders, state[section].currentFolderId);
    const currentFolderIndex = state[section].folders.findIndex((folder) => folder.id === currentFolder?.id);
    if (currentFolderIndex !== -1) {
      const updatedCurrentFolder = {
        ...currentFolder,
        sort,
      };
      state[section] = {
        ...state[section],
        folders: replaceByIndex(state[section].folders, currentFolderIndex, updatedCurrentFolder),
      };
    }
  },

  [types.SET_FOLDERS](state, { section, folders }) {
    if (!hasProperty(state, section)) {
      console.warn(`There is no section with "${section}" name.`);
      return;
    }
    if (!folders.length) {
      state[section] = {
        currentFolderId: null,
        folders: [],
      };
    } else {
      state[section] = {
        ...state[section],
        folders: folders.map((folder) => {
          const currentFolder = getCurrentFolder(state[section].folders, folder.id);
          if (currentFolder) {
            return {
              ...currentFolder,
              ...folder,
            };
          }
          return {
            ...folder,
            columnSettings: [],
            sort: {
              sort: null,
              sortBy: null,
            },
            quickActions: [],
          };
        }),
      };
    }
  },

  [types.SET_QUICK_ACTIONS](state, { section, quickActions }) {
    if (!hasProperty(state, section)) {
      console.warn(`There is no section with "${section}" name.`);
      return;
    }
    const currentFolder = getCurrentFolder(state[section].folders, state[section].currentFolderId);
    const currentFolderIndex = state[section].folders.findIndex((folder) => folder.id === currentFolder?.id);
    if (currentFolderIndex !== -1) {
      const updatedCurrentFolder = {
        ...currentFolder,
        quickActions,
      };
      state[section] = {
        ...state[section],
        folders: replaceByIndex(state[section].folders, currentFolderIndex, updatedCurrentFolder),
      };
    }
  },

  [types.RESET_STATE](state) {
    state[EFoldersSection.matricesList] = {
      currentFolderId: null,
      folders: [],
    };
    state[EFoldersSection.orderList] = {
      currentFolderId: null,
      currentOrderIid: null,
      folders: [],
    };
    state[EFoldersSection.referencesList] = {
      currentFolderId: null,
      folders: [],
    };
  },
};

export default mutations;
