import * as types from './mutation-types';
import { TPaginationActions } from './types';

const actions: TPaginationActions = {
  setTablePagination: ({ commit }, payload) => {
    commit(types.SET_TABLE_PAGINATION, payload);
  },
  resetAllPagination: ({ commit }) => {
    commit(types.RESET_ALL_PAGINATION);
  },
};

export default actions;
