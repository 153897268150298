import {
  computed,
  watch,
  onMounted,
} from 'vue';

import { EExperimentalFeatures } from '@/domains/constants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import store from '@/store';

export const useAppTestograf = () => {
  const SURVEY_ID = 581572;
  const SURVEY_WIDGET_CLASS = 'ttgraf-widget-trigger';

  const isSurveyVisible = import.meta.env.VITE_APP_TESTOGRAF_ENABLED === 'true';

  const isTestografAvailable = computed(() => checkFeatureIsEnabled(EExperimentalFeatures.testograf));

  const currentUser = computed(() => store.state.user.data);

  const setSurvey = () => {
    if (!isSurveyVisible) return;
    const surveyWidget = document.querySelector(`.${SURVEY_WIDGET_CLASS}`) as HTMLElement;
    const canSetParamsForTestograf = isTestografAvailable.value && !surveyWidget;

    // Перове подключение опроса
    if (canSetParamsForTestograf && window.ttgrafSetWidgetParams) {
      window.ttgrafSetWidgetParams({ id: SURVEY_ID });
    }

    // Отображаем виджет в зависимтости от фф
    if (surveyWidget) {
      surveyWidget.style.display = isTestografAvailable.value ? 'flex' : 'none';
    }
  };

  const setSurveyParams = () => {
    window.testograf = {
      params: {
        e: currentUser.value?.email,
        phone: currentUser.value?.phone,
      },
    };
  };

  watch(isTestografAvailable, setSurvey);

  watch(currentUser, setSurveyParams);

  onMounted(setSurvey);
};
