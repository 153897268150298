import { computed } from 'vue';

import { canCreateTenantsByRole, canManageTenantsByRole } from '@/domains/permissions/role/tenants';
import { canBrowseEmployeesByRole } from '@/domains/permissions/role/employees';
import { concatFullname } from '@/domains/concatFullname';
import useStore from '@/store/useStore';
import { joinString } from '@/utils';
import { TTenantEmployeeRole } from '@/types';

export const useView = () => {
  const store = useStore();

  const currentTenant = computed(() => store.state.tenants.currentTenant);
  const employee = computed(() => currentTenant.value?.employee);
  const currentUser = computed(() => store.getters['user/currentUser']);
  const availableUserTenants = computed(() => store.getters['tenants/availableUserTenants']);

  const fullName = computed(() => concatFullname(employee.value));
  const email = computed(() => currentUser.value?.email || '');
  const showOrganizations = computed(() => !currentUser.value?.ownerTenantId);
  const showSettingsButton = computed(() => canManageTenantsByRole() && employee.value?.id);
  const employeeRoles = computed(() => joinString(employee.value?.roles.map((role: TTenantEmployeeRole) => role.name), ', '));
  const showEmployeeProfileLink = computed(() => employee.value?.id && canBrowseEmployeesByRole());
  const isAddTenantButtonShown = computed(() => canCreateTenantsByRole());

  return {
    isAddTenantButtonShown,
    currentTenant,
    employee,
    availableUserTenants,
    fullName,
    email,
    showOrganizations,
    showSettingsButton,
    employeeRoles,
    showEmployeeProfileLink,
  };
};
