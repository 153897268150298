import { TCustomError } from '@/api/types';
import { TErrorsNotificationOptions } from '@/types';
import { notification } from '@/utils';
import tt from '@/i18n/utils/translateText';

const showErrorNotification = (options?: TErrorsNotificationOptions, error?: TCustomError) => {
  if (!options?.showError) return;

  const { message: optionsMessage, description: optionsDescription } = options;
  let message = optionsMessage || tt('shared.error.general');
  let description = '';

  if (typeof options?.transform === 'function') {
    const { message: messageFromTransform, description: descriptionFromTransform } = options.transform(error?.message);
    message = messageFromTransform || '';
    description = descriptionFromTransform || '';
    if (!message && !description) {
      message = tt('shared.error.general');
    }
  } else if (options?.serverErrorFirst) {
    /**
     * если serverErrorFirst = true, то сначала пробуем достать текст ошибки из ответа
     */
    description = error?.message || optionsDescription || tt('shared.retryLater');
  } else {
    description = optionsDescription || error?.message || tt('shared.retryLater');
  }

  notification.error({
    message,
    description,
  });
};

export default showErrorNotification;
