import {
  ComputedRef,
  computed,
} from 'vue';
import { RouteLocationNormalizedLoaded, Router } from 'vue-router';
import { _UnwrapAll } from 'pinia';

import {
  getFolders,
  getFolderInfo,
} from '@/contexts/orderingContext/services';
import { TCustomStore } from '@/store/types';
import {
  TFolderInfoResponse,
  TNewFolder,
  TNewFolderResponse,
} from '@/types';
import { getMatricesColumnsConfig } from '@/contexts/rateManagementContext/services';
import { EFoldersSection } from '@/store/modules/folders/types';
import { getIsTenantWithCustomization } from '@/domains/getIsTenantWithCustomization';
import { getContractorInfo } from '@/contexts/contractorsContext/services';
import { TReachedResponse } from '@/api/types';
import { TContractorInfo } from '@/components/layouts/domain/types';
import isTenantVerified from '@/domains/isTenantVerified';
import hasTrackRunsPermission from '@/domains/permissions/subscription/hasTrackRunsPermission';
import { canBrowseMatricesByRole } from '@/domains/permissions/role/matrices';
import { EExperimentalFeatures } from '@/domains/constants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import {
  hasCargoOwnerWorkplaceSubscription,
  hasMakeFreightRequestsPermission,
  hasShipperWorkplaceSubscription,
} from '@/domains/permissions/subscription';
import { getCustomReferencesColumnsConfig } from '@/contexts/referencesContext/services';
import { canBrowseOrdersSectionByRole } from '@/domains/permissions/role/orders';
import { canBrowseRunsByRole } from '@/domains/permissions/role/runs';
import { SET_CURRENT_FOLDER_ID } from '@/store/modules/folders/mutation-types';
import canBrowseCustomReferencesByRole from '@/domains/permissions/role/customReferences/canBrowseCustomReferencesByRole';
import { ICustomizationStore } from '@/stores/customization/types';

type TUseFetchDataArguments = {
  folderId: ComputedRef<string>,
  store: TCustomStore,
  router: Router,
  route: RouteLocationNormalizedLoaded,
  customizationStore: _UnwrapAll<ICustomizationStore>,
};

export const useFetchData = (
  {
    folderId,
    store,
    router,
    route,
    customizationStore,
  }: TUseFetchDataArguments,
) => {
  const isCustomReferencesAvailable = computed(() => checkFeatureIsEnabled(EExperimentalFeatures.customReferences));
  const isNewSubscriptionsFeaturesAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March),
  );

  const fetchFolderColumnsConfig = (folderId: string, section: EFoldersSection) => {
    switch (section) {
      case EFoldersSection.matricesList:
        return getMatricesColumnsConfig(folderId).then((response) => {
          if (!response.data) return;

          store.dispatch('folders/setColumnSettings', {
            section,
            columnSettings: response.data,
          });
        });
      case EFoldersSection.referencesList:
        return getCustomReferencesColumnsConfig(folderId).then((response) => {
          if (!response.data) return;

          store.dispatch('folders/setColumnSettings', {
            section,
            columnSettings: response.data,
          });
        });
      default: {
        return Promise.reject();
      }
    }
  };

  const fetchFolderInfo = (folderId: string, section: EFoldersSection) => {
    getFolderInfo(folderId)
      .then((response: TReachedResponse<TFolderInfoResponse>) => {
        if (!response.data) return;
        store.dispatch('folders/setQuickActions', {
          section,
          quickActions: response.data.quickActions,
        });
      });
  };

  const openFirstFolder = () => {
    const isOpenOrdersFolder = route.path === '/orders/only_orders';
    const { currentFolderId } = store.state.folders[EFoldersSection.orderList];
    // Если открыты заказы - по дефолту выбираем первую папку
    if (isOpenOrdersFolder) {
      const firstFolderId = store.state.folders[EFoldersSection.orderList].folders[0].id;
      // Если папка не выбрана - то сетим первую и получаем данные папки
      if (!currentFolderId) {
        router.replace({ query: { folderId: firstFolderId } });
        store.commit(`folders/${SET_CURRENT_FOLDER_ID}`, {
          section: EFoldersSection.orderList,
          currentFolderId: firstFolderId,
        });
        fetchFolderInfo(firstFolderId, EFoldersSection.orderList);
      } else {
        // Если папка выбрана - получаем данные папки
        fetchFolderInfo(currentFolderId, EFoldersSection.orderList);
      }
    }
  };

  const fetchNewOrderFolders = () => {
    getFolders()
      .then((response: TReachedResponse<TNewFolderResponse>) => {
        if (!response.data) return;

        store.dispatch('folders/setFolders', {
          section: EFoldersSection.orderList,
          folders: response.data.map((folder: TNewFolder) => ({
            id: folder.slug,
            name: folder.title,
          })),
        });
        openFirstFolder();
      });
  };

  const fetchMatrices = () => {
    // Запрашиваем матрицы только для тенанта с кастомизацией и при наличии доступа к разделу
    if (getIsTenantWithCustomization() && canBrowseMatricesByRole()) {
      const isOpenMatricesFolder = route.path === '/matrices' && folderId.value;

      customizationStore.loadMatrices()
        .then(() => {
          store.dispatch('folders/setFolders', {
            section: EFoldersSection.matricesList,
            folders: customizationStore.matrices.map((matrix) => ({
              id: matrix.id,
              name: matrix.name,
            })),
          });

          store.dispatch('filter/setSubsections', {
            section: 'matricesListView',
            subSections: customizationStore.matrices.map((matrix) => matrix.id),
          });

          if (isOpenMatricesFolder) {
            store.commit(`folders/${SET_CURRENT_FOLDER_ID}`, {
              section: EFoldersSection.matricesList,
              currentFolderId: folderId.value,
            });

            fetchFolderColumnsConfig(folderId.value, EFoldersSection.matricesList);
          }
        })
        .catch(() => {});
    }
  };

  const fetchCustomReferences = () => {
    // Запрашиваем кастомные справочники только для тенанта с кастомизацией и при наличии доступа к разделу
    // TODO: заменить canBrowseMatricesByRole на доступ к справочникам, когда для них появится ролевая модель
    if (getIsTenantWithCustomization() && canBrowseCustomReferencesByRole() && isCustomReferencesAvailable.value) {
      const isOpenCustomReferencesFolder = route.path === '/references' && folderId.value;

      customizationStore.loadCustomReferences()
        .then(() => {
          store.dispatch('folders/setFolders', {
            section: EFoldersSection.referencesList,
            folders: customizationStore.customReferences.map((customReference) => ({
              id: customReference.id,
              name: customReference.name,
            })),
          });

          store.dispatch('filter/setSubsections', {
            section: 'referencesListView',
            subSections: customizationStore.customReferences.map((customReference) => customReference.id),
          });

          if (isOpenCustomReferencesFolder) {
            store.commit(`folders/${SET_CURRENT_FOLDER_ID}`, {
              section: EFoldersSection.referencesList,
              currentFolderId: folderId.value,
            });

            fetchFolderColumnsConfig(folderId.value, EFoldersSection.referencesList);
          }
        })
        .catch(() => {});
    }
  };

  const fetchRunsCountInfo = () => {
    const isCargoOwnerWorkplace = isNewSubscriptionsFeaturesAvailable.value
      ? hasCargoOwnerWorkplaceSubscription()
      : hasMakeFreightRequestsPermission();

    const isShipperWorkplace = isNewSubscriptionsFeaturesAvailable.value
      ? hasShipperWorkplaceSubscription()
      : hasTrackRunsPermission();

    // Если тенант не верифицирован или нет доступа, то инфо не запрашиваем
    if (!isTenantVerified() || !(isCargoOwnerWorkplace || isShipperWorkplace) || !canBrowseRunsByRole()) return;

    getContractorInfo().then((response: TReachedResponse<TContractorInfo>) => {
      if (!response.data) return;

      store.dispatch('contractorInfo/setCurrentRunsCount', { count: response.data.onExecutionRunsCount });
      store.dispatch('contractorInfo/setPlannedRunsCount', { count: response.data.plannedRunsCount });
      store.dispatch('contractorInfo/setFinishedRunsCount', { count: response.data.completedRunsCount });
      store.dispatch('contractorInfo/setIncidentsCount', { count: response.data.incidentsCount });
      store.dispatch('contractorInfo/setCanSeeRunIncidents', { canSeeRunIncidents: response.data.canSeeRunIncidents });
    });
  };

  const fetchFolders = () => {
    const isCargoOwnerWorkplace = isNewSubscriptionsFeaturesAvailable.value
      ? hasCargoOwnerWorkplaceSubscription()
      : hasMakeFreightRequestsPermission();

    // TODO: после удаления FF isNewSubscriptionsFeaturesAvailable перенести проверки внутрь fetch функции
    if (isCargoOwnerWorkplace && canBrowseOrdersSectionByRole()) {
      fetchNewOrderFolders();
    }
    fetchMatrices();
    fetchCustomReferences();
  };

  return {
    fetchFolderColumnsConfig,
    fetchFolderInfo,
    fetchFolders,
    fetchRunsCountInfo,
  };
};
