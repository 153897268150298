<template>
  <div class="subscription-warning">
    <div class="subscription-warning__container">
      <div class="subscription-warning__description">
        {{ descriptionText }}
      </div>
      <div class="subscription-warning__divider" />
      <div class="subscription-warning__contacts">
        <SmartLink :href="`tel:${phone}`">
          {{ phone }}
        </SmartLink>
        <span class="subscription-warning__contacts-point" />
        <SmartLink :href="`mailto:${email}`">
          {{ email }}
        </SmartLink>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import tt from '@/i18n/utils/translateText';
import useAppTheme from '@/composables/application/useAppTheme';
import SmartLink from '@/components/SmartLink/index.vue';

import { useView } from './application/useView';

export default defineComponent({
  name: 'SubscriptionWarning',
  components: { SmartLink },
  setup() {
    const { getThemeConfig } = useAppTheme();
    const { support } = getThemeConfig();

    const { descriptionText } = useView();

    return {
      phone: support.phone,
      email: support.email,
      descriptionText,

      tt,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
