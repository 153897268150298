import { httpMethods } from '@/api';
import { sendApiRequest } from '@/api/sendApiRequest';
import {
  TDocumentResponse,
  TDocumentsResponse,
  TFileUploadInfo,
  TPagination,
} from '@/types';
import {
  TPartnershipGroupResponse,
  TIncomingInvitationsResponse,
  TPartnershipDocumentRequest,
  TPartnershipsResponse,
  TContractResponse,
  TContractsResponse,
  TPartnershipsStatisticsResponse,
  TPartnershipStatisticsResponse,
  TCreateContractRequest,
  TProlongContractRequest,
  TPartnerCandidatesResponse,
  TPartnershipResponse,
  TContractTypesResponse,
  TContractCustomColumnsResponse,
  TContractCustomAttributesSchemaResponse,
  TContractCustomAttributesFormSchemaResponse,
  TPartnershipPartnerContactsResponse,
} from '@/contexts/partnershipsContext/domain/types';
import { VEHICLE_UNIT_BY_TYPE } from '@/contexts/resourcesControlContext/domain/constants';
import tt from '@/i18n/utils/translateText';
import { EContractsTableFilter } from '@/contexts/partnershipsContext/components/ContractsTable/domain/types';
import { EPartnershipsInvitationType } from '@/contexts/partnershipsContext/views/PartnershipsListView/domains/types';
import { TPartnershipsRequest } from '@/contexts/partnershipsContext/views/PartnershipsListView/components/PartnershipsPartners/types';
import { EAcceptanceMethod, EPartnershipType } from '@/contexts/partnershipsContext/domain/constants';

export function inviteToPartnership(partnerId: string, initiatorRole: EPartnershipType) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: '/:tenant_id/outgoing_invitations',
    payload: {
      partnerId,
      initiatorRole,
    },
  });
}

export function addExternalNumberForOutgoingInvitation(
  invitationId: string,
  externalNumber: string,
) {
  return sendApiRequest({
    method: httpMethods.PATCH,
    endpoint: `:tenant_id/outgoing_invitations/${invitationId}/external_number`,
    payload: { externalNumber },
    requestOptions: {
      showError: true,
      message: tt('partnerships.shared.errors.addExternalNumber'),
    },
  });
}

export function acceptInvitation(invitationId: string) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: `/:tenant_id/incoming_invitations/${invitationId}/acceptance`,
    requestOptions: {
      showError: true,
      message: tt('partnerships.shared.errors.acceptInvitation'),
    },
  });
}

export function rejectInvitation(invitationId: string) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: `/:tenant_id/incoming_invitations/${invitationId}/rejection`,
    requestOptions: {
      showError: true,
      message: tt('partnerships.shared.errors.rejectInvitation'),
    },
  });
}

export function revokeInvitation(invitationId: string) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: `/:tenant_id/outgoing_invitations/${invitationId}/revocation`,
    requestOptions: {
      showError: true,
      message: tt('partnerships.shared.errors.revokeInvitation'),
    },
  });
}

export function fetchIncomingInvitations({ pageItems = 10, currentPage }: TPagination) {
  return sendApiRequest<TIncomingInvitationsResponse>({
    method: httpMethods.GET,
    endpoint: '/:tenant_id/incoming_invitations',
    params: {
      items: pageItems,
      page: currentPage,
    },
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
}

export function fetchOutgoingInvitations({ pageItems = 10, currentPage }: TPagination) {
  return sendApiRequest<TIncomingInvitationsResponse>({
    method: httpMethods.GET,
    endpoint: '/:tenant_id/outgoing_invitations',
    params: {
      items: pageItems,
      page: currentPage,
    },
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
}

export const fetchPartnerCandidates = (
  query: string,
  role: string,
) => sendApiRequest<TPartnerCandidatesResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/autocomplete/partner_candidates',
  params: {
    query,
    role,
  },
});

export const fetchPartnerships = ({ pagination, role, groupIds }: TPartnershipsRequest) => sendApiRequest<TPartnershipsResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/partnerships',
  params: {
    items: pagination.pageItems,
    page: pagination.currentPage,
    role,
    groupIds,
  },
  requestOptions: {
    showError: true,
    message: tt('partnerships.shared.errors.fetchPartnerships'),
  },
});

export const fetchPartnershipInfo = (partnershipId: string) => sendApiRequest<TPartnershipResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/partnerships/${partnershipId}`,
  requestOptions: {
    showError: true,
    message: tt('partnerships.shared.errors.fetchPartnershipInfo'),
  },
});

export const fetchPartnershipsStatistics = () => sendApiRequest<TPartnershipsStatisticsResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/partnerships_statistics',
});

export const fetchPartnershipStatistics = ({ partnershipId }) => sendApiRequest<TPartnershipStatisticsResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/partnerships/${partnershipId}/statistics`,
});

export const fetchVehiclesForPartnership = ({ type, pagination, partnershipId }: {
  type: any, pagination: TPagination, partnershipId: string,
}) => {
  const vehicleUnitType = VEHICLE_UNIT_BY_TYPE[type];
  if (!vehicleUnitType) return Promise.reject();

  return sendApiRequest({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/partnerships/${partnershipId}/partnership_vehicles`,
    params: {
      items: pagination.pageItems,
      page: pagination.currentPage,
      vehicleUnitType,
    },
  });
};

export const rejectDriver = (partnershipId: string, driverId: string) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/partnerships/${partnershipId}/reject_driver`,
  params: { driverId },
});

export const fetchPartnershipDrivers = (
  partnershipId: string,
  {
    pageItems,
    currentPage,
  }: TPagination,
) => sendApiRequest({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/partnerships/${partnershipId}/partnership_drivers`,
  params: {
    items: pageItems,
    page: currentPage,
  },
});

export const fetchDataForInvitationList = ({ type, pagination }: { type: any, pagination: TPagination }) => {
  switch (type) {
    case EPartnershipsInvitationType.incoming:
      return fetchIncomingInvitations(pagination);
    case EPartnershipsInvitationType.outgoing:
      return fetchOutgoingInvitations(pagination);
    default:
      return Promise.reject();
  }
};

export const requestInvitationAction = ({ type, invitationId }: { type: string, invitationId: string }) => {
  switch (type) {
    case 'accept':
      return acceptInvitation(invitationId);
    case 'reject':
      return rejectInvitation(invitationId);
    case 'revocate':
      return revokeInvitation(invitationId);
    default:
      return Promise.reject();
  }
};

export function fetchPartnerGroups() {
  return sendApiRequest<TPartnershipGroupResponse>({
    method: httpMethods.GET,
    endpoint: '/:tenant_id/partnerships/groups',
  });
}

export function createPartnerGroup(name: string) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: '/:tenant_id/partnerships/groups',
    params: { name },
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
}

export function editPartnerGroup(groupId: string, name: string) {
  return sendApiRequest({
    method: httpMethods.PUT,
    endpoint: `/:tenant_id/partnerships/groups/${groupId}`,
    params: { name },
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
}

export function deletePartnerGroup(groupId: string) {
  return sendApiRequest({
    method: httpMethods.DELETE,
    endpoint: `/:tenant_id/partnerships/groups/${groupId}`,
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
}

export function addPartnerToGroup(groupId: string, partnerId: string) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: `/:tenant_id/partnerships/${partnerId}/partnership_group`,
    params: { groupId },
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
      message: tt('partnerships.shared.errors.addPartnerToGroup'),
    },
  });
}

export function removePartnerFromGroup(groupId: string, partnerId: string) {
  return sendApiRequest({
    method: httpMethods.DELETE,
    endpoint: `/:tenant_id/partnerships/${partnerId}/partnership_group`,
    params: { groupId },
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
      message: tt('partnerships.shared.errors.removePartnerFromGroup'),
    },
  });
}

export function moveDriverToStopList(driverId: string, partnerId: string) {
  return sendApiRequest({
    method: httpMethods.DELETE,
    endpoint: `/:tenant_id/partnerships/${partnerId}/partnership_drivers/${driverId}/acceptance`,
  });
}

export function returnDriverFromStopList(driverId: string, partnerId: string) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: `/:tenant_id/partnerships/${partnerId}/partnership_drivers/${driverId}/acceptance`,
  });
}

export function pauseDriver(driverId: string, partnerId: string) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: `/:tenant_id/partnerships/${partnerId}/partnership_drivers/${driverId}/pause`,
  });
}

export function unpauseDriver(driverId: string, partnerId: string) {
  return sendApiRequest({
    method: httpMethods.DELETE,
    endpoint: `/:tenant_id/partnerships/${partnerId}/partnership_drivers/${driverId}/pause`,
  });
}

export function moveVehicleToStopList({ vehicleId, partnershipId }: { vehicleId: string, partnershipId: string }) {
  return sendApiRequest({
    method: httpMethods.DELETE,
    endpoint: `/:tenant_id/partnerships/${partnershipId}/partnership_vehicles/${vehicleId}/acceptance`,
  });
}

export function returnVehicleFromStopList({ vehicleId, partnershipId }: { vehicleId: string, partnershipId: string }) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: `/:tenant_id/partnerships/${partnershipId}/partnership_vehicles/${vehicleId}/acceptance`,
  });
}

export function pauseVehicle({ vehicleId, partnershipId }: { vehicleId: string, partnershipId: string }) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: `/:tenant_id/partnerships/${partnershipId}/partnership_vehicles/${vehicleId}/pause`,
  });
}

export function unpauseVehicle({ vehicleId, partnershipId }: { vehicleId: string, partnershipId: string }) {
  return sendApiRequest({
    method: httpMethods.DELETE,
    endpoint: `/:tenant_id/partnerships/${partnershipId}/partnership_vehicles/${vehicleId}/pause`,
  });
}

export function pausePartnership(partnershipId: string) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: `/:tenant_id/partnerships/${partnershipId}/pause`,
  });
}

export function unpausePartnership(partnershipId: string) {
  return sendApiRequest({
    method: httpMethods.DELETE,
    endpoint: `/:tenant_id/partnerships/${partnershipId}/pause`,
  });
}

export function cancelPartnership(partnershipId: string) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: `/:tenant_id/partnerships/${partnershipId}/cancellation`,
  });
}

export const getPartnershipsUploadingUrl = (
  {
    partnerId,
    filename,
    type,
  }: {
    partnerId: string,
    filename: string,
    type: any,
  },
) => sendApiRequest({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/partnerships/${partnerId}/upload`,
  params: {
    filename,
    type,
  },
});

export const attachFileToPartnership = ({ file, partnerId }: { partnerId: string, file: any }) => sendApiRequest({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/partnerships/${partnerId}/attachments`,
  payload: { file },
});

export const fetchPartnershipVehicleInformation = (
  {
    partnershipId,
    vehicleId,
  }: {
    vehicleId: string,
    partnershipId: string,
  },
) => sendApiRequest({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/partnerships/${partnershipId}/partnership_vehicles/${vehicleId}`,
});

export const fetchPartnershipDriverInformation = (
  {
    partnershipId,
    driverId,
  }: {
    driverId: string,
    partnershipId: string,
  },
) => sendApiRequest({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/partnerships/${partnershipId}/partnership_drivers/${driverId}`,
});

export function fetchPartnershipDocuments(partnershipId: string) {
  return sendApiRequest<TDocumentsResponse>({
    method: httpMethods.GET,
    endpoint: `/:tenant_id/partnerships/${partnershipId}/documents`,
  });
}

export function uploadPartnershipDocuments(partnershipId: string, payload: TPartnershipDocumentRequest) {
  return sendApiRequest<TDocumentResponse>({
    method: httpMethods.POST,
    endpoint: `/:tenant_id/partnerships/${partnershipId}/documents`,
    payload,
    requestOptions: {
      showError: true,
      message: tt('partnerships.shared.errors.uploadPartnershipDocuments'),
    },
  });
}

export function deletePartnershipDocument(partnershipId: string, documentId: string) {
  return sendApiRequest({
    method: httpMethods.DELETE,
    endpoint: `/:tenant_id/partnerships/${partnershipId}/documents/${documentId}`,
  });
}

export const fetchPartnerContacts = (
  partnershipId: string,
  {
    pageItems,
    currentPage,
  }: TPagination,
) => sendApiRequest<TPartnershipPartnerContactsResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/partnerships/${partnershipId}/contacts`,
  params: {
    items: pageItems,
    page: currentPage,
  },
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const getContracts = (
  {
    pagination: {
      pageItems = 10,
      currentPage,
    },
    status = EContractsTableFilter.all,
    partnershipId,
  }: {
    pagination: TPagination,
    status: keyof typeof EContractsTableFilter,
    partnershipId?: string,
  },
) => {
  const params: {
    items: number,
    page: number,
    status: keyof typeof EContractsTableFilter,
    partnershipId?: string,
  } = {
    items: pageItems,
    page: currentPage,
    status,
  };

  if (partnershipId) {
    params.partnershipId = partnershipId;
  }

  return sendApiRequest<TContractsResponse>({
    method: httpMethods.GET,
    endpoint: '/:tenant_id/contracts',
    requestOptions: {
      showError: true,
      message: tt('partnerships.shared.errors.fetchContracts'),
    },
    params,
  });
};

export const getContractTypes = () => sendApiRequest<TContractTypesResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/contracts/contract_types',
});

export const getContract = (
  { contractId }: { contractId: string },
) => sendApiRequest<TContractResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/contracts/${contractId}`,
});

export const archiveContract = (
  { contractId }: { contractId: string },
) => sendApiRequest({
  method: httpMethods.DELETE,
  endpoint: `/:tenant_id/contracts/${contractId}`,
});

export const unarchiveContract = (
  { contractId }: { contractId: string },
) => sendApiRequest<{ id: string }>({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/contracts/${contractId}/recover`,
});

export function createContract({ partnershipId, ...payload }: TCreateContractRequest) {
  return sendApiRequest<{ id: string }>({
    method: httpMethods.POST,
    endpoint: `/:tenant_id/partnerships/${partnershipId}/contracts`,
    payload,
  });
}

export const editContract = (
  { partnershipId, ...payload }: TCreateContractRequest,
  contractId: string,
) => sendApiRequest<{ id: string }>({
  method: httpMethods.PUT,
  endpoint: `/:tenant_id/partnerships/${partnershipId}/contracts/${contractId}`,
  payload,
});

export function prolongContract(contractId: string, payload: TProlongContractRequest) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: `/:tenant_id/contracts/${contractId}/prolong`,
    payload,
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
}

export const updatePartnershipExternalNumber = (
  partnershipId: string,
  externalNumber: string,
) => sendApiRequest<{ id: string }>({
  method: httpMethods.PUT,
  endpoint: `/:tenant_id/partnerships/${partnershipId}/external_number`,
  payload: { externalNumber },
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const getContractDocuments = (contractId: string) => sendApiRequest<TDocumentsResponse>({
  method: httpMethods.GET,
  endpoint: `/:tenant_id/contracts/${contractId}/documents`,
});

export const uploadContractDocument = (
  contractId: string,
  fileData: TFileUploadInfo,
) => sendApiRequest<TDocumentResponse>({
  method: httpMethods.POST,
  endpoint: `/:tenant_id/contracts/${contractId}/documents`,
  payload: { file: fileData },
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const deleteContractDocument = (contractId: string, documentId: string) => sendApiRequest({
  method: httpMethods.DELETE,
  endpoint: `/:tenant_id/contracts/${contractId}/documents/${documentId}`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});

export const getContractCustomAttributesSchema = () => sendApiRequest<TContractCustomAttributesSchemaResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/contracts/custom_attributes',
});

export const getContractCustomAttributesFormSchema = () => sendApiRequest<TContractCustomAttributesFormSchemaResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/contracts/custom_attributes/form_schema',
});

export const getContractCustomColumns = () => sendApiRequest<TContractCustomColumnsResponse>({
  method: httpMethods.GET,
  endpoint: '/:tenant_id/contracts/custom_attributes/columns',
});

export const changeTargetOfferAcceptanceMethod = (
  partnershipId: string,
  targetOfferAcceptanceMethod: EAcceptanceMethod,
) => sendApiRequest<{ id: string }>({
  method: httpMethods.PUT,
  payload: { targetOfferAcceptanceMethod },
  endpoint: `/:tenant_id/partnerships/${partnershipId}/target_offer_acceptance_method`,
  requestOptions: {
    showError: true,
    serverErrorFirst: true,
  },
});
