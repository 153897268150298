import {
  computed,
  ComputedRef,
  ref,
  UnwrapNestedRefs,
  watch,
} from 'vue';
import { RouteLocationNormalizedLoaded, Router } from 'vue-router';
import { _UnwrapAll } from 'pinia';

import { addByIndex, removeByIndex } from '@/utils';
import { TCustomStore } from '@/store/types';
import { useSberButtonVisible } from '@/components/SberBusinessIntegrationButton/composables/useSberButtonVisible';
import { EIntegrationEvent } from '@/components/SberBusinessIntegrationButton/domain/constants';
import { EFoldersSection } from '@/store/modules/folders/types';
import { SET_CURRENT_FOLDER_ID } from '@/store/modules/folders/mutation-types';
import { ICustomizationStore } from '@/stores/customization/types';

import { useFetchData } from './useFetchData';
import { useSiderMenuItems } from './useSiderMenuItems';
import { ECommonSchemaItemType, ECommonSchemaItemKeys } from '../domains/constants';

type TUseInteractWithSiderArguments = {
  state: UnwrapNestedRefs<Record<string, any>>,
  folderId: ComputedRef<string>,
  folderOpenKeys: ComputedRef<any>,
  store: TCustomStore,
  router: Router,
  route: RouteLocationNormalizedLoaded,
  isCurrentTenantVerified: ComputedRef<boolean>,
  customizationStore: _UnwrapAll<ICustomizationStore>,
};

type TSubmenuItemKey = ECommonSchemaItemKeys.orders
| ECommonSchemaItemKeys.matrices
| ECommonSchemaItemKeys.references;

export const useInteractWithSider = (
  {
    state,
    folderId,
    folderOpenKeys,
    store,
    router,
    route,
    isCurrentTenantVerified,
    customizationStore,
  }: TUseInteractWithSiderArguments) => {
  const isCompletedSocialLinking = ref(false);

  const { siderMenuItems } = useSiderMenuItems();

  const { isSberButtonVisible } = useSberButtonVisible(EIntegrationEvent.linking);

  const hasLinkedAccountToSber = computed(() => isSberButtonVisible.value && !isCompletedSocialLinking.value);

  const showMenuItemByType = (
    allowedTypes: ECommonSchemaItemType[],
    type: ECommonSchemaItemType,
  ) => (allowedTypes.includes(type));

  /**
   * Метод устанавливает selectedKeys в зависимости от текущего url.
   * selectedKeys используется для подсветки активного элемента меню сайдбара
   */
  const setSelectedKeys = (routePath: string) => {
    switch (true) {
      case !!folderId.value: {
        state.selectedKeys = [folderId.value];
        break;
      }
      case routePath === '/': {
        state.selectedKeys = ['summary'];
        break;
      }
      case routePath === '/admin': {
        state.selectedKeys = ['admin'];
        break;
      }
      case routePath.startsWith('/admin'): {
        const stringToSubtract = '/admin/';
        const adminKey = `admin_${routePath.slice(stringToSubtract.length).split('/')[0]}`;

        state.selectedKeys = [adminKey];
        break;
      }
      case routePath === '/executors_lookups/feed': {
        state.selectedKeys = ['executors_lookups_feed'];
        break;
      }
      default: {
        const key = routePath.slice(1).split('/')[0];

        state.selectedKeys = [key];
        break;
      }
    }
  };

  const {
    fetchFolderColumnsConfig,
    fetchFolderInfo,
    fetchFolders,
    fetchRunsCountInfo,
  } = useFetchData(
    {
      folderId,
      store,
      router,
      route,
      customizationStore,
    });

  const onNewOrderItemClick = (paramId: string) => {
    const routeFragments = route.path.split('/').filter((item: string) => item);
    let transformedURL = '';

    // Если у url есть дочерние фрагменты, то сохраняем их (например /orders/only_orders)
    if (routeFragments[0] === ECommonSchemaItemKeys.orders) {
      routeFragments.forEach((item) => {
        // TODO: вынести в enum
        if (item === 'orders' || item === 'only_orders' || item === 'with_freight_requests') {
          transformedURL = `${transformedURL}/${item}`;
        }
      });
    } else {
      transformedURL = `/${ECommonSchemaItemKeys.orders}`;
    }

    store.commit(`folders/${SET_CURRENT_FOLDER_ID}`, {
      section: EFoldersSection.orderList,
      currentFolderId: paramId,
    });

    const nextFolderColumnSettings = store.getters['folders/getColumnSettings'](EFoldersSection.orderList, paramId);
    if (nextFolderColumnSettings.length) {
      router.replace({
        path: transformedURL,
        query: { folderId: paramId },
      });
    } else {
      Promise.all([
        fetchFolderInfo(paramId, EFoldersSection.orderList),
      ]).then(() => {
        router.replace({
          path: transformedURL,
          query: { folderId: paramId },
        });
      });
    }
  };

  const onSubmenuItemClick = (itemKey: TSubmenuItemKey, paramId: string) => {
    const transformedURL = `/${itemKey}`;
    const foldersSectionName = `${itemKey}List`;
    const section = EFoldersSection[foldersSectionName];

    store.commit(`folders/${SET_CURRENT_FOLDER_ID}`, {
      section,
      currentFolderId: paramId || null,
    });

    const nextFolderColumnSettings = store.getters['folders/getColumnSettings'](section, paramId);
    if (nextFolderColumnSettings.length) {
      router.replace({
        path: transformedURL,
        query: { folderId: paramId },
      });
    } else {
      Promise.all([
        fetchFolderColumnsConfig(paramId, section),
      ]).then(() => {
        router.replace({
          path: transformedURL,
          query: { folderId: paramId },
        });
      });
    }
  };

  const handleSubmenuItemClick = (itemKey: TSubmenuItemKey, paramId: string) => {
    if (folderId.value === paramId) return;
    if (itemKey === ECommonSchemaItemKeys.orders) {
      onNewOrderItemClick(paramId);
    } else {
      onSubmenuItemClick(itemKey, paramId);
    }
  };

  const handleTitleClick = (key: string) => {
    let openKeys: string[] = [];

    if (state.openKeys.includes(key)) {
      const deletionIndex = state.openKeys.findIndex((selectedKey) => selectedKey === key);

      openKeys = removeByIndex(state.openKeys, deletionIndex);
    } else {
      openKeys = addByIndex(state.openKeys, state.openKeys.length, key);
    }

    store.dispatch('app/setFolderOpenKeys', openKeys);
  };

  const onMenuItemClick = (path: string) => {
    const routeUrl = path.split('/')[1].split('?')[0];
    const folderId = path.split('folderId=')[1];

    // Когда в папке всего один элемент, то редирект по нажатию на пункт меню в сайдбаре происходит напрямую в эту папку, при этом необходимо запросить и сохранить в стор данные по ней
    if (folderId) {
      const foldersSectionName = `${routeUrl}List`;
      const section = EFoldersSection[foldersSectionName];

      store.commit(`folders/${SET_CURRENT_FOLDER_ID}`, {
        section,
        currentFolderId: folderId,
      });
      fetchFolderColumnsConfig(folderId, section);
    }

    router.push(path);
  };

  const handleCompleteSocialLinking = () => {
    isCompletedSocialLinking.value = true;
  };

  watch(() => store.state.tenants.currentTenantInfo?.id, () => {
    // в fetchFolders используются данные из currentTenantInfo
    fetchFolders();
    fetchRunsCountInfo();
  });

  watch(
    () => [route.path, folderId.value],
    () => {
      store.dispatch('notifications/toggleNotificationsListVisible', false);

      setSelectedKeys(route.path);
    },
  );

  watch(() => folderOpenKeys.value, (value) => {
    state.openKeys = value;
  });

  watch(isCurrentTenantVerified, (isVerified: boolean) => {
    if (!isVerified && !state.openKeys.includes('shipper_runs')) {
      handleTitleClick('shipper_runs');
    }
  });

  return {
    isCompletedSocialLinking,
    isSberButtonVisible,
    hasLinkedAccountToSber,
    siderMenuItems,

    setSelectedKeys,
    handleTitleClick,
    handleCompleteSocialLinking,
    fetchFolderColumnsConfig,
    fetchFolders,
    fetchRunsCountInfo,
    showMenuItemByType,
    handleSubmenuItemClick,
    onMenuItemClick,
  };
};
