import { EDigitalQueuePageType, EDigitalQueuePageView } from '@/contexts/contractorsContext/views/DigitalQueueInfoView/domains/constants';
import { TDigitalQueuePermissions } from '@/contexts/contractorsContext/views/DigitalQueueInfoView/domains/types';
import { TDigitalQueueGates } from '@/contexts/contractorsContext/domain/types';

import {
  SET_DIGITAL_QUEUE_ID,
  SET_OPENED_GATES,
  SET_PAGE_TYPE,
  SET_VIEW_MODE,
  SET_GATE_SLOT_DURATION,
  RESET_STATE,
  SET_PERMISSIONS,
  SET_CURRENT_GATES,
} from './mutation-types';
import {
  TDigitalQueueSettingsMutations,
  TDigitalQueueSettingsState,
  TGateSettings,
} from './types';

export const mutations: TDigitalQueueSettingsMutations = {
  [SET_DIGITAL_QUEUE_ID](state: TDigitalQueueSettingsState, id: string) {
    state.currentDigitalQueueId = id;
  },
  [SET_OPENED_GATES](state: TDigitalQueueSettingsState, gates: TGateSettings[]) {
    state.openedGates = gates;
  },
  [SET_CURRENT_GATES](state: TDigitalQueueSettingsState, gates: TDigitalQueueGates[]) {
    state.currentGates = gates;
  },
  [SET_PAGE_TYPE](state: TDigitalQueueSettingsState, pageType: EDigitalQueuePageType) {
    state.pageType = pageType;
  },
  [SET_VIEW_MODE](state: TDigitalQueueSettingsState, viewType: EDigitalQueuePageView) {
    state.viewMode = viewType;
  },
  [SET_GATE_SLOT_DURATION](state: TDigitalQueueSettingsState, { id, slotDuration }: TGateSettings) {
    state.openedGates.find((gate: TGateSettings) => id === gate.id)!.slotDuration = slotDuration;
  },
  [SET_PERMISSIONS](state: TDigitalQueueSettingsState, permissions: TDigitalQueuePermissions | null) {
    state.permissions = permissions;
  },
  [RESET_STATE](state: TDigitalQueueSettingsState) {
    state.currentDigitalQueueId = '';
    state.openedGates = [];
    state.permissions = null;
  },
};
