export enum EOrderInfoDrawerTab {
  orderInfoDrawerInformation = 'OrderInfoDrawerInformation', // Информация
  orderInfoDrawerLookups = 'OrderInfoDrawerLookups', // Подборы
  orderInfoDrawerExecutionRequests = 'OrderInfoDrawerExecutionRequests', // Заявки
  orderInfoDrawerExecutionChecklist = 'ExecutionChecklist', // Заявки
}

export enum EOrderType {
  single = 'single', // Заказ типа one-to-one (один рейс)
  multiple = 'multiple', // Заказ типа one-to-many (больше одного рейса)
}

export enum ECustomAttributeDuplicatedFieldNames {
  railwayCarKind = 'railwayCarKind',
  containerKind = 'containerKind',
  vesselName = 'vesselName',
  vesselImo = 'vesselImo',
}
