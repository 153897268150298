/**
 *
 * @example
 * declensionOfNumber(1, ['рейс', 'рейса', 'рейсов']);
 * // returns рейс
 * @example
 * declensionOfNumber(2, ['рейс', 'рейса', 'рейсов']);
 * // returns рейса
 * @example
 * declensionOfNumber(7, ['рейс', 'рейса', 'рейсов']);
 * // returns рейсов
 * @returns {String} Возвращает склонение слова в зависимости от количества.
 */
export const declensionOfNumber = (number: number, titles: string[]) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
};
