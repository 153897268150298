import { Ref, computed } from 'vue';

import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import {
  CARRIER_PUBLIC_OFFER_LINK,
  EExperimentalFeatures,
  NEW_CARRIER_PUBLIC_OFFER_LINK,
} from '@/domains/constants';
import useStore from '@/store/useStore';

export const useView = (isPublicOfferAccept: Ref<boolean>, isManyEmployeesAlertShown: Ref<boolean>) => {
  const store = useStore();

  const currentTenantInfo = computed(() => store.state.tenants.currentTenantInfo);

  const isBillingValidFrom1MarchAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March));

  const isSubmitDisabled = computed(() => !isPublicOfferAccept.value || isManyEmployeesAlertShown.value);

  const hasActiveSubscription = computed(() => currentTenantInfo.value?.subscriptions?.length !== 0);

  const hasPastSubscriptions = computed(() => currentTenantInfo.value?.hasPastSubscriptions || false);

  const isBillingTrialPeriodInformationAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.billingTrialPeriodInformation),
  );

  const carrierPublicOfferLink = computed(() => (
    isBillingTrialPeriodInformationAvailable.value ? NEW_CARRIER_PUBLIC_OFFER_LINK : CARRIER_PUBLIC_OFFER_LINK
  ));

  const isBillingTrialPeriodInfoShown = computed(
    () => isBillingTrialPeriodInformationAvailable.value
    && !hasPastSubscriptions.value
    && !hasActiveSubscription.value,
  );

  return {
    isSubmitDisabled,
    isBillingValidFrom1MarchAvailable,
    isBillingTrialPeriodInfoShown,
    carrierPublicOfferLink,
  };
};
