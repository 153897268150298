import { useRoute } from 'vue-router';
import { computed } from 'vue';

import store from '@/store';
import { useView as useViewSiderContent } from '@/components/layouts/AppLayoutWrapper/components/AppLayoutSider/components/TmsSiderContent/application/useView';

import { useView } from './useView';
import { TCommonSchemaItem, TStaticSchemaItem } from '../domains/types';
import { ECommonSchemaItemKeys } from '../domains/constants';

export const useSiderMenuItems = () => {
  const route = useRoute();

  const {
    orderedMenuItemKeys,
    siderMenuSchema,
    matricesFolders,
    customReferencesFolders,
  } = useView(
    {
      store,
      route,
    });

  /**
   * Метод сортирует siderMenuSchema в заданной последовательности из orderedMenuItemKeys
   */
  const siderMenuItems = computed(() => {
    const menuItems: TCommonSchemaItem[] = [];

    orderedMenuItemKeys.forEach((menuItemSequence: string) => {
      const dynamicSchemaMenuItem = siderMenuSchema.value.find(
        (siderMenuItem: TCommonSchemaItem) => siderMenuItem.key === menuItemSequence,
      );

      // Проверяем permissions для найденного элемента меню. Если проверка прав не требуется, то массив permissions нужно оставить пустым
      if (dynamicSchemaMenuItem?.permissions?.every((hasPermissionChecker) => hasPermissionChecker())) {
        switch (dynamicSchemaMenuItem.key) {
          // Для динамически загружаемых subMenu показываем menuItem только если есть папки
          case ECommonSchemaItemKeys.matrices:
            if (matricesFolders.value.length > 0) {
              menuItems.push(dynamicSchemaMenuItem);
            }
            break;
          case ECommonSchemaItemKeys.references:
            if (customReferencesFolders.value.length > 0) {
              menuItems.push(dynamicSchemaMenuItem);
            }
            break;
          default:
            menuItems.push(dynamicSchemaMenuItem);
        }
      }
    });

    return menuItems;
  });

  const { componentsSchema } = useViewSiderContent();

  const staticSiderMenuItems = computed(() => {
    const menuItems: TStaticSchemaItem[] = [];

    componentsSchema.forEach((item: TStaticSchemaItem) => {
      if (!item.permissions || item.permissions?.every((hasPermissionChecker) => hasPermissionChecker())) {
        menuItems.push(item);
      }
    });

    return menuItems;
  });

  return {
    siderMenuItems,
    staticSiderMenuItems,
  };
};
