import { ROUTE_TYPE } from '@/constants';
import { ROUTE_NAME } from '@/contexts/operationalDocumentsContext/domain/constants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import {
  hasCargoOwnerWorkplaceSubscription,
  hasCarrierWorkplaceSubscription,
  hasOperationalDocumentsPermission,
  hasShipperWorkplaceSubscription,
} from '@/domains/permissions/subscription';
import { buildProtectedRoute } from '@/router/utils/buildProtectedRoute';
import isTenantVerified from '@/domains/isTenantVerified';

const hasOperationalDocumentsSubscription = () => (
  checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March)
    ? hasCarrierWorkplaceSubscription() || hasCargoOwnerWorkplaceSubscription() || hasShipperWorkplaceSubscription()
    : hasOperationalDocumentsPermission());

const routes = [
  buildProtectedRoute({
    path: '/operational_documents',
    name: ROUTE_NAME.operationalDocumentsList,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        hasOperationalDocumentsSubscription,
        isTenantVerified,
      ],
      component: () => import('@/contexts/operationalDocumentsContext/views/OperationalDocumentsListView/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/operational_documents/constructor/:id',
    name: ROUTE_NAME.operationalDocumentsConstructor,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        hasOperationalDocumentsSubscription,
        isTenantVerified,
      ],
      component: () => import('@/contexts/operationalDocumentsContext/views/OperationalDocumentsConstructorView/index.vue'),
    },
  }),
];

export default routes;
