<template>
  <BillingPlanSelectionCard
    :title="tt('billing.planCode.basic')"
    :subtitle="tt('billing.modal.forSmallAndMediumOrganizations')"
    :price="`${FREIGHT_FORWARDER_BASIC_PLAN_PRICE} ${tt('shared.currency.symbol.rub')}`"
    :planCode="EBillingPlanCode.freightForwarderBasic"
    @change="$emit('change', $event)"
  >
    <template #description>
      <li>{{ tt('billing.modal.twoManagersForOrganization') }}</li>
      <li>
        {{ tt('billing.modal.accessToOrdersTendersElectronicDocumentFlowAndFifteenTools') }}
      </li>
      <li>
        {{ tt('billing.modal.republishingOrdersOnATI') }}
      </li>
      <li>
        {{ tt('billing.modal.subcontracting') }}
      </li>
    </template>
  </BillingPlanSelectionCard>

  <BillingPlanSelectionCard
    :title="tt('billing.planCode.extended')"
    :subtitle="tt('billing.modal.forLargeCarriers')"
    :price="`${FREIGHT_FORWARDER_EXTENDED_PLAN_PRICE} ${tt('shared.currency.symbol.rub')}`"
    :planCode="EBillingPlanCode.freightForwarderExtended"
    forExtended
    @change="$emit('change', $event)"
  >
    <template #description>
      <li>{{ tt('billing.modal.unlimitedNumberOfManagersInTheOrganization') }}</li>
      <li>{{ tt('billing.modal.automaticFillingOfDriverAndCarDataUsingATemplate') }}</li>
      <li>{{ tt('billing.modal.transportationPriceCalculator') }}</li>
    </template>
  </BillingPlanSelectionCard>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import tt from '@/i18n/utils/translateText';
import { FREIGHT_FORWARDER_BASIC_PLAN_PRICE, FREIGHT_FORWARDER_EXTENDED_PLAN_PRICE } from '@/domains/constants';
import { EBillingPlanCode } from '@/contexts/billingContext/domain/constants';

import BillingPlanSelectionCard from '../BillingPlanSelectionCard/index.vue';

export default defineComponent({
  name: 'BillingCardsForFreightForwarder',
  components: { BillingPlanSelectionCard },
  emits: ['change'],
  setup() {
    return {
      FREIGHT_FORWARDER_BASIC_PLAN_PRICE,
      FREIGHT_FORWARDER_EXTENDED_PLAN_PRICE,
      EBillingPlanCode,

      tt,
    };
  },
});
</script>
