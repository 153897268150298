<template>
  <div class="notification">
    <Tooltip>
      <template #title>
        {{ indicatorText }}
      </template>
      <Button
        class="notification__indicator"
        type="link"
        :disabled="isIndicatorDisabled"
        @click="handleIndicatorClick"
      >
        <div
          class="notification__indicator-dot"
          :class="`notification__indicator-dot_${indicator}`"
        />
      </Button>
    </Tooltip>
    <div class="notification__content">
      <p class="notification__time">
        {{ time }}
      </p>
      <p class="notification__message">
        {{ notificationMessage }}
      </p>
      <div class="notification__row">
        <template
          v-for="(action, index) in notificationActions"
          :key="index"
        >
          <Button
            type="link"
            class="notification__action"
            @click="handleActionClick(action)"
          >
            {{ action.text }}
          </Button>
          <div
            v-if="index < notificationActions?.length - 1"
            class="notification__action-dot"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Button } from 'ant-design-vue';
import {
  ref,
  computed,
  defineComponent,
  onMounted,
  PropType,
} from 'vue';
import { useRouter } from 'vue-router';

import { Tooltip } from '@/ui';
import useStore from '@/store/useStore';
import { TNotification } from '@/contexts/notificationsContext/domain/types';
import useTimeFromNow from '@/composables/useTimeFromNow';
import { useDateValue } from '@/composables/useDateValue';
import useViewState from '@/composables/useViewState';
import { isToday } from '@/utils/dateUtils';
import tt from '@/i18n/utils/translateText';

import getActionPath from './utils/getActionPath';
import getNotificationMessage from './utils/getNotificationMessage';
import getNotificationActions from './utils/getNotificationActions';

export default defineComponent({
  name: 'Notification',
  components: {
    Button,
    Tooltip,
  },
  props: {
    notification: {
      type: Object as PropType<TNotification>,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const { timeFromNow } = useTimeFromNow(props.notification?.createdAt);

    const {
      isLoading: isIndicatorDisabled,
      setViewStateAs: setIndicatorDisabled,
    } = useViewState();

    const readNotificationById = () => {
      setIndicatorDisabled.loading();
      store.dispatch('notifications/readNotificationById', props.notification.id)
        .finally(setIndicatorDisabled.done);
    };

    const unreadNotificationById = () => {
      setIndicatorDisabled.loading();
      store.dispatch('notifications/unreadNotificationById', props.notification.id)
        .finally(setIndicatorDisabled.done);
    };

    const handleIndicatorClick = () => {
      if (props.notification.isRead) {
        unreadNotificationById();
      } else {
        readNotificationById();
      }
    };

    const handleActionClick = (action) => {
      const path = getActionPath(action.path, props.notification);
      router.push(path);
      if (!props.notification.isRead) {
        readNotificationById();
      }
      store.dispatch('notifications/toggleNotificationsListVisible', false);
    };

    const notificationMessage = computed(() => getNotificationMessage(props.notification));
    const notificationActions = computed(() => getNotificationActions(props.notification));
    const indicator = computed(() => (props.notification.isRead ? 'read' : 'unread'));
    const indicatorText = computed(() => (indicator.value === 'read'
      ? tt('notification.action.markAsUnread') : tt('notification.action.markAsRead')));
    const isNotificationWasReceivedToday = ref(false);
    const time = computed(() => (isNotificationWasReceivedToday.value
      ? timeFromNow.value
      : useDateValue(ref(props.notification.createdAt), { humanFormat: 'onlyTime' }).inHumanFormat.value));

    onMounted(() => {
      isNotificationWasReceivedToday.value = isToday(props.notification.createdAt);
    });

    return {
      indicator,
      indicatorText,
      time,
      isIndicatorDisabled,
      notificationMessage,
      notificationActions,

      getActionPath,
      handleActionClick,
      handleIndicatorClick,
      tt,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
