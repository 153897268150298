import store from '@/store';
import { httpMethods } from '@/api';
import { sendApiRequest } from '@/api/sendApiRequest';
import { EEmailForNotificationSource } from '@/contexts/resourcesControlContext/domain/constants';
import {
  TNotificationsResponse,
  TNotificationsRequest,
  TUnreadNotificationsCountResponse,
  TUnsubscribingFromEmailsRequest,
} from '@/contexts/notificationsContext/domain/types';
import { TPagination } from '@/types';
import tt from '@/i18n/utils/translateText';

export function readAllNotifications() {
  return sendApiRequest({
    method: httpMethods.PATCH,
    endpoint: '/:tenant_id/notifications/read',
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
      message: tt('notification.errors.readAllNotifications'),
    },
  })
    .then((response) => {
      store.commit('notifications/SET_UNREAD_COUNT', 0);
      return response;
    });
}

export function readNotification(notificationId: string) {
  return sendApiRequest({
    method: httpMethods.PATCH,
    endpoint: `/:tenant_id/notifications/${notificationId}/read`,
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
}

export function unreadNotification(notificationId: string) {
  return sendApiRequest({
    method: httpMethods.PATCH,
    endpoint: `/:tenant_id/notifications/${notificationId}/unread`,
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
}

export function selectEmailForNotifications(notificationEmailSource: EEmailForNotificationSource) {
  return sendApiRequest({
    method: httpMethods.PATCH,
    endpoint: '/:tenant_id/notification_email',
    payload: { notificationEmailSource },
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
  });
}

export function getNotifications(unread: boolean, { pageItems, currentPage }: TPagination) {
  const params: TNotificationsRequest = {
    items: pageItems,
    page: currentPage,
  };
  if (unread) {
    params.unread = unread;
  }
  return sendApiRequest<TNotificationsResponse>({
    method: httpMethods.GET,
    endpoint: '/:tenant_id/notifications',
    params,
  });
}

export function getUnreadNotificationsCount() {
  return sendApiRequest<TUnreadNotificationsCountResponse>({
    method: httpMethods.GET,
    endpoint: '/:tenant_id/notifications/statistics',
  });
}

export function unsubscribingFromEmails(payload: TUnsubscribingFromEmailsRequest) {
  return sendApiRequest({
    method: httpMethods.POST,
    endpoint: '/unsubscribe',
    payload,
    requestOptions: {
      showError: true,
      serverErrorFirst: true,
    },
    withoutAuth: true,
  });
}
