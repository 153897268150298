import app from './app';
import contractorInfo from './contractorInfo';
import filter from './filter';
import folders from './folders';
import i18n from './i18n';
import notifications from './notifications';
import pagination from './pagination';
import providers from './providers';
import rightPanel from './rightPanel';
import strategies from './strategies';
import tenants from './tenants';
import tenders from './tenders';
import timer from './timer';
import user from './user';
import digitalQueueSettings from './digitalQueueSettings';

const modules = {
  app,
  contractorInfo,
  filter,
  folders,
  i18n,
  notifications,
  pagination,
  providers,
  rightPanel,
  strategies,
  tenants,
  tenders,
  timer,
  user,
  digitalQueueSettings,
};

export default modules;
