import { computed } from 'vue';
import { useRoute } from 'vue-router';

import useStore from '@/store/useStore';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import { TUserResponse } from '@/contexts/accountingContext/domain/types';
import useExpirationTimer from '@/composables/useExpirationTimer';
import isTenantVerified from '@/domains/isTenantVerified';
import { ROUTE_TYPE } from '@/constants';
import { EBillingPlanCodeStatus } from '@/contexts/billingContext/domain/constants';

export const useView = () => {
  const route = useRoute();
  const store = useStore();

  const isNewVerifiedLogicAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March),
  );

  const user = computed<TUserResponse>(() => store.getters['user/currentUser']);

  const isOTP = computed(() => user.value?.oneTimePassword);
  const passwordExpiresAt = computed(() => user.value?.passwordExpiresAt); // Дата валидности пароля
  const lockedSince = computed(() => user.value?.lockedSince); // Дата блокировки аккаунта

  // Таймер истечения срока валидности пароля
  // TODO: А что это за таймер и зачем он нужен? Это самый простой способ проверить, не истёк ли пароль?
  const passwordExpirationTimer = useExpirationTimer({
    expirationDateRef: passwordExpiresAt,
    labelOnFinish: '',
    suffix: '',
  }).expirationValue;

  const hasTenants = computed(() => store.getters['tenants/availableUserTenants']?.length > 0);

  const hasActiveSubscription = computed(
    () => store.state.tenants.currentTenantInfo?.subscriptions?.some(
      (subscription) => subscription.status === EBillingPlanCodeStatus.active,
    ),
  );

  const showVerificationWarning = computed(() => isNewVerifiedLogicAvailable.value
      && hasTenants.value
      && !isTenantVerified()
      && route?.meta?.type === ROUTE_TYPE.private
      && !route.path.startsWith('/employees')
      && !route.path.startsWith('/tenant/settings')
      && !route.path.startsWith('/account')
      && !route.path.startsWith('/invitations'),
  );

  const showSubscriptionWarning = computed(() => isNewVerifiedLogicAvailable.value
      && hasTenants.value
      && isTenantVerified()
      && !hasActiveSubscription.value
      && route?.meta?.type === ROUTE_TYPE.private
      && !route.path.startsWith('/employees')
      && !route.path.startsWith('/tenant/settings')
      && !route.path.startsWith('/account')
      && !route.path.startsWith('/invitations'),
  );

  return {
    user,
    passwordExpirationTimer,
    passwordExpiresAt,
    isOTP,
    lockedSince,
    showVerificationWarning,
    showSubscriptionWarning,
  };
};
