import { useRoute } from 'vue-router';
import { computed } from 'vue';

import store from '@/store';
import { ROUTE_TYPE } from '@/constants';
import { EBillingPlanCodeStatus } from '@/contexts/billingContext/domain/constants';
import { TSubscription } from '@/types';

import { checkFeatureIsEnabled } from '../checkFeatureIsEnabled';
import { EExperimentalFeatures } from '../constants';

export const getIsPaywallMessageShown = (): boolean => {
  const route = useRoute();

  const currentTenantSubscriptions = store.state.tenants.currentTenantInfo?.subscriptions || [];
  const hasTenants = store.getters['tenants/availableUserTenants']?.length > 0;

  const isBillingValidFrom1MarchAvailable = checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March);

  const hasSubscriptionWithActiveStatus = computed(
    () => !!currentTenantSubscriptions
      .find((subscription: TSubscription) => subscription.status === EBillingPlanCodeStatus.active),
  );

  // с включенным FF показываем paywallMessage для всех ЛК, если не выбран тарифный план
  if (isBillingValidFrom1MarchAvailable) {
    const isRoutePrivate = route?.meta?.type === ROUTE_TYPE.private;
    return hasTenants && isRoutePrivate && !hasSubscriptionWithActiveStatus.value;
  }

  // с выключенным FF paywallMessage не показываем
  return false;
};
