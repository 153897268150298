import * as types from './mutation-types';
import { TI18nActions } from './types';

const actions: TI18nActions = {
  setCurrentLocale: ({ commit }, nextLocale) => {
    commit(types.SET_CURRENT_LOCALE, nextLocale);
  },
};

export default actions;
