import { computed, reactive } from 'vue';
import { RouteLocationNormalizedLoaded } from 'vue-router';

import tt from '@/i18n/utils/translateText';
import { TEntityFolder } from '@/types';
import { TCustomStore } from '@/store/types';
import {
  ECommonSchemaItemKeys,
  EShipperRunsKeys,
} from '@/components/layouts/AppLayoutWrapper/components/AppLayoutSider/domains/constants';
import isTenantVerified from '@/domains/isTenantVerified';
import isCurrentUserAdmin from '@/contexts/accountingContext/domain/isCurrentUserAdmin';
import isCurrentUserSupport from '@/contexts/accountingContext/domain/isCurrentUserSupport';
import { EExperimentalFeatures } from '@/domains/constants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EFoldersSection } from '@/store/modules/folders/types';
import { useView as useViewSiderContent } from '@/components/layouts/AppLayoutWrapper/components/AppLayoutSider/components/TmsSiderContent/application/useView';

import { TCommonSchemaItem } from '../domains/types';

export const useView = (
  {
    store,
    route,
  }: {
    store: TCustomStore,
    route: RouteLocationNormalizedLoaded,
  }) => {
  // Массив ключей, используемых в сайдбаре. Массив формирует порядок вывода элементов меню
  const orderedMenuItemKeys = [
    ECommonSchemaItemKeys.transportationCalculator,
    ECommonSchemaItemKeys.orders,
    ECommonSchemaItemKeys.executorsLookupsFeed,
    ECommonSchemaItemKeys.digitalQueues,
    ECommonSchemaItemKeys.shipments,
    ECommonSchemaItemKeys.directions,
    ECommonSchemaItemKeys.matrices,
    ECommonSchemaItemKeys.references,
    ECommonSchemaItemKeys.quotas,
    ECommonSchemaItemKeys.executionRequests,
    ECommonSchemaItemKeys.tenders,
    ECommonSchemaItemKeys.shipperRuns,
    ECommonSchemaItemKeys.employees,
    ECommonSchemaItemKeys.contractors,
    ECommonSchemaItemKeys.operationalDocuments,
    ECommonSchemaItemKeys.vehicles,
    ECommonSchemaItemKeys.partnerships,
    ECommonSchemaItemKeys.lookupStrategies,
    ECommonSchemaItemKeys.priceLists,
    ECommonSchemaItemKeys.reportTemplates,
    ECommonSchemaItemKeys.extraServices,
    ECommonSchemaItemKeys.approvals,
  ];

  const state = reactive({
    selectedKeys: [] as string[],
    openKeys: [] as string[],
  });

  const getOrdersPath = () => {
    const firstFolderId = store.state.folders[EFoldersSection.orderList].folders[0]?.id;

    return firstFolderId
      ? `/orders/only_orders?folderId=${firstFolderId}`
      : '/orders/only_orders';
  };

  const matricesFolders = computed(() => store.state.folders[EFoldersSection.matricesList].folders);
  const customReferencesFolders = computed(() => store.state.folders[EFoldersSection.referencesList].folders);

  const { ordersSchemaItem, commonSchemaItems } = useViewSiderContent(matricesFolders);

  // Массив со всеми элементами для сайдбара
  const siderMenuSchema = computed(() => {
    const dynamicSchemaItems: TCommonSchemaItem[] = [];

    dynamicSchemaItems.push({
      ...ordersSchemaItem,
      path: getOrdersPath(),
    });

    // Объединяем статичную схему и схему заказов с динамическим роутом
    return [...commonSchemaItems.value, ...dynamicSchemaItems];
  });

  const currentTenant = computed(() => store.state.tenants.currentTenant);

  const isAdminRoute = computed(() => route.path.startsWith('/admin'));
  const isAdmin = computed(() => isCurrentUserSupport() || isCurrentUserAdmin());

  const isSidebarVisible = computed(() => !!store.state.tenants.currentTenant);
  const folderOpenKeys = computed(() => store.state.app.folderOpenKeys);

  const folderId = computed(() => route.query.folderId as string);
  const orderFolders = computed((): TEntityFolder[] => {
    const folders = store.state.folders[EFoldersSection.orderList].folders as TEntityFolder[];
    return folders.map((folder) => ({
      id: folder.id,
      name: tt(`sider.menu.children.${folder.id}`),
    }));
  });

  const isCurrentTenantVerified = computed(() => isTenantVerified());

  const isNewVerifiedLogicAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March),
  );

  const isSiderItemsDisabled = computed(() => isNewVerifiedLogicAvailable.value && !isCurrentTenantVerified.value);

  const currentRunsCount = computed(() => store.state.contractorInfo.currentRunsCount);
  const plannedRunsCount = computed(() => store.state.contractorInfo.plannedRunsCount);
  const finishedRunsCount = computed(() => store.state.contractorInfo.finishedRunsCount);
  const incidentsCount = computed(() => store.state.contractorInfo.incidentsCount);

  const getShipperRunsCount = (runKey: string | EShipperRunsKeys) => {
    switch (runKey) {
      case EShipperRunsKeys.current:
        return currentRunsCount.value;
      case EShipperRunsKeys.planned:
        return plannedRunsCount.value;
      case EShipperRunsKeys.finished:
        return finishedRunsCount.value;
      case EShipperRunsKeys.incidents:
        return incidentsCount.value || null;
      default:
        return null;
    }
  };

  // Удалить после выпиливания EExperimentalFeatures.billingValidFrom1March вместе с PAGES_AVAILABLE_AFTER_VERIFICATION
  const getShipperRunsNotAvailable = (menuKey: string) => menuKey === 'shipper_runs' && !isCurrentTenantVerified.value;

  return {
    isAdmin,
    isAdminRoute,
    isSidebarVisible,
    isCurrentTenantVerified,
    state,
    folderOpenKeys,
    orderFolders,
    currentTenant,
    folderId,
    orderedMenuItemKeys,
    matricesFolders,
    customReferencesFolders,
    siderMenuSchema,
    isSiderItemsDisabled,

    getShipperRunsCount,
    getShipperRunsNotAvailable,
  };
};
