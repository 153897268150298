<template>
  <Input
    ref="inputRef"
    :value="inputValue.maskedValue"
    :type="EInputTypes.text"
    :inputmode="EInputModes.numeric"
    :size="size"
    :isInvalid="isInvalid || isIncorrectValue"
    :disabled="disabled"
    :placeholder="placeholder"
    :hasBorder="hasBorder"
    autocomplete="off"
    :allowClear="allowClear"
    :style="inlineStyle"
    @input="onInput"
    @change="onChange"
    @paste="onPaste"
    @keydown="$emit('keydown', $event)"
    @keyup.enter="$emit('enter', $event)"
    @keyup.delete="onClear"
    @blur="$emit('blur', $event)"
    @focus="$emit('focus', $event)"
    @reset="$emit('update:value', '')"
  >
    <template #addonBefore>
      <slot name="addonBefore" />
    </template>
    <template #addonAfter>
      <slot name="addonAfter" />
    </template>
  </Input>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  watch,
  onMounted,
  reactive,
  computed,
} from 'vue';
import type { PropType } from 'vue';

import type { TNumberInputValue } from '@/ui/types';
import {
  ESize,
  EInputTypes,
  EInputModes,
} from '@/ui/types';
import isNumber from '@/utils/isNumber';
import useAppTheme from '@/composables/application/useAppTheme';

import Input from '../Input/index.vue';
import { getNumberFromValue } from './utils/getNumberFromValue';
import { formatDigitOrder } from './utils/formatDigitOrder';

export default defineComponent({
  name: 'NumberInput',
  components: { Input },
  props: {
    value: {
      type: [Number, null] as PropType<number | null>,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    min: {
      type: [Number, null] as PropType<number | null>,
      default: null,
    },
    max: {
      type: [Number, null] as PropType<number | null>,
      default: null,
    },
    /** Для подсветки инпута при не валидных данных */
    isInvalid: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<ESize>,
      default: ESize.normal,
    },
    placeholder: {
      type: String,
      default: '',
    },
    /** При значении true, значение в инпуте будет отображаться без форматирования по разрядности(без пробелов) */
    withoutFormatting: {
      type: Boolean,
      default: false,
    },
    /** При значении true, в инпут можно ввести отрицательные значения */
    withNegativeNumbers: {
      type: Boolean,
      default: false,
    },
    /** При значении true, в инпут можно будет передать ТОЛЬКО целые числа (без точек) */
    isOnlyInteger: {
      type: Boolean,
      default: false,
    },
    /** отображение иконки очистки поля */
    allowClear: {
      type: Boolean,
      default: false,
    },
    hasBorder: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    /** событие вызывается сразу же при изменении значения поля */
    'input',
    /** срабатывает когда значение поля изменено, но при условии снятия с него фокуса */
    'change',
    /** событие вызывается при нажатии на клавишу "Enter" */
    'enter',
    /** событие вызывается при вставке чего-либо в поле */
    'paste',
    /** событие вызывается при нажатии на клавишу */
    'keydown',
    /** событие вызывается каждый раз при расфокусировке поля */
    'blur',
    /** событие вызывается когда поле в фокусе */
    'focus',
    /** поддержка двустороннего связывания (v-model) */
    'update:value',
    /** событие вызывается при очищении поля */
    'clear',
  ],
  setup(props, { emit, expose }) {
    const { getThemeConfig } = useAppTheme();
    const { jsColors } = getThemeConfig();

    const inputRef = ref<HTMLInputElement | null>(null);
    const isIncorrectValue = ref(false);
    const inlineStyle = computed(() => (isIncorrectValue.value ? { color: jsColors.danger60 } : null));

    const inputValue = reactive<TNumberInputValue>({
      number: null,
      maskedValue: '',
    });

    const formatStringValueWithOrder = (value: string) => Number(value).toLocaleString('ru-RU');

    /** Подготавливает числа, которые могут быть негативными, но только целыми */
    const prepareNegativeIntegerStringValue = (value: string) => {
      const splittedString = value.split('-');

      if (splittedString.length > 1) {
        const formattedPartOfValue = formatStringValueWithOrder(splittedString[1]);

        splittedString[1] = formattedPartOfValue === '0' ? '' : formattedPartOfValue;
        inputValue.maskedValue = splittedString.join('-');
      } else {
        splittedString[0] = formatStringValueWithOrder(splittedString[0]);
        inputValue.maskedValue = splittedString.join('.');
      }
    };

    /** Устанавливаем форматированное значение для отображения в интерфейсе
    * @param value значение для отображения в интерфейсе
    * @param hasMinusSymbol сообщает нам содержит ли число знак минуса
    */
    const setMaskedValue = (value: number | string | null, hasMinusSymbol: boolean = false) => {
      if (value || value === 0) {
        const stringValue = value.toString();

        if (hasMinusSymbol && !props.withNegativeNumbers) {
          if (stringValue.length === 1) {
            inputValue.maskedValue = '';
            return;
          }

          inputValue.maskedValue = props.withoutFormatting ? stringValue.replace('-', '') : formatDigitOrder(stringValue.replace('-', ''));
        } else {
          /** При запрете форматирования по разрядности */
          if (props.withoutFormatting) {
            inputValue.maskedValue = stringValue;
          }

          /** При допуске форматирования по разрядности */
          if (!props.withoutFormatting) {
            /** При допуске только целых чисел */
            if (props.isOnlyInteger) {
              /** При допуске негативных целых чисел */
              if (props.withNegativeNumbers) {
                prepareNegativeIntegerStringValue(stringValue);

              /** При допуске положительных целых чисел */
              } else {
                inputValue.maskedValue = formatStringValueWithOrder(stringValue);
              }

              /** При допуске дробных чисел */
            } else {
              const splittedString = stringValue.split('.');

              /** При допуске дробных негативных чисел */
              if (props.withNegativeNumbers) {
                /** Если число дробное */
                if (splittedString.length > 1) {
                  const integerPart = splittedString[0].split('-');

                  /** Если значение дробное отрицательное */
                  if (integerPart.length > 1) {
                    integerPart[1] = Number.isNaN(integerPart[1]) ? '' : formatStringValueWithOrder(integerPart[1]);
                    splittedString[0] = integerPart.join('-');
                  } else {
                    /** Если значение дробное положительное */
                    splittedString[0] = Number.isNaN(integerPart[0]) ? '' : formatStringValueWithOrder(integerPart[0]);
                  }
                } else {
                  prepareNegativeIntegerStringValue(stringValue);
                  return;
                }

                /** Присваиваем отрицательное дробное число */
                inputValue.maskedValue = splittedString.join('.');
              } else {
                splittedString[0] = formatStringValueWithOrder(splittedString[0]);
                inputValue.maskedValue = splittedString.join('.');
              }
            }
          }
        }
      } else {
        inputValue.maskedValue = '';
      }
    };

    /** Устанавливаем форматированное значение для эмита */
    const setValue = (value: string | number) => {
      const formattedNumber = value.toString().replace(/\s/g, '');

      if (Number.isNaN(Number(formattedNumber))) return;

      inputValue.number = !formattedNumber ? null : Number(formattedNumber);
    };

    const getValueFromProp = () => {
      if (props.value && !isNumber(props.value)) {
        console.error('NumberInput: default value is not a Number');

        return;
      }
      setMaskedValue(!props.value && !isNumber(props.value) ? '' : props.value);
      setValue(!props.value && !isNumber(props.value) ? '' : props.value);
    };

    onMounted(getValueFromProp);
    watch(() => props.value, getValueFromProp);

    const onInput = (event: Event) => {
      const input = event.target as HTMLInputElement;
      const validatedValue = getNumberFromValue(input.value, props.isOnlyInteger);

      /** Если значение в инпуте не прошло валидацию - эмитим null */
      if (!validatedValue || !validatedValue.value) {
        input.value = '';
        emit('update:value', null);
        emit('input', null);

        return;
      }

      /** Если число не целое, то разделяем его на две части */
      if (validatedValue.hasDot) {
        const numberBeforeDot = validatedValue.value.split('.')[0];
        const numberAfterDot = validatedValue.value.split('.')[1];
        const maskedValue = `${numberBeforeDot?.toLocaleString()}.${numberAfterDot}`;

        /** Если число не целое и отрицательное, тогда сетим true вторым аргументом */
        setMaskedValue(maskedValue, validatedValue.hasMinus);
      } else {
        /** Если число целое и отрицательное, тогда сетим true вторым аргументом */
        setMaskedValue(validatedValue.value, validatedValue.hasMinus);
      }

      /** Присваиваем отформатированное значение в инпут */
      input.value = inputValue.maskedValue || '';

      setValue(input.value);
      emit('input', inputValue.number);
      emit('update:value', inputValue.number);
    };

    const onChange = () => {
      isIncorrectValue.value = false;

      /** Эмитим число, а не event, чтобы не приходилось в потребителе форматировать value */
      emit('change', inputValue.number);

      if (inputValue.number) {
        /** Сравниваем число с минимальным допустимым */
        if (props.min && inputValue.number < props.min) {
          isIncorrectValue.value = true;

          console.error('Значение меньше минимально допустимого');
          return;
        }

        /** Сравниваем число с максимальным допустимым */
        if (props.max && inputValue.number > props.max) {
          isIncorrectValue.value = true;

          console.error('Значение больше максимально допустимого');
        }
      }
    };

    const onFocus = () => {
      inputRef.value?.focus();
    };

    const onPaste = (event: ClipboardEvent) => {
      const input = event.target as HTMLInputElement;
      const validatedValue = getNumberFromValue(input.value, props.isOnlyInteger);

      emit('update:value', validatedValue);
      emit('paste', event);
    };

    const onClear = (event: KeyboardEvent) => {
      const input = event.target as HTMLInputElement;

      if (event.code === 'Backspace' && !input.value) {
        setValue('');
        setMaskedValue('');
      }
      emit('clear', event);
    };

    expose({
      focus: onFocus,
      numberInput: inputRef,
    });

    return {
      inputRef,
      inputValue,
      isIncorrectValue,
      inlineStyle,

      EInputTypes,
      EInputModes,

      onInput,
      onPaste,
      onChange,
      onClear,
    };
  },
});
</script>
