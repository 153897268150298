import { computed } from 'vue';

import tt from '@/i18n/utils/translateText';
import useStore from '@/store/useStore';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';

export const useView = () => {
  const isNewVerifiedLogicAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March),
  );

  const store = useStore();

  const currentTenantInfo = computed(() => store.state.tenants.currentTenantInfo);

  const subscriptionsRequest = computed(() => currentTenantInfo.value?.subscriptionRequest || null);
  const subscriptions = computed(() => currentTenantInfo.value?.subscriptions || []);

  const descriptionText = computed(() => {
    // Есть активная подписка или запрос на подписку
    if (!isNewVerifiedLogicAvailable.value || subscriptions.value.length > 0 || subscriptionsRequest.value) {
      return tt('tenant.shared.verificationAndSubscriptionWarning.description');
    }

    return tt('tenant.shared.verificationAndSubscriptionWarning.shared.connectPlan');
  });

  return { descriptionText };
};
