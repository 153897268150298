export enum ESubscriptionFeature {
  makeFreightRequests = 'makeFreightRequests',
  executeRuns = 'executeRuns',
  geomonitoring = 'geomonitoring',
  organizeLookups = 'organizeLookups',
  participateInLookups = 'participateInLookups',
  useDigitalQueues = 'useDigitalQueues',
  useAggregator = 'useAggregator',
  usePriceAnalytics = 'usePriceAnalytics',
  useReportTemplates = 'useReportTemplates',
  useExtraServices = 'useExtraServices',
  useOperationalDocuments = 'useOperationalDocuments',
  trackRuns = 'trackRuns',
}

export enum EActiveSubscriptionsConditionsFeature {
  /** Кабинет ГВ (грузовладельца) */
  cargoOwnerWorkplace = 'cargoOwnerWorkplace',
  /** Кабинет ГО/ГП (грузоотправитель/грузополучатель) */
  shipperWorkplace = 'shipperWorkplace',
  /** Кабинет ГП (грузоперевозчика) */
  carrierWorkplace = 'carrierWorkplace',
  /** Дополнительные фичи экспедитора */
  forwarderFeatures = 'forwarderFeatures',
  /** Калькулятор перевозки */
  priceCalculator = 'priceCalculator',
  /** Ограничение автозаполнения ТС */
  autocompleteTransportResources = 'autocompleteTransportResources',
}

export enum EExternalAccountProviders {
  sber_business = 'sber_business',
}

export enum ExpectedArrivalDateTimeType {
  interval = 'interval',
  determined = 'determined',
  none = 'none',
}

export enum ERegionType {
  область = 'область',
  край = 'край',
  республика = 'республика',
  город = 'город',
  'автономный округ' = 'автономный округ',
  'автономная область' = 'автономная область',
}

export enum ETenantSubscriptionStatus {
  active = 'active',
  finished = 'finished',
}

export enum EmployeeViewSettingsField {
  ordersColumns = 'ordersColumns',
  executionRequestsExecutorColumns = 'executionRequestsExecutorColumns',
}

export enum EExperimentalFeatures {
  /** Доступ к разделу Согласования */
  approvals = 'approvals',
  /** Доступ разделу Документооборот */
  operationalDocuments = 'operationalDocuments',
  /** Возможность выгрузки заказов в Excel */
  ordersExport = 'ordersExport',
  /** Возможность загрузки заказов в Excel */
  ordersImport = 'ordersImport',
  /** Необходимость принятия публичной оферты при регистрации новых тенантов */
  publicOfferAccept = 'publicOfferAccept',
  /** Кастомизация статусной модели рейса */
  runStatusesCustomizations = 'runStatusesCustomizations',
  /** Асинхронная генерация результатов по тендеру */
  tenderLotsStageResultAsyncGeneration = 'tenderLotsStageResultAsyncGeneration',
  /** Юридический адрес партнера и контрагента */
  legalAddress = 'legalAddress',
  /** Кастомизация справочника инцидентов */
  runIncidentsCustomization = 'runIncidentsCustomization',
  /** Добавить в фильтр на списке заказов номер и статус рейса */
  ordersTableRunFiltering = 'ordersTableRunFiltering',
  /** Добавить сортировку по статусу рейса на списке заказов */
  ordersTableRunSorting = 'ordersTableRunSorting',
  /** Отображение кастомной БЛ в этапах автоматизации */
  showAutomationStepLogic = 'showAutomationStepLogic',
  /** Реализация раздела "Справочники" */
  customReferences = 'customReferences',
  /** Кастомизация атрибутов договоров */
  contractAttributesCustomizations = 'contractAttributesCustomizations',
  /** Кастомная валидация ресурсов в заявке при распределении */
  executionRequestCustomerValidations = 'executionRequestCustomerValidations',
  /** Создание и редактирование номера поставки в маршрутном листе */
  newDeliveryAttributes = 'newDeliveryAttributes',
  /** Новый подход в отображении НДС */
  vatRateImprovments = 'vatRateImprovments',
  /** Функционал биллинга, который нужно запустить ровно с 01.03 */
  billingValidFrom1March = 'billingValidFrom1March',
  /** Скрытие лого, которое нужно для демо на с 29.02 */
  hideLogo = 'hideLogo',
  /** Опросник Testograf */
  testograf = 'testograf',
  /** Рефакторинг подготовки пейлодов для создания подборов и автоматизаций */
  refactoringPreparePayloadForLookups = 'refactoringPreparePayloadForLookups',
  /**  Ролевая модель сибур */
  siburAdditionalRolesModel = 'siburAdditionalRolesModel',
  /** Показ нескольких контрагентов в ленте заказов */
  showCounterpartiesInRequests = 'showCounterpartiesInRequests',
  /** Оптимизированный вариант таблицы на grid */
  gridTable = 'gridTable',
  /** Возможность генерировать отчет по матрице */
  generateMatrixReport = 'generateMatrixReport',
  /** Поиск отправителей и получателей через все точки */
  enhancedSearchForCounterparties = 'enhancedSearchForCounterparties',
  /** Разделение настроек перебронирования и аннулирования для рейса и самовывоза */
  digitalQueueRestrictionsSplit = 'digitalQueueRestrictionsSplit',
  /** Обновление дровера создания бронирования за ГВ в ЦО */
  preciseBookingForCustomer = 'preciseBookingForCustomer',
  /** Резервирование таймслота */
  digitalQueueReservation = 'digitalQueueReservation',
  /** Таблица с ресайзом для вкладки "Договоры" в разделе "Партнерства" */
  partnershipsContractsTableViewSettings = 'partnershipsContractsTableViewSettings',
  /** Обновление таблицы с заказами по сокетам */
  updateOrdersListByWs = 'updateOrdersListByWs',
  /** Исключение из торгов перевозчиков, которые отказались от предложений по гарантии СИБУР */
  siburRejectQuotaDistributionExecutors = 'siburRejectQuotaDistributionExecutors',
  /** Кастомные атрибуты рейса в таблице заказов" */
  runsCustomAttributesInOrdersTable = 'runsCustomAttributesInOrdersTable',
  /** Добавление новых колонок в таблицу заказов ГВ (СИБУР) */
  orderCreatedByColumns = 'orderCreatedByColumns',
  /** Отображение общего и занятого количества таймслотов для ворот В ЦО */
  digiatalQueueGateViewCounts = 'digiatalQueueGateViewCounts',
  /** Изменение обязательности поля Юр адрес организации */
  requiredOrganizationLegalAddress = 'requiredOrganizationLegalAddress',
  /** Отображение информации о пробном периоде в биллинге */
  billingTrialPeriodInformation = 'billingTrialPeriodInformation',
  /** Доработки резервирования и бронирования в ЦО */
  digitalQueueReservationImprovements = 'digitalQueueReservationImprovements',
  /** Рефакторинг запросов к participation_state на ленте заказов */
  refactoringParticipationStateForLookups = 'refactoringParticipationStateForLookups',
  /** Просмотр стоимости транзакций в подтвержденных партнерствах */
  showPartnerTransactionsInfo = 'showPartnerTransactionsInfo',
  /** Подсветка непрочитанных изменений в заявке */
  executionRequestUnreadChangesIndicator = 'executionRequestUnreadChangesIndicator',
  /** Изменение логики создания ворот и расписания в ЦО */
  digitalQueueSchedulesImprovements = 'digitalQueueSchedulesImprovements',
  /** Загрузка и выгрузка в эксель данных по договорам */
  importAndExportContractsFromExcel = 'importAndExportContractsFromExcel',
  /** Загрузка и выгрузка в эксель данных по партнерствам */
  partnershipsImportAndExport = 'partnershipsImportAndExport',
  /** Возможность забрать часть объема по подбору */
  divisibleReduction = 'divisibleReduction',
  /** Рефакторинг дроверов просмотра брони и расписания */
  digitalQueueRefactoringDrawers = 'digitalQueueRefactoringDrawers',
}

export enum EGlobalSettings {
  sberBusinessEnabled = 'sberBusinessEnabled',
}

export enum ECompanyRole {
  executor = 'executor',
  customer = 'customer',
  shipper = 'shipper',
}

export const ADDRESS_SOURCES = {
  daData: 'da_data',
  db: 'db',
  user: 'user',
};

export const SUPPORT_EMAIL = 'support@tms.ru';

// TODO: при переносе в src/constants ломается tt(). Нужно разобраться с проблемой
export enum ELocale {
  ru = 'ru',
  en = 'en',
}

export enum EStatusType {
  default = 'default',
  error = 'error',
  processing = 'processing',
  success = 'success',
  warning = 'warning',
}

export enum ERunWorkflowPhase {
  waitingForExecution = 'waiting_for_execution',
  executionInProgress = 'execution_in_progress',
  executionFinished = 'execution_finished',
  executionCancelled = 'execution_cancelled',
}

export enum ETenantType {
  customer = 'customer',
  executor = 'executor',
  shipper = 'shipper',
}

export enum EAddressBound {
  country = 'country',
  region = 'region',
  area = 'area',
  city = 'city',
  settlement = 'settlement',
  street = 'street',
  block = 'block',
}

export enum ERouteProgress {
  allProgress = 'all_progress',
  selfProgress = 'self_progress',
}

export enum EAdmissionToLookupsParticipation {
  noContractsNeeded = 'no_contracts_needed',
  contractStrictlyRequired = 'contract_strictly_required',
  followingByLookupSettings = 'following_by_lookup_settings',
}

export enum ECreationLookupType {
  strategy = 'strategy',
  manually = 'manually',
}

export enum EAutomationStepsSpotOfferAttributes {
  accessLevel = 'accessLevel',
  currency = 'currency',
  priceSource = 'priceSource',
  vatRate = 'vatRate',
  accessRules = 'accessRules',
  duration = 'duration',
  price = 'price',
  priceType = 'priceType',
}

export enum EAutomationStepsTargetOfferAttributes {
  currency = 'currency',
  priceSource = 'priceSource',
  vatRate = 'vatRate',
  duration = 'duration',
  price = 'price',
  partnershipId = 'partnershipId',
}

export enum EAutomationStepsQuotaAttributes {
  currency = 'currency',
  priceSource = 'priceSource',
  vatRate = 'vatRate',
  duration = 'duration',
  guaranteeTagName = 'guaranteeTagName',
  price = 'price',
}

export enum EAutomationStepsReductionAttributes {
  accessLevel = 'accessLevel',
  currency = 'currency',
  offeringType = 'offeringType',
  priceSource = 'priceSource',
  vatRate = 'vatRate',
  accessRules = 'accessRules',
  duration = 'duration',
  percentStep = 'percentStep',
  exactStep = 'exactStep',
  expirationStartsAfter = 'expirationStartsAfter',
  manualBidSelection = 'manualBidSelection',
  manualBidSelectionDuration = 'manualBidSelectionDuration',
  startingPrice = 'startingPrice',
  stepType = 'stepType',
  automaticProlongation = 'automaticProlongation',
}

export enum EAutomationStepsFreeOfferingAttributes {
  accessLevel = 'accessLevel',
  currency = 'currency',
  maxPrice = 'maxPrice',
  minPrice = 'minPrice',
  offerSelectionDuration = 'offerSelectionDuration',
  offeringDuration = 'offeringDuration',
  offeringType = 'offeringType',
  priceSource = 'priceSource',
  vatRate = 'vatRate',
  accessRules = 'accessRules',
  automaticProlongation = 'automaticProlongation',
}

export enum ELookupUsageContext {
  order = 'order',
  agreement = 'agreement',
}

export const MORE_INFO_ABOUT_PLANS_URL = 'https://confluence.trucker.group/manual/9-tarify-i-usloviya-2535822689.html';

// Стоимость подписки для тарифным планам
export const FREIGHT_FORWARDER_BASIC_PLAN_PRICE = '2 399';
export const FREIGHT_FORWARDER_EXTENDED_PLAN_PRICE = '4 399';
export const CARRIER_BASIC_PLAN_PRICE = '1 899';
export const CARRIER_EXTENDED_PLAN_PRICE = '2 899';

/** Ссылка на договор офферты для подписки на кабинет ГВ (грузовладельца) */
export const CARGO_OWNER_PUBLIC_OFFER_LINK = '/docs/default/contract-offer-customer.pdf';
/**  Ссылка на договор офферты для подписки на кабинет ГП (грузоперевозчик) или кабинет ГО/ГП (грузоотправитель/грузополучатель) */
export const CARRIER_PUBLIC_OFFER_LINK = '/docs/default/contract-offer.pdf';
/**  Ссылка на новый договор офферты для подписки на кабинет ГП (грузоперевозчик) или кабинет ГО/ГП (грузоотправитель/грузополучатель) */
export const NEW_CARRIER_PUBLIC_OFFER_LINK = '/docs/default/contract-offer-new.pdf';
