<template>
  <div class="billing-plan-selection-card">
    <div class="billing-plan-selection-card__info">
      <p class="billing-plan-selection-card__info-title">
        {{ title }}
      </p>
      <p class="billing-plan-selection-card__info-subtitle">
        {{ subtitle }}
      </p>
      <p
        v-if="!withoutPrice"
        class="billing-plan-selection-card__info-price"
      >
        <span>{{ price }}</span> / {{ tt('shared.shortMounth') }}
      </p>
      <p
        v-if="isBillingTrialPeriodInfoShown"
        class="billing-plan-selection-card__info-trial-period"
      >
        {{ tt('billing.firstFourteenDaysIsTrial', {
          param: {
            first: tt('shared.first.plural'),
          }
        }) }}
      </p>
      <Button
        class="billing-plan-selection-card__info-button"
        type="primary"
        @click="$emit('change', planCode)"
      >
        {{ actionText }}
      </Button>
    </div>
    <div class="billing-plan-selection-card__description">
      <ul>
        <li
          v-if="forExtended"
          class="billing-plan-selection-card__description-extended"
        >
          {{ tt('billing.modal.everythingInTheBasic') }} +
        </li>
        <slot name="description" />
      </ul>
    </div>
  </div>
</template>
<script lang="ts">

import { Button } from 'ant-design-vue';
import { PropType, defineComponent } from 'vue';

import tt from '@/i18n/utils/translateText';
import { EBillingPlanCode } from '@/contexts/billingContext/domain/constants';

import { useView } from './application/useView';

export default defineComponent({
  name: 'BillingPlanSelectionCard',
  components: { Button },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    price: {
      type: String,
      default: '',
    },
    withoutPrice: {
      type: Boolean,
      default: false,
    },
    forExtended: {
      type: Boolean,
      default: false,
    },
    planCode: {
      type: String as PropType<EBillingPlanCode>,
      default: EBillingPlanCode.carrierBasic,
    },
    actionText: {
      type: String,
      default: tt('shared.action.choose'),
    },
  },
  emits: ['change'],
  setup(props) {
    const { isBillingTrialPeriodInfoShown } = useView(props.planCode);
    return {
      isBillingTrialPeriodInfoShown,

      tt,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
