<template>
  <li
    class="action-sheet-item"
    :class="className"
  >
    <div class="action-sheet-item__row-container">
      <div class="action-sheet-item__flex-row">
        <div class="action-sheet-item__icon-left">
          <component
            :is="icon"
          />
        </div>
        <div v-if="label" class="action-sheet-item__flex-row">
          <span v-if="canShowFullLabel" class="action-sheet-item__title_full-label">
            {{ label }}
          </span>
          <Tooltip
            v-else-if="label"
            :title="String(label)"
            :placement="EPopoverPlacement.right"
          >
            <span class="action-sheet-item__title">
              {{ label }}
            </span>
          </Tooltip>
        </div>
        <div v-if="isCheckIconVisible" class="action-sheet-item__flex-row action-sheet-item__info">
          <CheckSvg
            class="action-sheet-item__check-icon"
          />
        </div>
        <div v-if="hasBadge || withToggle" class="action-sheet-item__icons-right">
          <Toggle
            v-if="withToggle"
            :size="ESize.small"
            :disabled="disabled"
          />
          <Badge
            v-if="hasBadge"
            :size="ESize.small"
            :color="badge.color"
          >
            <template #icon>
              <component
                :is="badge.icon"
              />
            </template>
            {{ badge.label }}
          </Badge>
        </div>
      </div>
      <div
        v-if="subTitle && label"
        class="action-sheet-item__flex-row action-sheet-item__sub-title"
      >
        <span>
          {{ subTitle }}
        </span>
      </div>
    </div>
  </li>
</template>
<script lang="ts">
import {
  PropType,
  computed,
  defineComponent,
} from 'vue';

import {
  EPopoverPlacement,
  TRawActionSheetValue,
  EActionSheetType,
  ESize,
  TBadgeOption,
} from '@/ui/types';
import CheckSvg from '@/assets/svg/16x16/check.svg';
import Tooltip from '@/ui/Tooltip/index.vue';
import Badge from '@/ui/Badge/index.vue';
import Toggle from '@/ui/Toggle/index.vue';

export default defineComponent({
  name: 'ActionSheetItem',
  components: {
    Tooltip,
    CheckSvg,
    Badge,
    Toggle,
  },
  props: {
    value: {
      type: [String, Number] as PropType<TRawActionSheetValue>,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    withToggle: {
      type: Boolean,
      default: false,
    },
    subTitle: {
      type: String,
      default: '',
    },
    isFullLabelVisible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String as PropType<EActionSheetType>,
      default: EActionSheetType.default,
    },
    icon: {
      type: [Object, null] as PropType<SVGElement | null>,
      default: null,
    },
    badge: {
      type: Object as PropType<TBadgeOption>,
      default: () => ({}),
    },
  },
  setup(props) {
    const className = computed(() => {
      let resultClassName = props.disabled ? 'action-sheet-item_disabled' : '';

      if (props.selected) {
        resultClassName += ' action-sheet-item_selected';
      }

      if (props.type === EActionSheetType.negative) {
        resultClassName += ' action-sheet-item_negative';
      }

      return resultClassName;
    });

    const hasBadge = computed(() => !!Object.keys(props.badge).length);
    const hasItemContent = computed(() => hasBadge.value || props.withToggle || !!props.subTitle);
    const canShowFullLabel = computed(() => props.isFullLabelVisible && !hasItemContent.value);
    const isCheckIconVisible = computed(() => props.selected && !hasBadge.value && !props.withToggle);

    return {
      className,
      canShowFullLabel,
      EActionSheetType,
      EPopoverPlacement,
      ESize,
      hasBadge,
      isCheckIconVisible,
    };
  },
});
</script>
<style lang="scss" src="./styles.scss" />
