import * as types from './mutation-types';
import { TFoldersActions } from './types';

const actions: TFoldersActions = {
  setColumnSettings: ({ commit }, payload) => {
    commit(types.SET_COLUMN_SETTINGS, payload);
  },
  setSort: ({ commit }, payload) => {
    commit(types.SET_SORT, payload);
  },
  setFolders: ({ commit }, payload) => {
    commit(types.SET_FOLDERS, payload);
  },
  setQuickActions: ({ commit }, payload) => {
    commit(types.SET_QUICK_ACTIONS, payload);
  },
  resetState: ({ commit }) => {
    commit(types.RESET_STATE);
  },
};

export default actions;
