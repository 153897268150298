<template>
  <BillingPlanSelectionCard
    :title="tt('billing.planCode.basic')"
    :subtitle="tt('billing.modal.forSmallAndMediumOrganizations')"
    :price="`${CARRIER_BASIC_PLAN_PRICE} ${tt('shared.currency.symbol.rub')}`"
    :planCode="EBillingPlanCode.carrierBasic"
    @change="$emit('change', $event)"
  >
    <template #description>
      <li>{{ tt('billing.modal.twoManagersForOrganization') }}</li>
      <li>
        {{ tt('billing.modal.accessToOrdersTendersElectronicDocumentFlowAndEightTools') }}
      </li>
    </template>
  </BillingPlanSelectionCard>

  <BillingPlanSelectionCard
    :title="tt('billing.planCode.extended')"
    :subtitle="tt('billing.modal.forLargeCarriers')"
    :price="`${CARRIER_EXTENDED_PLAN_PRICE} ${tt('shared.currency.symbol.rub')}`"
    :planCode="EBillingPlanCode.carrierExtended"
    forExtended
    @change="$emit('change', $event)"
  >
    <template #description>
      <li>{{ tt('billing.modal.unlimitedNumberOfManagersInTheOrganization') }}</li>
      <li>{{ tt('billing.modal.automaticFillingOfDriverAndCarDataUsingATemplate') }}</li>
      <li>{{ tt('billing.modal.transportationPriceCalculator') }}</li>
    </template>
  </BillingPlanSelectionCard>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import tt from '@/i18n/utils/translateText';
import { CARRIER_BASIC_PLAN_PRICE, CARRIER_EXTENDED_PLAN_PRICE } from '@/domains/constants';
import { EBillingPlanCode } from '@/contexts/billingContext/domain/constants';

import BillingPlanSelectionCard from '../BillingPlanSelectionCard/index.vue';

export default defineComponent({
  name: 'BillingCardsForCarrier',
  components: { BillingPlanSelectionCard },
  emits: ['change'],
  setup() {
    return {
      CARRIER_BASIC_PLAN_PRICE,
      CARRIER_EXTENDED_PLAN_PRICE,
      EBillingPlanCode,

      tt,
    };
  },
});
</script>
