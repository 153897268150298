import { Ref } from 'vue';

import { DROPDOWN_MARGIN, MIN_DROPDOWN_HEIGHT } from '@/ui/types/constants';
import {
  isBoolean,
  isNumber,
  joinString,
} from '@/utils';
import { getDropdownListHeight } from '@/ui/DropdownList/utils/getDropdownListHeight';

type TInlineStyle = {
  targetElementClientRect: Ref<DOMRect | null>,
  optionsLength: number | null,
  dropdownMatchSelectWidth: number | boolean,
  isFixedOnLeft: boolean,
};

export const getInlineStyle = ({
  targetElementClientRect,
  optionsLength,
  dropdownMatchSelectWidth,
  isFixedOnLeft,
}: TInlineStyle) => {
  if (!targetElementClientRect) return '';
  const clientHeight = getDropdownListHeight(optionsLength);

  let top = targetElementClientRect.value?.bottom ? `top: ${targetElementClientRect.value.bottom + DROPDOWN_MARGIN}px;` : '';
  // если DropdownList начинает упираться в окно браузера снизу, то он будет рендериться сверху контрола
  if (targetElementClientRect.value
    && (document.documentElement.clientHeight - targetElementClientRect.value.bottom < clientHeight + MIN_DROPDOWN_HEIGHT)) {
    // padding 4px [up + down] + margin 2px = 10
    const topPosition = targetElementClientRect.value.top - clientHeight - 10;
    top = `top: ${topPosition}px;`;
  }

  let left = targetElementClientRect.value?.left ? `left: ${targetElementClientRect.value.left}px;` : '';
  let width = '';
  if (isBoolean(dropdownMatchSelectWidth) && !dropdownMatchSelectWidth) {
    width = 'width: auto';
  } else if (isNumber(dropdownMatchSelectWidth)) {
    width = `min-width: ${dropdownMatchSelectWidth}px;`;
    if (targetElementClientRect.value) {
      if (isFixedOnLeft) {
        /** зафиксировать DropdownList у левого края Select'а */
        left = `left: ${targetElementClientRect.value.left}px;`;
      } else {
      // чтобы DropdownList всегда был прижат к правому краю контрола
        let leftPosition;
        const position = Math.abs(dropdownMatchSelectWidth - targetElementClientRect.value.width);
        if (dropdownMatchSelectWidth > targetElementClientRect.value.width) {
          leftPosition = targetElementClientRect.value.left - position;
        } else {
          leftPosition = targetElementClientRect.value.left + position;
        }
        left = leftPosition ? `left: ${leftPosition}px;` : '';
      }
    }
  } else {
    width = targetElementClientRect.value?.width ? `max-width: ${targetElementClientRect.value.width}px; width: 100%` : '';
  }

  return joinString([top, left, width]);
};
