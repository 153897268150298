import { defineStore } from 'pinia';
import { ref } from 'vue';

import { EViewSettingsSections } from './constants';
import { TViewSettings, IViewSettingsStore } from './types';

// Если есть соблазн вынести "sort: {sort: null, sortBy: null}" в отдельную константу, то не стоит этого делать
// В этом случае для каждой ref будет использоваться одна и та же ссылка на объект, и при изменении одной ref, будут меняться остальные
export const useViewSettingsStore = defineStore(
  'viewSettings',
  (): IViewSettingsStore => {
    const executorsLookupsFeed = ref<TViewSettings>({
      sort: {
        sort: null,
        sortBy: null,
      },
    });

    const contractorPersonalAccountCurrentRunsTable = ref<TViewSettings>({
      sort: {
        sort: null,
        sortBy: null,
      },
    });

    const contractorPersonalAccountFinishedRunsTable = ref<TViewSettings>({
      sort: {
        sort: null,
        sortBy: null,
      },
    });

    const contractorPersonalAccountPlannedRunsTable = ref<TViewSettings>({
      sort: {
        sort: null,
        sortBy: null,
      },
    });

    const ordersFinalized = ref<TViewSettings>({
      sort: {
        sort: null,
        sortBy: null,
      },
    });

    const ordersOnBidding = ref<TViewSettings>({
      sort: {
        sort: null,
        sortBy: null,
      },
    });

    const ordersOnExecution = ref<TViewSettings>({
      sort: {
        sort: null,
        sortBy: null,
      },
    });

    const ordersOnTargetOffering = ref<TViewSettings>({
      sort: {
        sort: null,
        sortBy: null,
      },
    });

    const ordersPending = ref<TViewSettings>({
      sort: {
        sort: null,
        sortBy: null,
      },
    });

    const setViewSetting = (section: EViewSettingsSections, data: TViewSettings) => {
      if (!section || !data) return;

      switch (section) {
        case EViewSettingsSections.executorsLookupsFeed: {
          executorsLookupsFeed.value = data;
          break;
        }
        case EViewSettingsSections.contractorPersonalAccountCurrentRunsTable: {
          contractorPersonalAccountCurrentRunsTable.value = data;
          break;
        }
        case EViewSettingsSections.contractorPersonalAccountFinishedRunsTable: {
          contractorPersonalAccountFinishedRunsTable.value = data;
          break;
        }
        case EViewSettingsSections.contractorPersonalAccountPlannedRunsTable: {
          contractorPersonalAccountPlannedRunsTable.value = data;
          break;
        }
        case EViewSettingsSections.ordersFinalized: {
          ordersFinalized.value = data;
          break;
        }
        case EViewSettingsSections.ordersOnBidding: {
          ordersOnBidding.value = data;
          break;
        }
        case EViewSettingsSections.ordersOnExecution: {
          ordersOnExecution.value = data;
          break;
        }
        case EViewSettingsSections.ordersOnTargetOffering: {
          ordersOnTargetOffering.value = data;
          break;
        }
        case EViewSettingsSections.ordersPending: {
          ordersPending.value = data;
          break;
        }
        default:
      }
    };

    const $reset = () => {
      executorsLookupsFeed.value = {
        sort: {
          sort: null,
          sortBy: null,
        },
      };
      contractorPersonalAccountCurrentRunsTable.value = {
        sort: {
          sort: null,
          sortBy: null,
        },
      };
      contractorPersonalAccountFinishedRunsTable.value = {
        sort: {
          sort: null,
          sortBy: null,
        },
      };
      contractorPersonalAccountPlannedRunsTable.value = {
        sort: {
          sort: null,
          sortBy: null,
        },
      };
      ordersFinalized.value = {
        sort: {
          sort: null,
          sortBy: null,
        },
      };
      ordersOnBidding.value = {
        sort: {
          sort: null,
          sortBy: null,
        },
      };
      ordersOnExecution.value = {
        sort: {
          sort: null,
          sortBy: null,
        },
      };
      ordersOnTargetOffering.value = {
        sort: {
          sort: null,
          sortBy: null,
        },
      };
      ordersPending.value = {
        sort: {
          sort: null,
          sortBy: null,
        },
      };
    };

    return {
      executorsLookupsFeed,
      contractorPersonalAccountCurrentRunsTable,
      contractorPersonalAccountFinishedRunsTable,
      contractorPersonalAccountPlannedRunsTable,
      ordersFinalized,
      ordersOnBidding,
      ordersOnExecution,
      ordersOnTargetOffering,
      ordersPending,

      setViewSetting,
      $reset,
    };
  },
  { persist: true },
);
