<template>
  <Input
    ref="inputRef"
    :value="formatInputValue(value, hasMask)"
    :type="EInputTypes.tel"
    maxlength="19"
    :placeholder="placeholder"
    :size="size"
    :isInvalid="isInvalid"
    :disabled="disabled"
    :allowClear="allowClear"
    @paste="onPaste"
    @keydown="onKeydown"
    @keyup.enter="$emit('enter', $event)"
    @input="onInput"
    @change="onChange"
    @blur="$emit('blur', $event)"
    @focus="$emit('focus', $event)"
    @reset="$emit('update:value', '')"
  />
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  ref,
} from 'vue';

import { ESize, EInputTypes } from '@/ui/types';

import Input from '../Input/index.vue';
import { getInputNumbersValue } from './utils/getInputNumbersValue';
import { formatInputValue } from './utils/formatInputValue';

export default defineComponent({
  name: 'PhoneInput',
  components: { Input },
  props: {
    value: {
      type: [String, null] as PropType<string | null>,
      default: null,
    },
    size: {
      type: String as PropType<ESize>,
      default: ESize.normal,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    /** отображение иконки очистки поля */
    allowClear: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '+7 999 999-99-99',
    },
    /** предзаполнение российского номера при вводе цифр 7/8/9. Временное решение до появления селекта выбора страны */
    hasMask: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'input',
    'change',
    'enter',
    'paste',
    'keydown',
    'blur',
    'focus',
    /** Поддержка двустороннего связывания (v-model) */
    'update:value',
    'clear',
  ],
  setup(props, { emit, expose }) {
    const inputRef = ref<HTMLInputElement>();

    const onPaste = (event: ClipboardEvent) => {
      const input = event.target as HTMLInputElement;
      const inputNumbersValue = getInputNumbersValue(input.value);
      const pasted = event.clipboardData;
      if (pasted) {
        // getData('text') - возращает данные переданного типа, в данном случае 'text', которые находятся в буфере обмена.
        const pastedText = pasted.getData('text');
        if (/\D/g.test(pastedText)) {
          // при попытке вставить не число
          input.value = inputNumbersValue;
          emit('update:value', inputNumbersValue);
        }
      }
      emit('paste', event);
    };

    const onKeydown = (event: KeyboardEvent) => {
      const input = event.target as HTMLInputElement;
      const inputValue = getInputNumbersValue(input.value);
      if (event.code === 'Backspace' && inputValue.length === 1) {
        input.value = '';
        emit('update:value', '');
      }
      emit('keydown', event);
    };

    const onInput = (event: InputEvent) => {
      const input = event.target as HTMLInputElement;
      const { selectionStart } = input;
      const inputNumbersValue = getInputNumbersValue(input.value);

      if (!inputNumbersValue) {
        input.value = '';
        emit('input', event);
        emit('update:value', '');
        return;
      }

      if (input.value.length !== selectionStart) {
        if (event.data && /\D/g.test(event.data)) {
          input.value = inputNumbersValue;
          emit('update:value', inputNumbersValue);
          emit('input', event);
        }
        return;
      }

      const formattedInputValue = formatInputValue(input.value, props.hasMask);
      input.value = formattedInputValue;
      emit('input', event);
      emit('update:value', formattedInputValue);
    };

    const onChange = (event: Event) => {
      const input = event.target as HTMLInputElement;
      const inputNumbersValue = getInputNumbersValue(input.value); // 79284728131
      emit('change', event);
      emit('update:value', inputNumbersValue);
    };

    const onFocus = () => {
      inputRef.value?.focus();
    };

    expose({ onFocus });

    return {
      inputRef,
      formatInputValue,
      ESize,
      EInputTypes,

      onPaste,
      onInput,
      onKeydown,
      onChange,
    };
  },
});
</script>
