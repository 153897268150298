import {
  TEntity,
  TQuickAction,
  TTableColumns,
} from '@/types';
import { TCustomStoreInAction } from '@/store/types';
import { TCustomTableSort, TTableConfigLoaded } from '@/components/CustomTableLayout/types';

import * as types from './mutation-types';

export enum EFoldersSection {
  matricesList = 'matricesList',
  orderList = 'orderList',
  referencesList = 'referencesList',
}

export type TFolderWithSettings = TEntity & {
  columnSettings: TTableColumns,
  quickActions: TQuickAction[],
  sort: TCustomTableSort,
};

export type TFoldersWithSettings = TFolderWithSettings[];

export type TFoldersState = {
  [EFoldersSection.matricesList]: {
    currentFolderId: string | null,
    folders: TFoldersWithSettings,
  },
  [EFoldersSection.orderList]: {
    currentFolderId: string | null,
    currentOrderIid?: number | null,
    folders: TFoldersWithSettings,
  },
  [EFoldersSection.referencesList]: {
    currentFolderId: string | null,
    folders: TFoldersWithSettings,
  },
};

export type TFoldersMutations<S = TFoldersState> = {
  [types.SET_CURRENT_FOLDER_ID](state: S, payload: { section: EFoldersSection, currentFolderId: string | null }): void,
  [types.SET_CURRENT_ORDER_IID](state: S, payload: { section: EFoldersSection, currentOrderIid: number | null }): void,
  [types.SET_COLUMN_SETTINGS](state: S, payload: {
    section: EFoldersSection,
    columnSettings: TTableColumns | TTableConfigLoaded,
  }): void,
  [types.SET_SORT](state: S, payload: { section: EFoldersSection, sort: TCustomTableSort }): void,
  [types.SET_FOLDERS](state: S, payload: { section: EFoldersSection, folders: TEntity[] }): void,
  [types.SET_QUICK_ACTIONS](state: S, payload: { section: EFoldersSection, quickActions: TQuickAction[] }): void,
  [types.RESET_STATE](state: S): void,
};

type TColumnSettingsActionPayload = {
  section: EFoldersSection,
  columnSettings: TTableColumns,
};

type TSortActionPayload = {
  section: EFoldersSection,
  sort: TCustomTableSort,
};

type TFoldersActionPayload = {
  section: EFoldersSection,
  folders: TEntity[],
};

type TQuickActionsActionPayload = {
  section: EFoldersSection,
  quickActions: TQuickAction[],
};

export type TFoldersActions<S = TCustomStoreInAction<TFoldersState>> = {
  setColumnSettings(state: S, payload: TColumnSettingsActionPayload): void,
  setSort(state: S, payload: TSortActionPayload): void,
  setFolders(state: S, payload: TFoldersActionPayload): void,
  setQuickActions(state: S, payload: TQuickActionsActionPayload): void,
  resetState(store: S): void,
};
