import { getActualAuthToken } from '@/api/sendApiRequest';
import logger from '@/logger';
import store from '@/store';

import {
  _connectConsumer,
  closeConnection,
  createConsumer,
  getConsumer,
  getCurrentTenantId,
  getUrl,
} from './index';
import { addTaskToStack } from './tasksStack';

export const openWsConnection = () => {
  createConsumer();
};

const setUrlToConsumer = async () => {
  const consumer = await getConsumer();
  const tokens = await getActualAuthToken() as any;
  const tenantId = getCurrentTenantId();

  if (!tokens?.accessToken || !tenantId) {
    const message = '[setUrlToConsumer] accessToken or tenantId is undefined. Logout.';
    logger.warn(message);
    throw new Error(message);
  }

  consumer.url = getUrl({
    tenantId,
    token: tokens?.accessToken,
  }) || '';
};

export const disconnectConsumer = async () => {
  const consumer = await getConsumer();
  consumer.disconnect();
};

export const reconnectWithNewUrl = async () => {
  const removeTask = addTaskToStack();
  await disconnectConsumer();
  try {
    await setUrlToConsumer();
  } catch {
    closeConnection();
    // TODO: временный лог
    // eslint-disable-next-line
    console.log('[reconnectWithNewUrl] setUrlToConsumer().catch');
    store.dispatch('app/logout');
    return;
  }
  _connectConsumer();
  removeTask();
};
