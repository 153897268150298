import {
  readNotification,
  unreadNotification,
  getUnreadNotificationsCount,
  getNotifications,
} from '@/contexts/notificationsContext/services';

import * as types from './mutation-types';
import { TNotificationsActions } from './types';

const actions: TNotificationsActions = {
  setUnreadCount: ({ commit }, unreadCount) => {
    commit(types.SET_UNREAD_COUNT, unreadCount);
  },
  fetchUnreadNotificationsCount: ({ commit }) => {
    getUnreadNotificationsCount()
      .then((response) => {
        if (!response.data) return;
        commit(types.SET_UNREAD_COUNT, response.data.unreadCount);
      })
      .catch(() => {});
  },
  fetchNotifications: ({ commit, state }, { onlyUnread, pagination }) => {
    commit(types.SET_IS_NOTIFICATIONS_LOADING, true);
    return getNotifications(onlyUnread, pagination)
      .then((response) => {
        if (response.data) {
          if (Array.isArray(state.notifications.ids)) {
            commit(types.APPEND_NOTIFICATIONS, response.data);
          } else {
            commit(types.SET_NOTIFICATIONS, response.data);
          }
        }
        commit(types.SET_IS_NOTIFICATIONS_LOADING, false);
        return response;
      });
  },
  readNotificationById: ({ commit }, notificationId) => readNotification(notificationId)
    .then(() => {
      commit(types.DECREMENT_UNREAD_COUNT);
      commit(types.CHANGE_NOTIFICATION_READ_STATE, {
        notificationId,
        isRead: true,
      });
    }),
  unreadNotificationById: ({ commit }, notificationId) => unreadNotification(notificationId)
    .then(() => {
      commit(types.INCREMENT_UNREAD_COUNT);
      commit(types.CHANGE_NOTIFICATION_READ_STATE, {
        notificationId,
        isRead: false,
      });
    }),
  incrementUnreadCount: ({ commit }) => {
    commit(types.INCREMENT_UNREAD_COUNT);
  },
  decrementUnreadCount: ({ commit }) => {
    commit(types.DECREMENT_UNREAD_COUNT);
  },
  toggleNotificationsListVisible: ({ commit }, visible) => {
    commit(types.TOGGLE_NOTIFICATION_LIST_VISIBLE, visible);
  },
  setAppStateWithUnreadNotification: ({ commit }, isTabWithUnreadNotifications) => {
    commit(types.SET_APP_STATE_WITH_UNREAD_NOTIFICATIONS, isTabWithUnreadNotifications);
  },
  resetState: ({ commit }) => {
    commit(types.RESET_STATE);
  },
};

export default actions;
