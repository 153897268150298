import { i18n } from '@/i18n';

import * as types from './mutation-types';
import { TI18nMutations } from './types';

const mutations: TI18nMutations = {
  [types.SET_CURRENT_LOCALE](state, nextLocale) {
    state.currentLocale = nextLocale;
    i18n.global.locale = nextLocale;
  },
};

export default mutations;
