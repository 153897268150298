<template>
  <div class="radio-group">
    <template v-if="options.length">
      <template v-for="option in options" :key="option.value">
        <component
          :is="componentByType[optionType]"
          :value="option.value"
        >
          {{ option.label }}
        </component>
      </template>
    </template>
    <!-- слот для вывода Radio или RadioButton без передачи props.options -->
    <slot />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  PropType,
  provide,
  watch,
} from 'vue';

import {
  ESize,
  ERadioButtonOptionType,
  TRadioGroupOption,
  RadioGroupContextKey,
} from '@/ui/types';

import Radio from '../Radio/index.vue';
import RadioButton from '../RadioButton/index.vue';

const componentByType = {
  default: 'Radio',
  button: 'RadioButton',
};

export default defineComponent({
  name: 'RadioGroup',
  components: {
    Radio,
    RadioButton,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    optionType: {
      type: String as PropType<ERadioButtonOptionType>,
      default: ERadioButtonOptionType.default,
    },
    size: {
      type: String as PropType<ESize>,
      default: ESize.normal,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array as PropType<TRadioGroupOption[]>,
      default: () => [],
    },
  },
  emits: ['change', 'update:value'],
  setup(props, { emit }) {
    const stateValue = ref(props.value);

    const onRadioChange = (event: Event) => {
      const lastValue = stateValue.value;
      const { value } = (event.target as HTMLInputElement);

      if (value !== lastValue) {
        emit('update:value', value);
        emit('change', event);
      }
    };

    provide(RadioGroupContextKey, {
      props,
      stateValue,
      onRadioChange,
    });

    watch(() => props.value, (newValue: string) => {
      stateValue.value = newValue;
    });

    return { componentByType };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
