import {
  ComputedRef,
  computed,
} from 'vue';

import CompanySvg from '@/assets/svg/16x16/company.svg';
import UserSvg from '@/assets/svg/16x16/user.svg';
import CommitmentSvg from '@/assets/svg/16x16/commitment.svg';
import AutomatizationSvg from '@/assets/svg/16x16/automatization.svg';
import BellSvg from '@/assets/svg/16x16/bell.svg';
import CalendarSvg from '@/assets/svg/16x16/calendar.svg';
import ServiceSvg from '@/assets/svg/16x16/service.svg';
import PartnersSvg from '@/assets/svg/16x16/partners.svg';
import RouteSvg from '@/assets/svg/16x16/route.svg';
import FeedSvg from '@/assets/svg/16x16/feed.svg';
import TruckSvg from '@/assets/svg/16x16/truck.svg';
import PriceSvg from '@/assets/svg/16x16/price.svg';
import QueueSvg from '@/assets/svg/16x16/queue.svg';
import PickupSvg from '@/assets/svg/16x16/pickup.svg';
import TenderSvg from '@/assets/svg/16x16/tender.svg';
import DoubleArrowSvg from '@/assets/svg/16x16/double-arrow.svg';
import GuaranteeSvg from '@/assets/svg/16x16/guarantee.svg';
import ReportSvg from '@/assets/svg/16x16/report.svg';
import CalculateSvg from '@/assets/svg/16x16/calculate.svg';
import FileSvg from '@/assets/svg/16x16/file.svg';
import AgreementSvg from '@/assets/svg/16x16/agreement.svg';
import FolderStrokeSvg from '@/assets/svg/16x16/folder-stroke.svg';
import {
  hasMakeFreightRequestsPermission,
  hasExecuteRunsPermission,
  hasParticipateInLookupsPermission,
  hasDigitalQueuesPermission,
  hasPriceAnalyticsPermission,
  hasUseReportTemplatesPermission,
  hasExtraServicesPermission,
  hasOperationalDocumentsPermission,
  hasTrackRunsPermission,
  hasCargoOwnerWorkplaceSubscription,
  hasCarrierWorkplaceSubscription,
  hasShipperWorkplaceSubscription,
  hasPriceCalculatorSubscription,
} from '@/domains/permissions/subscription';
import { canBrowseExecutionRequestsByRole } from '@/domains/permissions/role/executionRequests';
import { canBrowseEmployeesByRole } from '@/domains/permissions/role/employees';
import { canBrowseContractorsByRole } from '@/domains/permissions/role/contractors';
import { canBrowsePartnershipsByRole } from '@/domains/permissions/role/partnerships';
import { canBrowseLookupStrategiesByRole } from '@/domains/permissions/role/lookupStrategies';
import { canBrowsePriceListsByRole } from '@/domains/permissions/role/priceLists';
import { canBrowseTendersByRole } from '@/domains/permissions/role/tenders';
import { canBrowseGuaranteeListsByRole } from '@/domains/permissions/role/guaranteeLists';
import { canBrowseDirectionsByRole } from '@/domains/permissions/role/directions';
import { canBrowseShipmentsByRole } from '@/domains/permissions/role/shipments';
import {
  canBrowseDigitalQueuesByCustomAccessRole,
  canBrowseDigitalQueuesByRole,
} from '@/domains/permissions/role/digitalQueues';
import { canBrowseReportsTemplatesByRole } from '@/domains/permissions/role/reportTemplates';
import hasCurrentTenant from '@/domains/hasCurrentTenant';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import { canBrowseOrdersSectionByRole } from '@/domains/permissions/role/orders';
import { canBrowseFreightRequestsSectionByRole } from '@/domains/permissions/role/freightRequests';
import { getIsTenantWithCustomization } from '@/domains/getIsTenantWithCustomization';
import { canBrowseExtraServicesByRole } from '@/domains/permissions/role/extraServices';
import { canBrowseApprovalsByRole } from '@/domains/permissions/role/approvals';
import { canBrowseMatricesByRole } from '@/domains/permissions/role/matrices';
import {
  TCommonSchemaItem,
  TStaticSchemaItem,
} from '@/components/layouts/AppLayoutWrapper/components/AppLayoutSider/domains/types';
import {
  ECommonSchemaItemKeys,
  ECommonSchemaItemLabels,
  ECommonSchemaItemType,
  EShipperRunsKeys,
  EShipperRunsLabels,
} from '@/components/layouts/AppLayoutWrapper/components/AppLayoutSider/domains/constants';
import { TFolderWithSettings } from '@/store/modules/folders/types';
import { canBrowseRunsByRole } from '@/domains/permissions/role/runs';
import canBrowseCustomReferencesByRole from '@/domains/permissions/role/customReferences/canBrowseCustomReferencesByRole';

export const useView = (
  matricesFolders?: ComputedRef<TFolderWithSettings[]>,
) => {
  const hasDigitalQueueSubscription = computed(() => (
    checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March)
      ? hasCargoOwnerWorkplaceSubscription()
      : hasDigitalQueuesPermission()),
  );

  const hasExecuteRunsSubscription = computed(() => (
    checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March)
      ? hasCarrierWorkplaceSubscription()
      : hasExecuteRunsPermission()),
  );

  const hasExtraServicesSubscription = computed(() => (
    checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March)
      ? hasCargoOwnerWorkplaceSubscription()
      : hasExtraServicesPermission()),
  );

  const hasMakeFreightRequestsSubscription = computed(() => (
    checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March)
      ? hasCargoOwnerWorkplaceSubscription()
      : hasMakeFreightRequestsPermission()),
  );

  const hasParticipateInLookupsSubscription = computed(() => (
    checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March)
      ? hasCarrierWorkplaceSubscription()
      : hasParticipateInLookupsPermission()),
  );

  const hasTransportationCalculatorSubscription = computed(() => (
    checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March)
      ? hasPriceCalculatorSubscription()
      : (hasParticipateInLookupsPermission() && hasPriceAnalyticsPermission())),
  );

  const hasTrackRunsSubscription = computed(() => (
    checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March)
      ? hasCargoOwnerWorkplaceSubscription() || hasShipperWorkplaceSubscription()
      : hasTrackRunsPermission()),
  );

  const hasReportTemplatesSubscription = computed(() => (
    checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March)
      ? hasCargoOwnerWorkplaceSubscription()
      : hasUseReportTemplatesPermission()),
  );

  const hasOperationalDocumentsSubscription = computed(() => (
    checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March)
      ? hasCargoOwnerWorkplaceSubscription() || hasCarrierWorkplaceSubscription() || hasShipperWorkplaceSubscription()
      : hasOperationalDocumentsPermission()),
  );

  const hasNotificationsSubscription = computed(() => (
    checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March)
      ? hasCargoOwnerWorkplaceSubscription() || hasCarrierWorkplaceSubscription() || hasShipperWorkplaceSubscription()
      : true),
  );

  const componentsSchema: TStaticSchemaItem[] = [
    {
      key: 'notifications',
      label: 'notifications',
      icon: BellSvg,
      permissions: [
        hasCurrentTenant,
        canBrowseEmployeesByRole,
        () => hasNotificationsSubscription.value,
      ],
      component: 'NotificationMenuItem',
    },
  ];

  const ordersSchemaItem: TCommonSchemaItem = {
    key: ECommonSchemaItemKeys.orders,
    path: '/orders/only_orders',
    label: ECommonSchemaItemLabels.orders,
    icon: CalendarSvg,
    permissions: [
      () => hasMakeFreightRequestsSubscription.value,
      () => (canBrowseFreightRequestsSectionByRole() || canBrowseOrdersSectionByRole()),
    ],
    type: ECommonSchemaItemType.submenu,
  };

  const commonSchemaItems: ComputedRef<TCommonSchemaItem[]> = computed(() => [
    {
      key: ECommonSchemaItemKeys.transportationCalculator,
      path: '/transportation_calculator',
      label: ECommonSchemaItemLabels.transportationCalculator,
      icon: CalculateSvg,
      permissions: [() => hasTransportationCalculatorSubscription.value],
      type: ECommonSchemaItemType.withDivider,
    },
    {
      key: ECommonSchemaItemKeys.digitalQueues,
      path: '/digital_queues',
      label: ECommonSchemaItemLabels.digitalQueues,
      icon: QueueSvg,
      permissions: [() => (hasDigitalQueueSubscription.value
        && (canBrowseDigitalQueuesByRole() || canBrowseDigitalQueuesByCustomAccessRole())
      )],
      type: ECommonSchemaItemType.default,
    },
    {
      key: ECommonSchemaItemKeys.shipments,
      path: '/shipments',
      label: ECommonSchemaItemLabels.shipments,
      icon: PickupSvg,
      permissions: [() => hasDigitalQueueSubscription.value, canBrowseShipmentsByRole],
      type: ECommonSchemaItemType.default,
    },
    {
      key: ECommonSchemaItemKeys.directions,
      path: '/directions',
      label: ECommonSchemaItemLabels.directions,
      icon: DoubleArrowSvg,
      permissions: [
        () => !getIsTenantWithCustomization(),
        () => hasMakeFreightRequestsSubscription.value,
        canBrowseDirectionsByRole,
      ],
      type: ECommonSchemaItemType.default,
    },
    {
      key: ECommonSchemaItemKeys.matrices,
      path: (matricesFolders && matricesFolders.value.length === 1) ? `/matrices?folderId=${matricesFolders && matricesFolders.value[0]?.id}` : '/matrices',
      label: ECommonSchemaItemLabels.matrices,
      icon: DoubleArrowSvg,
      permissions: [
        () => getIsTenantWithCustomization(),
        () => hasMakeFreightRequestsSubscription.value,
        canBrowseMatricesByRole,
      ],
      type: (matricesFolders && matricesFolders.value.length === 1)
        ? ECommonSchemaItemType.default : ECommonSchemaItemType.submenu,
    },
    {
      key: ECommonSchemaItemKeys.references,
      path: '/references',
      label: ECommonSchemaItemLabels.references,
      icon: FolderStrokeSvg,
      permissions: [
        () => checkFeatureIsEnabled(EExperimentalFeatures.customReferences),
        () => getIsTenantWithCustomization(),
        () => hasMakeFreightRequestsSubscription.value,
        canBrowseCustomReferencesByRole,
      ],
      type: ECommonSchemaItemType.submenu,
    },
    {
      key: ECommonSchemaItemKeys.quotas,
      path: '/quotas',
      label: ECommonSchemaItemLabels.quotas,
      icon: GuaranteeSvg,
      permissions: [
        () => !getIsTenantWithCustomization(),
        () => hasMakeFreightRequestsSubscription.value,
        canBrowseGuaranteeListsByRole,
      ],
      type: ECommonSchemaItemType.default,
    },
    {
      key: ECommonSchemaItemKeys.executorsLookupsFeed,
      path: '/executors_lookups/feed',
      label: ECommonSchemaItemLabels.executorsLookupsFeed,
      icon: FeedSvg,
      permissions: [() => (hasExecuteRunsSubscription.value || hasParticipateInLookupsSubscription.value)],
      type: ECommonSchemaItemType.default,
    },
    {
      key: ECommonSchemaItemKeys.tenders,
      path: '/tenders',
      label: ECommonSchemaItemLabels.tenders,
      icon: TenderSvg,
      permissions: [
        canBrowseTendersByRole,
        () => hasMakeFreightRequestsSubscription.value || hasExecuteRunsSubscription.value,
      ],
      type: ECommonSchemaItemType.default,
    },
    {
      key: ECommonSchemaItemKeys.employees,
      path: '/employees',
      label: ECommonSchemaItemLabels.employees,
      icon: UserSvg,
      permissions: [hasCurrentTenant, canBrowseEmployeesByRole],
      type: ECommonSchemaItemType.default,
    },
    {
      key: ECommonSchemaItemKeys.contractors,
      path: '/contractors',
      label: ECommonSchemaItemLabels.contractors,
      icon: CompanySvg,
      permissions: [
        hasCurrentTenant,
        canBrowseContractorsByRole,
        () => hasMakeFreightRequestsSubscription.value || hasExecuteRunsSubscription.value,
      ],
      type: ECommonSchemaItemType.default,
    },
    {
      key: ECommonSchemaItemKeys.operationalDocuments,
      path: '/operational_documents',
      label: ECommonSchemaItemLabels.operationalDocuments,
      icon: FileSvg,
      permissions: [() => hasOperationalDocumentsSubscription.value],
      type: ECommonSchemaItemType.default,
    },
    {
      key: ECommonSchemaItemKeys.vehicles,
      path: '/vehicles',
      label: ECommonSchemaItemLabels.vehicles,
      icon: TruckSvg,
      permissions: [() => hasExecuteRunsSubscription.value],
      type: ECommonSchemaItemType.default,
    },
    {
      key: ECommonSchemaItemKeys.partnerships,
      path: '/partnerships',
      label: ECommonSchemaItemLabels.partnerships,
      icon: PartnersSvg,
      permissions: [
        hasCurrentTenant,
        canBrowsePartnershipsByRole,
        () => hasMakeFreightRequestsSubscription.value || hasExecuteRunsSubscription.value,
      ],
      type: ECommonSchemaItemType.default,
    },
    {
      key: ECommonSchemaItemKeys.executionRequests,
      path: '/execution_requests',
      label: ECommonSchemaItemLabels.executionRequests,
      icon: CommitmentSvg,
      permissions: [
        canBrowseExecutionRequestsByRole,
        () => hasExecuteRunsSubscription.value,
      ],
      type: ECommonSchemaItemType.default,
    },
    {
      key: ECommonSchemaItemKeys.lookupStrategies,
      path: '/lookup_strategies',
      label: ECommonSchemaItemLabels.lookupStrategies,
      icon: AutomatizationSvg,
      permissions: [
        () => hasMakeFreightRequestsSubscription.value,
        canBrowseLookupStrategiesByRole,
      ],
      type: ECommonSchemaItemType.default,
    },
    {
      key: ECommonSchemaItemKeys.priceLists,
      path: '/price_lists',
      label: ECommonSchemaItemLabels.priceLists,
      icon: PriceSvg,
      permissions: [
        () => !getIsTenantWithCustomization(),
        () => hasMakeFreightRequestsSubscription.value,
        canBrowsePriceListsByRole,
      ],
      type: ECommonSchemaItemType.default,
    },
    {
      key: ECommonSchemaItemKeys.reportTemplates,
      path: '/report_templates',
      label: ECommonSchemaItemLabels.reportTemplates,
      icon: ReportSvg,
      permissions: [
        canBrowseReportsTemplatesByRole,
        () => hasReportTemplatesSubscription.value,
      ],
      type: ECommonSchemaItemType.default,
    },
    {
      key: ECommonSchemaItemKeys.extraServices,
      path: '/extra_services',
      label: ECommonSchemaItemLabels.extraServices,
      icon: ServiceSvg,
      permissions: [() => hasExtraServicesSubscription.value, canBrowseExtraServicesByRole],
      type: ECommonSchemaItemType.default,
    },
    {
      key: ECommonSchemaItemKeys.approvals,
      path: '/approvals',
      label: ECommonSchemaItemLabels.approvals,
      icon: AgreementSvg,
      permissions: [
        () => hasMakeFreightRequestsSubscription.value || hasExecuteRunsSubscription.value,
        canBrowseApprovalsByRole,
      ],
      type: ECommonSchemaItemType.default,
    },
    {
      key: ECommonSchemaItemKeys.shipperRuns,
      path: '/contractor_personal_account/runs',
      label: ECommonSchemaItemLabels.runsWatching,
      icon: RouteSvg,
      permissions: [() => hasTrackRunsSubscription.value, canBrowseRunsByRole],
      type: ECommonSchemaItemType.submenu,
    },
  ]);

  const shipperRunsSchema: TCommonSchemaItem[] = [
    {
      key: EShipperRunsKeys.current,
      path: '/contractor_personal_account/runs/current',
      label: EShipperRunsLabels.current,
      type: ECommonSchemaItemType.default,
      permissions: [() => hasTrackRunsSubscription.value, canBrowseRunsByRole],
    },
    {
      key: EShipperRunsKeys.planned,
      path: '/contractor_personal_account/runs/planned',
      label: EShipperRunsLabels.planned,
      type: ECommonSchemaItemType.default,
      permissions: [() => hasTrackRunsSubscription.value, canBrowseRunsByRole],
    },
    {
      key: EShipperRunsKeys.finished,
      path: '/contractor_personal_account/runs/finished',
      label: EShipperRunsLabels.finished,
      type: ECommonSchemaItemType.default,
      permissions: [() => hasTrackRunsSubscription.value, canBrowseRunsByRole],
    },
    {
      key: EShipperRunsKeys.incidents,
      path: '/contractor_personal_account/incidents',
      label: EShipperRunsLabels.incidents,
      type: ECommonSchemaItemType.default,
      permissions: [() => hasTrackRunsSubscription.value, canBrowseRunsByRole],
    },
  ];

  const PAGES_AVAILABLE_AFTER_VERIFICATION = ['operational_documents', 'partnerships'];

  return {
    componentsSchema,
    ordersSchemaItem,
    commonSchemaItems,
    shipperRunsSchema,
    PAGES_AVAILABLE_AFTER_VERIFICATION,
  };
};
