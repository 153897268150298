import { hasProperty } from '@/utils';

import * as types from './mutation-types';
import { TPaginationMutations } from './types';

const mutations: TPaginationMutations = {
  [types.SET_TABLE_PAGINATION](state, { tableName, pagination }) {
    if (!hasProperty(state, tableName)) {
      console.warn(`There is no table with "${tableName}" name.`);
      return;
    }
    state[tableName] = pagination;
  },
  [types.RESET_ALL_PAGINATION](state) {
    state.operationalDocumentsListTable = null;
    state.orderListOnlyOrdersTable = null;
    state.executionRequestsTable = null;
    state.priceListTable = null;
    state.executorsLookupTable = null;
    state.directionsTable = null;
    state.vehiclesTable = null;
    state.runsTable = null;
    state.quotasTable = null;
    state.lotsTable = null;
    state.employeesTable = null;
    state.partnershipsTable = null;
    state.contractorPersonalAccountPlannedRunsTable = null;
    state.contractorPersonalAccountCurrentRunsTable = null;
    state.contractorPersonalAccountFinishedRunsTable = null;
    state.directionOrdersTable = null;
    state.matricesTable = null;
    state.shipmentsListTable = null;
    state.contractorPersonalAccountIncidentsTable = null;
    state.approvalsClaimsTable = null;
    state.approvalsSurchargesTable = null;
    state.incomingPayloadsTable = null;
    state.partnershipsPartnerContracts = null;
    state.executorsLookupFeedTable = null;
    state.tenantsAdminTable = null;
    state.informationRequestDirectionsTable = null;
    state.partnershipContractsTable = null;
    state.partnershipInvitationsTable = null;
    state.customReferencesTable = null;
    state.tendersTable = null;
    state.partnershipsPartnerContactsTable = null;
  },
};

export default mutations;
