import { useRoute, useRouter } from 'vue-router';
import { ref, computed } from 'vue';

import useStore from '@/store/useStore';
import {
  EAppState,
  ROUTE_TYPE,
} from '@/constants';
import isCurrentUserAdmin from '@/contexts/accountingContext/domain/isCurrentUserAdmin';
import redirectToInitialRoute from '@/domains/redirectToInitialRoute';
import logger from '@/logger';
import { useSiderMenuItems } from '@/components/layouts/AppLayoutWrapper/components/AppLayoutSider/application/useSiderMenuItems';
import { ECommonSchemaItemType } from '@/components/layouts/AppLayoutWrapper/components/AppLayoutSider/domains/constants';
import isCurrentUserSupport from '@/contexts/accountingContext/domain/isCurrentUserSupport';

export const useAppGeneralNavigation = () => {
  const store = useStore();
  const router = useRouter();
  const route = useRoute();

  const { siderMenuItems } = useSiderMenuItems();

  const redirectPathToSignIn = computed(() => {
    const isAuthByEntryCode = store.getters['app/isAuthByEntryCode'];
    const { dataBeforeAuth } = store.state.app;

    return isAuthByEntryCode
      ? `/contractor_personal_account/sign_in?entry_uid=${dataBeforeAuth?.entryUid}`
      : '/sign_in';
  });

  const subscribeRouterGuards = () => {
    router.beforeEach((to, from, next) => {
      const { state } = store.state.app;
      const { dataBeforeAuth } = store.state.app;
      const isAuthByEntryCode = store.getters['app/isAuthByEntryCode'];

      if (state === EAppState.authenticated) {
        if (to.meta.type === ROUTE_TYPE.unauthenticated) {
          logger.log('[Router Guard] Navigate to /');
          router.push('/');
          return;
        }
      } else if (state !== EAppState.checking && to.meta.type === ROUTE_TYPE.private) {
        logger.log('[Router Guard] Navigate to sign_in');
        // если не авторизован и находится на private роуте, редиректим на Регистрацию
        if (isAuthByEntryCode) {
          router.push(`/contractor_personal_account/sign_in?entry_uid=${dataBeforeAuth?.entryUid}`);
        } else {
          router.push('/sign_in');
        }
        return;
      }
      /**
       * Т.к. в next() описан warn(`The "next" callback was called more than once in one navigation
       * guard when going from "${from.fullPath}" to "${to.fullPath}". It should be called exactly
       * one time in each navigation guard. This will fail in production.`)
       * добавляем проверку
       */
      if (from.fullPath !== to.fullPath) {
        next();
      }
    });
  };

  const initializeRouter = () => router.isReady()
    .then(subscribeRouterGuards)
    .catch(() => {});

  const isRouteUnauthorized = (appRoute) => appRoute?.meta?.type === ROUTE_TYPE.unauthenticated;
  const isRoutePublic = (appRoute) => appRoute?.meta?.type === ROUTE_TYPE.public;
  const isAdminRoute = (appRoute) => appRoute?.meta?.type === ROUTE_TYPE.admin;
  const isPrivateRoute = (appRoute) => appRoute?.meta?.type === ROUTE_TYPE.private;

  const isMainRoute = (appRoute) => appRoute?.path === '/';
  const isInvitationAcceptanceRoute = (appRoute) => appRoute?.name === 'invitationAcceptance';
  const isTenantsListEmpty = (tenants) => tenants.length === 0;

  const isNavigationAfterSignInInProgress = ref(false);

  const commonRouteHandler = () => {
    const tenants = store.getters['tenants/availableUserTenants'];
    const { currentTenant } = store.state.tenants;
    const { pathBeforeAuth } = store.state.app;

    /**
     * Если роут для принятия приглашения к подключению к TMS (переход по ссылке из письма)
     */
    if (isInvitationAcceptanceRoute(route)) return;

    if (isTenantsListEmpty(tenants)) {
      router.push('/').then(() => {
        isNavigationAfterSignInInProgress.value = false;
      });
    } else if (currentTenant) {
      if (!isMainRoute(route)) return;
      redirectToInitialRoute(pathBeforeAuth);
    } else {
      router.push('/');
    }
  };

  const navigateAfterSignIn = () => {
    const tenants = store.getters['tenants/availableUserTenants'];
    if (isRouteUnauthorized(route) && !isTenantsListEmpty(tenants)) {
      // фильтруем страницы без типа withDivider, т.к. нам нужны только основные
      const firstPage = siderMenuItems.value.filter((item) => item.type !== ECommonSchemaItemType.withDivider)[0].path;
      if (firstPage) {
        isNavigationAfterSignInInProgress.value = true;
        router.push(firstPage)
          .catch(() => {})
          .finally(() => {
            isNavigationAfterSignInInProgress.value = false;
          });
      } else {
        commonRouteHandler();
      }
    } else if (isCurrentUserAdmin() || isCurrentUserSupport()) {
      if (!isAdminRoute(route)) {
        commonRouteHandler();
      }
    } else {
      commonRouteHandler();
    }
  };

  const navigateAfterLogout = () => {
    router.push(redirectPathToSignIn.value);
  };

  const navigateToMaintenance = () => {
    router.push('/maintenance');
  };

  const navigateAfterFailedAuthentication = () => {
    if (!isRouteUnauthorized(route) && !isRoutePublic(route)) {
      navigateAfterLogout();
    }
  };

  return {
    isNavigationAfterSignInInProgress,
    redirectPathToSignIn,

    initializeRouter,
    navigateAfterSignIn,
    navigateAfterLogout,
    navigateToMaintenance,
    navigateAfterFailedAuthentication,
    isPrivateRoute,
    isAdminRoute,
  };
};
