import { EFoldersSection, TFoldersState } from './types';

export const initialState = (): TFoldersState => ({
  [EFoldersSection.matricesList]: {
    currentFolderId: null,
    folders: [],
  },
  [EFoldersSection.orderList]: {
    currentFolderId: null,
    currentOrderIid: null,
    folders: [],
  },
  [EFoldersSection.referencesList]: {
    currentFolderId: null,
    folders: [],
  },
});
