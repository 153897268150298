import { onMounted } from 'vue';

import store from '@/store';
import { i18n } from '@/i18n';

const useI18n = () => {
  onMounted(() => {
    i18n.global.locale = store.state.i18n.currentLocale;
  });
};

export default useI18n;
