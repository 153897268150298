<template>
  <!--
    lockedSince - дата блокировки аккаунта за неудачные попытки входа.
    Если есть пользователь и дата, то показываем форму для разблокировки
  -->
  <template v-if="user && lockedSince">
    <Layout class="app-layout-wrapper">
      <UnlockView />
    </Layout>
  </template>
  <!--
    Если истек таймер валидности пароля и в сторе есть дата валидности пароля
    то выводим страницу обновления пароля
  -->
  <template v-else-if="isOTP || (!passwordExpirationTimer && passwordExpiresAt)">
    <UpdatePasswordView :isOTP="isOTP" />
  </template>

  <div
    v-else
    class="app-layout-wrapper"
  >
    <BillingPaywallMessage v-if="getIsPaywallMessageShown()" />
    <Layout
      :hasSider="hasSider"
    >
      <AppLayoutSider v-if="hasSider" />
      <VerificationWarning v-if="showVerificationWarning" />
      <SubscriptionWarning v-else-if="showSubscriptionWarning" />
      <slot v-else />
    </Layout>
  </div>
</template>

<script lang="ts">
import { Layout } from 'ant-design-vue';
import { defineComponent } from 'vue';

import UpdatePasswordView from '@/views/Infrastructure/UpdatePasswordView/index.vue';
import UnlockView from '@/views/Infrastructure/UnlockView/index.vue';
import BillingPaywallMessage from '@/components/billing/BillingPaywallMessage/index.vue';
import { getIsPaywallMessageShown } from '@/domains/billing/getIsPaywallMessageShown';
import VerificationWarning from '@/components/VerificationWarning/index.vue';
import SubscriptionWarning from '@/components/SubscriptionWarning/index.vue';

import { useView } from './application/useView';
import AppLayoutSider from './components/AppLayoutSider/index.vue';

export default defineComponent({
  name: 'AppLayoutWrapper',
  components: {
    Layout,
    AppLayoutSider,
    UpdatePasswordView,
    UnlockView,
    BillingPaywallMessage,
    VerificationWarning,
    SubscriptionWarning,
  },
  props: {
    hasSider: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {
      user,
      passwordExpirationTimer,
      passwordExpiresAt,
      isOTP,
      lockedSince,
      showVerificationWarning,
      showSubscriptionWarning,
    } = useView();

    return {
      user,
      passwordExpirationTimer,
      passwordExpiresAt,
      isOTP,
      lockedSince,
      showVerificationWarning,
      showSubscriptionWarning,

      getIsPaywallMessageShown,
    };
  },
});
</script>

<style lang="scss" scoped src="./styles.scss" />
