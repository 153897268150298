import {
  EFoldersSection,
  TFoldersState,
  TFoldersWithSettings,
  TFolderWithSettings,
} from './types';

const getCurrentFolder = (
  folders: TFoldersWithSettings,
  currentFolderId: string | null,
) => folders?.find((folder: TFolderWithSettings) => folder.id === currentFolderId) || null;

export const getColumnSettings = (state: TFoldersState) => (
  section: EFoldersSection,
  folderFilterId: string | null = state[section].currentFolderId,
) => getCurrentFolder(state[section].folders, folderFilterId)?.columnSettings || [];

export const getQuickActions = (state: TFoldersState) => (
  section: EFoldersSection,
  folderFilterId: string | null = state[section].currentFolderId,
) => getCurrentFolder(state[section].folders, folderFilterId)?.quickActions || [];

export const getSort = (state: TFoldersState) => (
  section: EFoldersSection,
  folderFilterId: string | null = state[section].currentFolderId,
) => getCurrentFolder(state[section].folders, folderFilterId)?.sort || {
  sort: null,
  sortBy: null,
};
