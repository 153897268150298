<template>
  <div class="billing-plan-selection">
    <RadioGroup
      :optionType="ERadioButtonOptionType.button"
      :value="billingPlanGroupType"
      @update:value="billingPlanGroupType = $event"
    >
      <RadioButton
        :value="EBillingPlanGroup.carrier"
      >
        {{ tt('shared.carrier') }}
      </RadioButton>
      <RadioButton
        :value="EBillingPlanGroup.freightForwarder"
      >
        {{ tt('shared.forwarder') }}
      </RadioButton>
      <RadioButton
        :value="EBillingPlanGroup.cargoOwner"
      >
        {{ tt('shared.cargoOwner') }}
      </RadioButton>
      <RadioButton
        :value="EBillingPlanGroup.shipper"
      >
        {{ tt('billing.shipper') }}
      </RadioButton>
    </RadioGroup>
    <div class="billing-plan-selection__cards">
      <component
        :is="planSelectionCards"
        @change="$emit('change', $event)"
      />
    </div>
    <SmartLink
      v-if="textForMoreAboutPlan"
      :href="MORE_INFO_ABOUT_PLANS_URL"
      alwaysOpenNewTab
    >
      {{ textForMoreAboutPlan }}
    </SmartLink>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { RadioButton, RadioGroup } from '@/ui';
import tt from '@/i18n/utils/translateText';
import { ERadioButtonOptionType } from '@/ui/types';
import SmartLink from '@/components/SmartLink/index.vue';
import { MORE_INFO_ABOUT_PLANS_URL } from '@/domains/constants';
import { EBillingPlanGroup } from '@/contexts/billingContext/domain/constants';

import BillingCardsForCarrier from './components/BillingCardsForCarrier/index.vue';
import BillingCardsForFreightForwarder from './components/BillingCardsForFreightForwarder/index.vue';
import BillingCardsForShipper from './components/BillingCardsForShipper/index.vue';
import BillingCardsForCargoOwner from './components/BillingCardsForCargoOwner/index.vue';
import { useView } from './application/useView';

export default defineComponent({
  name: 'BillingPlanSelection',
  components: {
    RadioGroup,
    RadioButton,
    SmartLink,
    BillingCardsForCarrier,
    BillingCardsForFreightForwarder,
    BillingCardsForShipper,
    BillingCardsForCargoOwner,
  },
  emits: ['change'],
  setup() {
    const { textForMoreAboutPlan, planSelectionCards, billingPlanGroupType } = useView();

    return {
      ERadioButtonOptionType,
      planSelectionCards,
      MORE_INFO_ABOUT_PLANS_URL,
      EBillingPlanGroup,
      textForMoreAboutPlan,
      billingPlanGroupType,

      tt,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
