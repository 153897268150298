<template>
  <div class="tms-sider-content">
    <div
      class="tms-sider-content__menu-content"
      :class="{
        'tms-sider-content__menu-content-high': !hasLinkedAccountToSber,
        'tms-sider-content__menu-content-high_message': getIsPaywallMessageShown()
      }"
    >
      <SiderTopContent :isCollapsed="isCollapsed" @collapse="$emit('collapse')" />
      <AdminContent v-if="isAdminRoute" :selectedKeys="state.selectedKeys" :isCollapsed="isCollapsed" />

      <template v-if="isSidebarVisible && !isAdminRoute">
        <div class="tms-sider-content__menu tms-sider-content__menu_static">
          <!-- Столкнулись с неприятным кейсом после обновления ant.
               Дочерние компоненты внутри <Menu /> вызываются два раза, следовательно все подписки будут дублироваться.
               Этот костыль вставляет <NotificationMenuItem /> в Sider и исправляет дублирование открытия Popover -->
          <component
            :is="item.component"
            v-for="item in staticSiderMenuItems"
            :key="item.key"
            :class="{ 'ant-menu-inline-collapsed': isCollapsed }"
            :isCollapsed="isCollapsed"
            :icon="item.icon"
            :label="tt(`sider.menu.${item.label}`)"
          />

          <SupportMenu :isCollapsed="isCollapsed" />
        </div>

        <Menu
          :selectedKeys="state.selectedKeys"
          :openKeys="state.openKeys"
          class="tms-sider-content__menu"
          theme="light"
          mode="inline"
        >
          <template v-for="item in siderMenuItems" :key="item.key">
            <SubMenu
              v-if="showMenuItemByType([ECommonSchemaItemType.submenu], item.type)"
              :key="item.key"
              :disabled="getShipperRunsNotAvailable(item.key) || isSiderItemsDisabled"
              @titleClick="handleTitleClick(item.key)"
            >
              <template #title>
                <Tooltip
                  overlayClassName="tms-sider-content__menu-tooltip"
                  :placement="EPopoverPlacement.right"
                  :visible="getShipperRunsNotAvailable(item.key)"
                  :title="getShipperRunsNotAvailable(item.key) ? tt('sider.verificationWarning') : ''"
                >
                  <MenuIconWithLabel
                    :icon="item.icon"
                    :label="tt(`sider.menu.${item.label as ECommonSchemaItemLabels}`)"
                    :isCollapsed="isCollapsed"
                  />
                </Tooltip>
              </template>
              <template v-if="item.key === ECommonSchemaItemKeys.orders">
                <MenuItem
                  v-for="folder in orderFolders"
                  :key="folder.id"
                  :disabled="isSiderItemsDisabled"
                  @click="handleSubmenuItemClick(item.key, folder.id)"
                >
                  {{ folder.name }}
                </MenuItem>
              </template>
              <template v-if="item.key === ECommonSchemaItemKeys.matrices">
                <MenuItem
                  v-for="folder in matricesFolders"
                  :key="folder.id"
                  :disabled="isSiderItemsDisabled"
                  @click="handleSubmenuItemClick(item.key, folder.id)"
                >
                  {{ folder.name }}
                </MenuItem>
              </template>
              <template v-if="item.key === ECommonSchemaItemKeys.references">
                <MenuItem
                  v-for="folder in customReferencesFolders"
                  :key="folder.id"
                  :disabled="isSiderItemsDisabled"
                  @click="handleSubmenuItemClick(item.key, folder.id)"
                >
                  {{ folder.name }}
                </MenuItem>
              </template>
              <template v-if="item.key === ECommonSchemaItemKeys.shipperRuns">
                <MenuItem
                  v-for="folder in shipperRunsSchema"
                  :key="folder.key"
                  :disabled="!isCurrentTenantVerified"
                  @click="onMenuItemClick(folder.path)"
                >
                  <Tooltip
                    overlayClassName="tms-sider-content__menu-tooltip"
                    :placement="EPopoverPlacement.right"
                    :visible="!isCurrentTenantVerified"
                    :title="!isCurrentTenantVerified ? tt('sider.verificationWarning') : ''"
                  >
                    <MenuIconWithLabel
                      :label="tt(`sider.menu.contractorPersonalAccountRuns.${folder.label as EShipperRunsLabels}`)"
                      :isCollapsed="isCollapsed"
                      :count="getShipperRunsCount(folder.key)"
                      :disabled="!isCurrentTenantVerified"
                    />
                  </Tooltip>
                </MenuItem>
              </template>
            </SubMenu>

            <template
              v-if="showMenuItemByType([ECommonSchemaItemType.default, ECommonSchemaItemType.withDivider], item.type)"
            >
              <MenuItem
                v-if="PAGES_AVAILABLE_AFTER_VERIFICATION.includes(item.key)"
                :key="item.key"
                class="tms-sider-content__menu-item"
                :disabled="!isCurrentTenantVerified"
                :title="!isCurrentTenantVerified ? '' : tt(`sider.menu.${item.label as ECommonSchemaItemLabels}`)"
                @click="onMenuItemClick(item.path)"
              >
                <Tooltip
                  overlayClassName="tms-sider-content__menu-tooltip"
                  :placement="EPopoverPlacement.right"
                  :visible="!isCurrentTenantVerified"
                  :title="!isCurrentTenantVerified ? tt('sider.verificationWarning') : ''"
                >
                  <MenuIconWithLabel
                    :icon="item.icon"
                    :label="tt(`sider.menu.${item.label as ECommonSchemaItemLabels}`)"
                    :isCollapsed="isCollapsed"
                  />
                </Tooltip>
              </MenuItem>
              <MenuItem
                v-else
                :key="item.key"
                :disabled="isSiderItemsDisabled && item.key !== 'employees'"
                class="tms-sider-content__menu-item"
                :class="{ 'tms-sider-content__menu-item_bordered': item.type === ECommonSchemaItemType.withDivider }"
                @click="onMenuItemClick(item.path)"
              >
                <MenuIconWithLabel
                  :icon="item.icon"
                  :label="tt(`sider.menu.${item.label as ECommonSchemaItemLabels}`)"
                  :isCollapsed="isCollapsed"
                />
              </MenuItem>
            </template>
          </template>
        </Menu>
      </template>
    </div>
    <div
      v-if="hasLinkedAccountToSber"
      class="tms-sider-content__sber"
    >
      <SberBusinessIntegrationButton
        class="analytics--sber-business-id-button-sider"
        :integrationEvent="EIntegrationEvent.linking"
        :isCollapsed="isCollapsed"
        @completeLinking="handleCompleteSocialLinking"
      />
    </div>

    <TenantMenu :isCollapsed="isCollapsed" :isAdmin="isAdmin" />
  </div>
</template>

<script lang="ts">
import {
  Button,
  Menu,
  Popover,
  MenuItem,
  SubMenu,
} from 'ant-design-vue';
import {
  defineComponent,
  onBeforeMount,
  onMounted,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { Tooltip } from '@/ui';
import { EPopoverPlacement } from '@/ui/types';
import SmartLink from '@/components/SmartLink/index.vue';
import tt from '@/i18n/utils/translateText';
import SberBusinessIntegrationButton from '@/components/SberBusinessIntegrationButton/index.vue';
import { EIntegrationEvent } from '@/components/SberBusinessIntegrationButton/domain/constants';
import { ECounterType } from '@/components/layouts/domain/constants';
import useStore from '@/store/useStore';
import { useView as useViewSiderContent } from '@/components/layouts/AppLayoutWrapper/components/AppLayoutSider/components/TmsSiderContent/application/useView';
import { getIsPaywallMessageShown } from '@/domains/billing/getIsPaywallMessageShown';
import { useCustomizationStore } from '@/stores/customization';

import SiderTopContent from '../SiderTopContent/index.vue';
import AdminContent from './components/AdminContent/index.vue';
import TenantMenu from './components/TenantMenu/index.vue';
import NotificationMenuItem from './components/NotificationMenuItem/index.vue';
import SupportMenu from './components/SupportMenu/index.vue';
import MenuIconWithLabel from '../MenuIconWithLabel/index.vue';
import { useView } from '../../application/useView';
import { useInteractWithSider } from '../../application/useInteractWithSider';
import { useSiderMenuItems } from '../../application/useSiderMenuItems';
import {
  ECommonSchemaItemKeys,
  ECommonSchemaItemLabels,
  ECommonSchemaItemType,
  EShipperRunsLabels,
} from '../../domains/constants';

export default defineComponent({
  name: 'TmsSiderContent',
  components: {
    SiderTopContent,
    Menu,
    MenuItem,
    SubMenu,
    Button,
    TenantMenu,
    NotificationMenuItem,
    Popover,
    SmartLink,
    AdminContent,
    MenuIconWithLabel,
    SupportMenu,
    SberBusinessIntegrationButton,
    Tooltip,
  },
  props: {
    isCollapsed: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['collapse'],
  setup(props) {
    const store = useStore();
    const customizationStore = useCustomizationStore();
    const router = useRouter();
    const route = useRoute();

    const {
      isAdmin,
      isAdminRoute,
      isSidebarVisible,
      isCurrentTenantVerified,
      state,
      folderOpenKeys,
      orderFolders,
      folderId,
      matricesFolders,
      customReferencesFolders,
      isSiderItemsDisabled,
      getShipperRunsCount,
      getShipperRunsNotAvailable,
    } = useView(
      {
        store,
        route,
      });

    const {
      shipperRunsSchema,
      PAGES_AVAILABLE_AFTER_VERIFICATION,
    } = useViewSiderContent();

    const { siderMenuItems, staticSiderMenuItems } = useSiderMenuItems();

    const {
      hasLinkedAccountToSber,
      setSelectedKeys,
      handleTitleClick,
      handleSubmenuItemClick,
      handleCompleteSocialLinking,
      fetchFolders,
      fetchRunsCountInfo,
      showMenuItemByType,
      onMenuItemClick,
    } = useInteractWithSider({
      state,
      folderId,
      folderOpenKeys,
      store,
      router,
      route,
      isCurrentTenantVerified,
      customizationStore,
    });

    onBeforeMount(() => {
      fetchFolders();
      fetchRunsCountInfo();

      if (!props.isCollapsed) {
        if (!isCurrentTenantVerified.value) {
          handleTitleClick('shipper_runs');
        }
        state.openKeys = folderOpenKeys.value;
      }
    });

    onMounted(() => {
      setSelectedKeys(route.path);
    });

    return {
      isAdmin,
      isAdminRoute,
      isSidebarVisible,
      isCurrentTenantVerified,
      hasLinkedAccountToSber,
      staticSiderMenuItems,
      shipperRunsSchema,
      state,
      EIntegrationEvent,
      ECounterType,
      isSiderItemsDisabled,
      folderOpenKeys,
      orderFolders,
      ECommonSchemaItemType,
      siderMenuItems,
      matricesFolders,
      customReferencesFolders,
      PAGES_AVAILABLE_AFTER_VERIFICATION,
      ECommonSchemaItemLabels,
      EShipperRunsLabels,
      ECommonSchemaItemKeys,
      EPopoverPlacement,

      tt,
      handleSubmenuItemClick,
      onMenuItemClick,
      handleTitleClick,
      handleCompleteSocialLinking,
      showMenuItemByType,
      getShipperRunsCount,
      getShipperRunsNotAvailable,
      getIsPaywallMessageShown,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
