import tt from '@/i18n/utils/translateText';
import { EAccessLevels } from '@/contexts/executorsLookupContext/domain/constants';
import { EStatusType } from '@/domains/constants';

export const ROUTE_NAME = {
  tenders: 'tenders',
  tender: 'tender',
  surveyStage: 'survey_stage',
  informationRequestStage: 'information_request_stage',
  submissionStage: 'submission_stage',
};

export enum EStageTargetTypes {
  survey = 'survey',
  submission = 'submission',
  infoRequest = 'info_request',
}

export enum EInformationRequestStageListType {
  directions = 'directions',
  participants = 'participants',
}

export enum ETenderPeriodType {
  day = 'day',
  week = 'week',
  month = 'month',
}

export enum EStageType {
  survey = 'survey',
  infoRequest = 'info_request',
  submission = 'submission',
}

export enum ESurveyStageKinds {
  withAnswerOptions = 'with_answer_options',
  freeInput = 'free_input',
}

export enum EStageRelation {
  bid = 'bid',
  lot = 'lot',
  offer = 'offer',
}

export enum ECustomRenderLotSlugs {
  bestScore = 'best_score',
  lightsIndicatorColor = 'lights_indicator_color',
  vatRate = 'vat_rate',
}

export const ACCESS_LEVEL_TRANSLATE = {
  [EAccessLevels.allPartners]: tt('tenders.view.stages.accessSettings.allPartners'),
  [EAccessLevels.selectedPartners]: tt('tenders.view.stages.accessSettings.selectedPartners'),
  [EAccessLevels.allExecutors]: tt('tenders.view.stages.accessSettings.allExecutors'),
};

export const STAGE_ROUTE_BY_TYPE = {
  [EStageTargetTypes.survey]: EStageType.survey,
  [EStageTargetTypes.submission]: EStageType.submission,
  [EStageTargetTypes.infoRequest]: EStageType.infoRequest,
};

export const STAGE_NAME_BY_TYPE = {
  [EStageType.survey]: tt('tenders.shared.stageTargetType.survey'),
  [EStageType.infoRequest]: tt('tenders.shared.stageTargetType.infoRequest'),
  [EStageType.submission]: tt('tenders.shared.stageTargetType.submission'),
};
export const STAGE_NAME_BY_STAGE_TYPE = {
  [EStageTargetTypes.survey]: tt('tenders.shared.stageTargetType.survey'),
  [EStageTargetTypes.infoRequest]: tt('tenders.shared.stageTargetType.infoRequest'),
  [EStageTargetTypes.submission]: tt('tenders.shared.stageTargetType.submission'),
};

export enum ETenderStatus {
  draft = 'draft',
  active = 'active',
  archived = 'archived',
}

export enum ETenderState {
  hasNotActiveStages = 'has_not_active_stages',
  hasActiveStages = 'has_active_stages',
  archived = 'archived',
}

export enum EStageStatus {
  draft = 'draft',
  readyToStart = 'ready_to_start',
  started = 'started',
  finished = 'finished',
  canceled = 'canceled',
}

export enum ETenderCustomerFilter {
  all = 'all',
  hasNotActiveStages = 'has_not_active_stages',
  hasActiveStage = 'has_active_stages',
  archived = 'archived',
}

export const TENDER_STATUS_MODEL = {
  [ETenderStatus.draft]: {
    text: tt(`shared.${ETenderStatus.draft}`),
    type: EStatusType.default,
  },
  [ETenderStatus.active]: {
    text: tt(`tenders.shared.statuses.${ETenderStatus.active}`),
    type: EStatusType.processing,
  },
  [ETenderStatus.archived]: {
    text: tt(`tenders.shared.statuses.${ETenderStatus.archived}`),
    type: EStatusType.error,
  },
};

export const STAGE_STATUS_MODEL = {
  [EStageStatus.draft]: {
    text: tt('shared.draft'),
    type: EStatusType.default,
  },
  [EStageStatus.readyToStart]: {
    text: tt('tenders.view.stages.statuses.readyToStart'),
    type: EStatusType.processing,
  },
  [EStageStatus.started]: {
    text: tt('tenders.view.stages.statuses.started'),
    type: EStatusType.processing,
  },
  [EStageStatus.finished]: {
    text: tt('tenders.view.stages.statuses.finished'),
    type: EStatusType.success,
  },
  [EStageStatus.canceled]: {
    text: tt('tenders.view.stages.statuses.canceled'),
    type: EStatusType.error,
  },
};

export const TENDER_PERIOD_TYPE_TRANSLATION = {
  [ETenderPeriodType.day]: tt('shared.day'),
  [ETenderPeriodType.week]: tt('shared.weekDeclension'),
  [ETenderPeriodType.month]: tt('shared.month'),
};

export enum ETenderStageStatus {
  draft = 'draft',
  readyToStart = 'ready_to_start',
  started = 'started',
  finished = 'finished',
  canceled = 'canceled',
}

export enum ETenderStageExecutorFilter {
  started = 'started',
  finished = 'finished',
}

export const STAGE_TARGET_TYPES_TRANSLATIONS = {
  [EStageTargetTypes.survey]: {
    normal: tt('tenders.shared.stageTargetType.survey'),
    declination: tt('tenders.shared.stageTargetType.surveyDeclination'),
  },
  [EStageTargetTypes.submission]: {
    normal: tt('tenders.shared.stageTargetType.submission'),
    declination: tt('tenders.shared.stageTargetType.submissionDeclination'),
  },
  [EStageTargetTypes.infoRequest]: {
    normal: tt('tenders.shared.stageTargetType.infoRequest'),
    declination: tt('tenders.shared.stageTargetType.infoRequestDeclination'),
  },
};

export const TENDER_PERIOD_TYPES = [
  {
    value: ETenderPeriodType.day,
    label: tt('shared.day'),
  },
  {
    value: ETenderPeriodType.week,
    label: tt('shared.week'),
  },
  {
    value: ETenderPeriodType.month,
    label: tt('shared.month'),
  },
];

// светофор начинается со значения в 100% по нижней границе зеленого цвета
export const MIN_VALUE_GREEN_LIGHT = 100;

export enum ELightIndicator {
  green = 'green',
  orange = 'orange',
  red = 'red',
  withoutСolor = 'without_color',
}

export enum ETendersContextResponseCode {
  tenderOfferHasInvalidValues = 'DomainErrors::TendersContext::TenderOfferHasInvalidValues',
}

export enum ETenderOfferStatus {
  draft = 'draft',
  submitted = 'submitted',
}

export enum ETenderAttributeType {
  string = 'string',
  dictionary = 'dictionary',
  boolean = 'boolean',
  integer = 'integer',
  float = 'float',
  date = 'date',
}

export enum EValidationConstraint {
  past = 'past',
  future = 'future',
}

export enum EAttributeValidation {
  filled = 'filled',
  minSize = 'minSize',
  maxSize = 'maxSize',
  includedIn = 'includedIn',
  gt = 'gt',
  lt = 'lt',
  constraint = 'constraint',
}
