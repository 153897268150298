<template>
  <div
    class="billing-paywall-message"
  >
    <template
      v-if="subscriptionWithPendingStatus || subscriptionRequest"
    >
      <template v-if="isCargoOwnerSubscription">
        <p
          v-if="subscriptionWithPendingStatus"
          class="billing-paywall-message__text"
        >
          {{ tt('billing.paywallMessage.yourPlanForCargoOwnerWillStartFrom',
                {
                  param: {
                    date: expectedSubscriptionDate,
                  }
                }) }}
        </p>
        <p v-else>
          {{ tt('billing.paywallMessage.connectionRequestHasBeenSuccessfullyCompleted') }}
        </p>
      </template>
      <p
        v-else
        class="billing-paywall-message__text"
      >
        {{ capitalize(tt('shared.selected.masculine')) }}
        <span>{{ shortPlan && tt(`billing.planCode.${shortPlan}`) }}</span>
        {{ tt('billing.plan') }}.
        {{ tt('billing.paywallMessage.willStartWorking') }}
        {{ tt('shared.since') }}
        <span>{{ expectedSubscriptionDate }}</span>
      </p>
    </template>
    <template
      v-else-if="сanCreateSubscriptions"
    >
      <p
        v-if="!isBillingValidFrom1MarchAvailable"
        class="billing-paywall-message__text"
      >
        {{ tt('billing.paywallMessage.freePeriodIsEnding') }}
        {{ capitalize(tt('shared.since')) }}
        <span>{{ expectedSubscriptionDate }}</span>
        {{ tt('billing.paywallMessage.ourServiceToolsAreBecomingPaid') }}
        {{ tt('billing.paywallMessage.youCanChooseAPlanInAdvance') }}
      </p>
      <p
        v-else
        class="billing-paywall-message__text"
      >
        {{ tt('billing.paywallMessage.connectToOneOfThePlansToGetAccessToTheTools') }}
      </p>
    </template>
    <p
      v-else
      class="billing-paywall-message__text"
    >
      {{ tt('billing.paywallMessage.theToolsOfOurServiceArePaid') }}.
      {{ tt('billing.paywallMessage.theOrganizationAdministratorCanChooseAPlan') }}
    </p>
    <Button
      v-if="showPlanSelectionButton"
      type="primary"
      class="billing-paywall-message__button"
      @click="openModal"
    >
      {{ tt('billing.choosePlan.short') }}
    </Button>
  </div>
  <BillingPlanSelectionModal
    :visible="isOpenModal"
    @update:visible="isOpenModal = $event"
  />
</template>

<script lang="ts">

import {
  defineComponent,
  ref,
} from 'vue';
import { Button } from 'ant-design-vue';

import tt from '@/i18n/utils/translateText';
import { capitalize } from '@/utils';
import BillingPlanSelectionModal from '@/components/billing/BillingPlanSelectionModal/index.vue';
import { formatDate } from '@/utils/dateUtils';
import { DATE_FORMAT } from '@/constants';

import { useView } from './application/useView';

export default defineComponent({
  name: 'BillingPaywallMessage',
  components: {
    Button,
    BillingPlanSelectionModal,
  },
  setup() {
    const isOpenModal = ref<boolean>(false);

    const {
      showPlanSelectionButton,
      сanCreateSubscriptions,
      subscriptionRequest,
      shortPlan,
      isBillingValidFrom1MarchAvailable,
      isCargoOwnerSubscription,
      expectedSubscriptionDate,
      subscriptionWithPendingStatus,
    } = useView();

    const openModal = () => {
      isOpenModal.value = true;
    };

    return {
      showPlanSelectionButton,
      сanCreateSubscriptions,
      isOpenModal,
      subscriptionRequest,
      shortPlan,
      isBillingValidFrom1MarchAvailable,
      isCargoOwnerSubscription,
      expectedSubscriptionDate,
      subscriptionWithPendingStatus,
      DATE_FORMAT,

      tt,
      capitalize,
      openModal,
      formatDate,
    };
  },
});
</script>
<style lang="scss" src="./styles.scss" />
