<template>
  <div
    ref="wrapperRef"
    class="control-wrapper"
    :class="className"
    @click="$emit('click', $event)"
    @blur="$emit('blur', $event)"
  >
    <span v-if="addonBefore" class="control-wrapper__addon control-wrapper__addon_before">
      {{ addonBefore }}
    </span>
    <div v-if="hasPrefixSlot" class="control-wrapper__slot">
      <slot tabIndex="0" name="prefix" />
    </div>

    <slot tabIndex="0" :onFocus="focus" :onBlur="blur" />

    <div v-if="hasSuffixSlot" class="control-wrapper__slot">
      <slot tabIndex="0" name="suffix" />
    </div>
    <span v-if="addonAfter" class="control-wrapper__addon control-wrapper__addon_after">
      {{ addonAfter }}
    </span>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  PropType,
  ref,
} from 'vue';

import { useHasSlot } from '@/composables/useHasSlot';
import { ESize } from '@/ui/types';

export default defineComponent({
  name: 'ControlWrapper',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<ESize>,
      default: ESize.normal,
    },
    addonAfter: {
      type: [String, null] as PropType<string | null>,
      default: null,
    },
    addonBefore: {
      type: [String, null] as PropType<string | null>,
      default: null,
    },
    hasBorder: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['click', 'blur'],
  setup(props, { slots, expose }) {
    const wrapperRef = ref<HTMLDivElement>();
    const hasPrefixSlot = useHasSlot(slots, 'prefix');
    const hasDefaultSlot = useHasSlot(slots, 'default');
    const hasSuffixSlot = useHasSlot(slots, 'suffix');
    const isFocused = ref(false);

    const blur = () => {
      if (hasDefaultSlot) {
        isFocused.value = false;
      }
    };

    const focus = () => {
      if (hasDefaultSlot) {
        isFocused.value = true;
      }
    };

    const className = computed(() => {
      let resultClassName = props.disabled ? 'control-wrapper_disabled' : '';

      if (props.isInvalid) {
        resultClassName += ' control-wrapper_invalid';
      }

      if (isFocused.value) {
        resultClassName += ' control-wrapper_focused';
      }

      if ([ESize.normal, ESize.large].includes(props.size)) {
        resultClassName += ` control-wrapper_${props.size}`;
      }

      if (!props.hasBorder) {
        resultClassName += ' control-wrapper_without-border';
      }

      return resultClassName;
    });

    expose({
      focus,
      blur,
      wrapper: wrapperRef,
    });

    return {
      wrapperRef,
      className,
      hasPrefixSlot,
      hasSuffixSlot,

      focus,
      blur,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss"></style>

<!-- Для того чтобы стилизовать компонент переданный в <slot />,
  Vue предоставляет специальный псевдо-класс ":slotted()".
  Однако, он не будет работать вне файла .vue, поэтому эти стили прописаны здесь
  https://vuejs.org/api/sfc-css-features.html#scoped-css -->

<style lang="scss" scoped>
  :slotted(.slotted) {
    min-height: 100%;
  }

  .control-wrapper_disabled :slotted(.slotted) {
    border-color: $neutral50;
    color: $neutral60;
    cursor: not-allowed!important;
  }
</style>
