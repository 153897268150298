<template>
  <div class="radio">
    <label class="radio__label" :class="className">
      <input
        :id="name"
        type="radio"
        class="radio__input"
        :value="value"
        :name="name"
        :checked="isChecked"
        :disabled="isDisabled"
        @change="onChange"
      />
      <span class="radio__inner" />
      <span v-if="hasSlot" class="radio__title">
        <!-- @slot Слот для вывода контента -->
        <slot />
      </span>
    </label>
    <div v-if="hasSlot && hasHint" class="radio__hint" :class="{ 'radio__hint_disabled': isDisabled }">
      {{ hint }}
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  inject,
} from 'vue';

import { useHasSlot } from '@/composables/useHasSlot';
import { TRadioGroupContext, RadioGroupContextKey } from '@/ui/types';

export default defineComponent({
  name: 'Radio',
  props: {
    value: {
      type: String,
      required: true,
    },
    /** Подсказка под основным контентом. Показывается только при наличии контента в слоте */
    hint: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'change',
    /** Поддержка двустороннего связывания (v-model) свойства 'checked' */
    'update:checked',
  ],
  setup(props, { slots, emit }) {
    /** Контекст radioGroupContext предоставляется из компонента RadioGroup.
     * Внутри provide передается объект вида
     *    {
     *       onRadioChange,
     *       stateValue,
     *       props,
     *    };
     * Нам необходимо использовать этот контекст до тех пор пока не появится собственный компонент RadioGroup для того,
     * чтобы RadioGroup продолжала ловить onChange из Radio.
     */
    const radioGroupContext = inject<TRadioGroupContext>(RadioGroupContextKey);

    const hasSlot = useHasSlot(slots, 'default');
    const hasHint = computed(() => !!props.hint);
    const isDisabled = computed(() => (props.disabled || radioGroupContext?.props.disabled));
    const className = computed(() => ({ radio__label_disabled: isDisabled.value }));
    const isChecked = computed(() => (props.checked || props.value === radioGroupContext?.stateValue.value));
    const name = computed(() => radioGroupContext?.props.name);

    const onChange = (event: Event) => {
      const targetChecked = (event.target as HTMLInputElement).checked;

      emit('update:checked', targetChecked);
      emit('change', event);
      if (radioGroupContext && radioGroupContext.onRadioChange) {
        radioGroupContext.onRadioChange(event);
      }
    };

    return {
      hasSlot,
      hasHint,
      isChecked,
      isDisabled,
      className,
      name,

      onChange,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
