export enum EBillingPlanCode {
  shipper = 'tms.plan.shipper',
  carrierBasic = 'tms.plan.carrier.basic',
  carrierExtended = 'tms.plan.carrier.extended',
  freightForwarderBasic = 'tms.plan.freight_forwarder.basic',
  freightForwarderExtended = 'tms.plan.freight_forwarder.extended',
  cargoOwner = 'tms.plan.cargo_owner',
}

export enum EBillingShortPlanCode {
  basic = 'basic',
  extended = 'extended',
}

export enum EBillingPlanGroup {
  shipper = 'shipper',
  carrier = 'carrier',
  freightForwarder = 'freight_forwarder',
  cargoOwner = 'cargoOwner',
}

export enum EBillingPlanCodeStatus {
  active = 'active',
  pending = 'pending',
}

export enum EBillingTransactionCode {
  countOfCompletedExecutionRequests = 'tms.count_of_completed_execution_requests',
  countOfCompletedBookings = 'tms.count_of_completed_bookings',
}
