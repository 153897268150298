import { computed, watch } from 'vue';

import store from '@/store';
import {
  disconnectConsumer,
  openWsConnection,
  reconnectWithNewUrl,
} from '@/websocket/controller';

const useWebsocketController = () => {
  const currentTenant = computed(() => store.getters['tenants/currentTenant']);

  watch(() => currentTenant.value?.id, () => {
    if (currentTenant.value?.id) {
      reconnectWithNewUrl();
    }
  });

  return {
    disconnectConsumer,
    openWsConnectionForCurrentTenant: openWsConnection,
  };
};

export default useWebsocketController;
