import { watch } from 'vue';
import { v4 as uuidv4 } from 'uuid';

import ToastNotification from '@/contexts/notificationsContext/components/ToastNotification/index.vue';
import { ENotificationType } from '@/contexts/notificationsContext/domain/constants';
import useStore from '@/store/useStore';
import useWebsocketChannel from '@/composables/useWebsocketChannel';
import {
  notification,
  isString,
  setDefaultFavicon,
  setNotificationFavicon,
} from '@/utils';
import toggleAppTitleWithNotifications from '@/contexts/notificationsContext/utils/toggleAppTitleWithNotifications';
import getNotificationScheme from '@/contexts/notificationsContext/domain/getNotificationScheme';

// TODO: переместить тип выше и описать тип приходящий сообщений для всех каналов
type TWebsocketMessagePayload = {
  payload: Record<string, string> | null,
  type: ENotificationType,
  id: string,
};

const useNotificationsUtils = () => {
  const store = useStore();
  const { start: startToggle, stop: stopToggle } = toggleAppTitleWithNotifications();

  const showNotification = (message: string, notificationId: string) => {
    const toastKey = uuidv4();
    notification.info({
      message: (
        <ToastNotification
          message={message}
          notificationId={notificationId}
          toastKey={toastKey}
        />
      ),
      key: toastKey,
    });
  };

  const activateAppStateWithUnreadNotifications = () => {
    store.commit('notifications/SET_APP_STATE_WITH_UNREAD_NOTIFICATIONS', true);
  };

  const deactivateAppStateWithUnreadNotifications = () => {
    store.commit('notifications/SET_APP_STATE_WITH_UNREAD_NOTIFICATIONS', false);
  };

  const handleNewNotification = (message: TWebsocketMessagePayload) => {
    if (!message) return;
    store.commit('notifications/INCREMENT_UNREAD_COUNT');
    const schemeItem = getNotificationScheme(message.payload)[message.type];
    if (!schemeItem) return;
    const messageTemplate = schemeItem.template;
    const notificationMessage = isString(messageTemplate) ? messageTemplate : messageTemplate(message.payload);

    showNotification(notificationMessage, message.id);
    if (!store.state.app.isTabActive) {
      activateAppStateWithUnreadNotifications();
    }
  };

  const { connect, closeConnection } = useWebsocketChannel({
    channelName: 'NotificationsChannel',
    onMessage: handleNewNotification,
  });

  const connectToNotificationChannel = () => {
    connect();
  };

  const disconnectFromNotificationChannel = () => {
    closeConnection();
  };

  const reconnectNotificationChannel = () => {
    disconnectFromNotificationChannel();
    connectToNotificationChannel();
  };

  watch(() => store.state.tenants.currentTenant?.id, () => {
    if (!store.state.tenants.currentTenant?.id) return;
    reconnectNotificationChannel();
  });

  watch(() => store.state.notifications.isTabWithUnreadNotifications, (isTabWithUnreadNotifications) => {
    if (isTabWithUnreadNotifications) {
      startToggle();
      setNotificationFavicon();
    } else {
      stopToggle();
      setDefaultFavicon();
    }
  });

  watch(() => store.state.app.isTabActive, (isTabActive) => {
    if (isTabActive) {
      deactivateAppStateWithUnreadNotifications();
    }
  });
};

export default useNotificationsUtils;
