export enum EPartnershipsInvitationType {
  incoming = 'incoming',
  outgoing = 'outgoing',
}

export enum EPartnershipsListTabsKey {
  partnershipsPartners = 'partnershipsPartners',
  partnershipsInvitations = 'partnershipsInvitations',
  partnershipsContracts = 'partnershipsContracts',
}

export type TPartnershipsListStatistics = {
  partnershipsContracts: string,
  partnershipsInvitations: string,
  partnershipsInvitationsByGroup: {
    [EPartnershipsInvitationType.incoming]: string,
    [EPartnershipsInvitationType.outgoing]: string,
  },
  partnershipsPartners: string,
};
