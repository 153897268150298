import { EDayOfWeek } from '@/constants';
import { ELookupMethod } from '@/contexts/orderingContext/domain/constants';
import { EStatusType } from '@/domains/constants';
import tt from '@/i18n/utils/translateText';

export enum EAccessLevels {
  allPartners = 'all_partners',
  selectedPartners = 'selected_partners',
  allTenants = 'all_tenants',
  allExecutors = 'all_executors',
  accountPartners = 'account_partners',
}

export enum EAccessRuleActions {
  allow = 'allow',
  deny = 'deny',
}

export enum EAccessSettingsKeys {
  accessGrants = 'accessGrants',
  accessDenies = 'accessDenies',
  accessLevel = 'accessLevel',
  rejectQuotaDistributionExecutors = 'rejectQuotaDistributionExecutors',
}

export enum EAccessRuleTypes {
  partner = 'partner',
  group = 'group',
}

export const SETTINGS_ALERT_MESSAGE = {
  published: tt('executorsLookup.shared.alreadyPublished'),
  completed: tt('executorsLookup.shared.auctionFinished'),
};

export const MIN_PARTICIPANTS_COUNT = 2;

export enum ELookupStrategyAction {
  suspend = 'suspend',
  activate = 'activate',
  archive = 'archive',
}

export const LOOKUP_STRATEGIES_STATUS = {
  not_started_yet: 'not_started_yet',
  started: 'started',
  finished: 'finished',
  suspended: 'suspended',
  archived: 'archived',
};

export const LOOKUP_STRATEGIES_STATUS_MODEL = {
  [LOOKUP_STRATEGIES_STATUS.not_started_yet]: {
    text: tt('executorsLookup.shared.lookupStrategyStatuses.notStartedYet'),
    type: EStatusType.processing,
  },
  [LOOKUP_STRATEGIES_STATUS.started]: {
    text: tt('executorsLookup.shared.lookupStrategyStatuses.started'),
    type: EStatusType.success,
  },
  [LOOKUP_STRATEGIES_STATUS.finished]: {
    text: tt('executorsLookup.shared.lookupStrategyStatuses.finished'),
    type: EStatusType.error,
  },
  [LOOKUP_STRATEGIES_STATUS.suspended]: {
    text: tt('executorsLookup.shared.lookupStrategyStatuses.suspended'),
    type: EStatusType.default,
  },
  [LOOKUP_STRATEGIES_STATUS.archived]: {
    text: tt('executorsLookup.shared.lookupStrategyStatuses.archived'),
    type: EStatusType.default,
  },
};

export const initialDaySetup = {
  [EDayOfWeek.monday]: {
    active: true,
    from: '09:00',
    to: '18:00',
    label: tt('shared.dayOfWeekShort.monday'),
  },
  [EDayOfWeek.tuesday]: {
    active: true,
    from: '09:00',
    to: '18:00',
    label: tt('shared.dayOfWeekShort.tuesday'),
  },
  [EDayOfWeek.wednesday]: {
    active: true,
    from: '09:00',
    to: '18:00',
    label: tt('shared.dayOfWeekShort.wednesday'),
  },
  [EDayOfWeek.thursday]: {
    active: true,
    from: '09:00',
    to: '18:00',
    label: tt('shared.dayOfWeekShort.thursday'),
  },
  [EDayOfWeek.friday]: {
    active: true,
    from: '09:00',
    to: '18:00',
    label: tt('shared.dayOfWeekShort.friday'),
  },
  [EDayOfWeek.saturday]: {
    active: true,
    from: '09:00',
    to: '18:00',
    label: tt('shared.dayOfWeekShort.saturday'),
  },
  [EDayOfWeek.sunday]: {
    active: true,
    from: '09:00',
    to: '18:00',
    label: tt('shared.dayOfWeekShort.sunday'),
  },
};

export enum ELookupPriceSource {
  lookupMethodSettings = 'lookup_method_settings',
  priceList = 'price_list',
  firstBid = 'first_bid',
}

export const LOOKUP_PRICE_SOURCE_TRANSLATIONS = {
  [ELookupPriceSource.lookupMethodSettings]: tt('shared.setManually'),
  [ELookupPriceSource.priceList]: tt('shared.setFromPriceList'),
  [ELookupPriceSource.firstBid]: tt('shared.firstBid'),
};

export const LOOKUP_PRICE_SOURCE_TRANSLATIONS_ORDER = {
  [ELookupPriceSource.priceList]: tt('order.shared.priceFromList'),
  [ELookupPriceSource.firstBid]: tt('order.shared.firstBid'),
};

export enum ELookupPriceType {
  personal = 'personal',
  fixed = 'fixed',
}

export const LOOKUP_STATE = {
  active: 'active',
  failed: 'failed',
  success: 'success',
  canceled: 'canceled',
};

export enum ELookupResultsStatus {
  pending = 'pending',
  approved = 'approved',
  annulled = 'annulled',
}

export enum ELookupState {
  active = 'active',
  success = 'success',
  failed = 'failed',
}

export enum EAutomationStatus {
  draft = 'draft',
  ready = 'ready',
}

export enum ESchemeStatus {
  bypass = 'bypass',
  active = 'active',
  draft = 'draft',
  archived = 'archived',
}

export enum EStepsSlug {
  rd = 'rd',
  so = 'so',
  to = 'to',
  qd = 'qd',
  fo = 'fo',
}

export const ACTIVE_STEP_BY_LOOKUP = {
  [ELookupMethod.reduction]: 'reduction',
  [ELookupMethod.spotOffer]: 'spot',
  [ELookupMethod.targetOffer]: 'target',
  [ELookupMethod.quota]: 'quota',
  [ELookupMethod.freeOffering]: 'free-offering',
};

export enum ELookupExecutorRestrictionKey {
  executorWithoutContract = 'executor_without_contract',
  allExecutorsWithoutContract = 'all_executors_without_contract',
}

export enum EProviderCredentialsSchemaItemType {
  text = 'text',
  password = 'password',
}

export enum EProviderFeatures {
  autobider = 'autobider',
  fetchOrders = 'fetch_orders',
  makeABid = 'make_a_bid',
  respond = 'respond',
}

export enum EAutoBiddingStopCause {
  orderClosed = 'order_closed',
  overLimit = 'over_limit',
  noOrder = 'no_order',
}

export enum EAtisuOrderType {
  auction = 'auction',
  rateRequest = 'rate-request',
  withoutBargaining = 'without-bargaining',
}

export enum EAutomaticProlongationUnit {
  minute = 'minute',
  hour = 'hour',
}

export const EXECUTORS_LOOKUPS_FEED_FILTER_ADDRESS_RADIUS_DEFAULT = 50;
export const EXECUTORS_LOOKUPS_FEED_FILTER_ADDRESS_RADIUS_MIN = 10;

export enum ECreateExecutorLookupForAgreementResponseError {
  notAvailableQuantityForLookups = 'DomainErrors::ExecutorsLookupContext::NotAvailableQuantityForLookups',
}
