import tt from '@/i18n/utils/translateText';

export const ROUTE_NAME = {
  contractors: 'contractors',
  contractorPersonalAccountSignIn: 'contractorPersonalAccountSignIn',
  contractorPersonalAccountUnsubscribe: 'contractorPersonalAccountUnsubscribe',
  contractorPersonalAccountRuns: 'contractorPersonalAccountRuns',
  contractorPersonalAccountRun: 'contractorPersonalAccountRun',
  contractorPersonalAccountIncidents: 'contractorPersonalAccountIncidents',
  digitalQueue: 'digitalQueue',
  digitalQueueBooking: 'digitalQueueBooking',
  digitalQueueSchedule: 'digitalQueueSchedule',
};

export enum EActionType {
  loading = 'loading',
  unloading = 'unloading',
  loadingAndUnloading = 'loadingAndUnloading',
}

export const ACTION_TYPE_TRANSLATION = {
  [EActionType.loading]: tt('shared.loading'),
  [EActionType.unloading]: tt('shared.unloading'),
  [EActionType.loadingAndUnloading]: tt('shared.loadingAndUnloading'),
};

export enum EBookingTarget {
  run = 'run',
  selfDelivery = 'self_delivery',
  manual = 'manual',
}

export enum EBookingState {
  detached = 'detached',
  inProgress = 'in_progress',
  done = 'done',
  lateArrival = 'late_arrival',
  lateDeparture = 'late_departure',
  reserved = 'reserved',
}

export enum EBookingStatusState {
  done = 'done', // Работа завершена
  inProgress = 'in_progress', // В процессе
  booked = 'booked', // Запланировано
  registrationIsNotCompleted = 'registration_is_not_completed', // Ожидает внесения данных (Резервирование)
  annulled = 'annulled', // Аннулировано
  registered = 'registered', // Зарегистрировано(бронь ГВ за ГП в ЦО)
}

export const BOOKING_STATE_LATE_STATUS_TRANSLATION = {
  [EBookingState.lateArrival]: tt('digitalQueues.shared.lateArrival'),
  [EBookingState.lateDeparture]: tt('digitalQueues.shared.lateDeparture'),
};

export enum EBookingOwnership {
  own = 'own',
  spouses = 'spouses',
  rent = 'rent',
  leasing = 'leasing',
  freeUsage = 'free_usage',
}

export const BOOKING_OWNERSHIP_TRANSLATION = {
  [EBookingOwnership.own]: tt('shared.own'),
  [EBookingOwnership.spouses]: tt('shared.spouses'),
  [EBookingOwnership.rent]: tt('shared.rent'),
  [EBookingOwnership.leasing]: tt('shared.leasing'),
  [EBookingOwnership.freeUsage]: tt('shared.freeUsage'),
};

export enum ERunsTableStatus {
  onExecution = 'on_execution',
  completed = 'completed',
  planned = 'planned',
}

export enum ERunsTableTypes {
  contractorPersonalAccountCurrentRunsTable = 'contractorPersonalAccountCurrentRunsTable',
  contractorPersonalAccountFinishedRunsTable = 'contractorPersonalAccountFinishedRunsTable',
  contractorPersonalAccountPlannedRunsTable = 'contractorPersonalAccountPlannedRunsTable',
}

export enum EContractorCategory {
  customer = 'customer',
  consigneeOrConsignor = 'consigneeOrConsignor',
  payer = 'payer',
}

export const CONTRACTOR_CATEGORY_TRANSLATIONS = {
  [EContractorCategory.customer]: 'customer',
  [EContractorCategory.consigneeOrConsignor]: 'consignee_or_consignor',
  [EContractorCategory.payer]: 'payer',
};

export const CONTRACTOR_CATEGORIES = [{
  name: tt('contractors.shared.customer'),
  id: EContractorCategory.customer,
}, {
  name: tt('contractors.shared.consigneeOrConsignor'),
  id: CONTRACTOR_CATEGORY_TRANSLATIONS[EContractorCategory.consigneeOrConsignor],
}, {
  name: tt('contractors.shared.payer'),
  id: EContractorCategory.payer,
}];

export enum EDigitalQueueTargetType {
  run = 'run', // рейс
  shipment = 'shipment', // самовывоз
  manual = 'manual', // создан вручную ГВ за ГП
  executionRequest = 'execution_request', // резервирования
}

export enum EContractorContextDomainErrorCode {
  contractorAlreadyUsesDigitalQueueOnAddress = 'DomainErrors::ContractorsContext::ContractorAlreadyUsesDigitalQueueOnAddress',
}
