import { EDigitalQueuePageType, EDigitalQueuePageView } from '@/contexts/contractorsContext/views/DigitalQueueInfoView/domains/constants';

import { TDigitalQueueSettingsState } from './types';

export const initialState = (): TDigitalQueueSettingsState => ({
  currentDigitalQueueId: '',
  openedGates: [],
  currentGates: [],
  pageType: EDigitalQueuePageType.booking,
  viewMode: EDigitalQueuePageView.timeline,
  permissions: null,
});
