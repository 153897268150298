import { computed, watch } from 'vue';

import { rebuildRejectedProtectedRoutes } from '@/router/utils/processRejectedRoute';
import { TCurrentTenantInfoResponse } from '@/contexts/accountingContext/domain/types';
import { EVatRate } from '@/domains/getVatRateOptions';
import redirectToInitialRoute from '@/domains/redirectToInitialRoute';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import useStore from '@/store/useStore';
import { ETenantPriceViewMode } from '@/contexts/accountingContext/domain/constants';

const useTenantServices = () => {
  const store = useStore();
  const isVatRateImprovmentsAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.vatRateImprovments));
  const priceViewMode = computed(() => store.state.tenants.currentTenantInfo?.legalEntity?.priceViewMode);

  const fetchCommonTenantData = () => Promise.all([
    store.dispatch('tenants/fetchCurrentTenantInfo'),
    store.dispatch('tenants/fetchReferenceData'),
    store.dispatch('notifications/fetchUnreadNotificationsCount'),
  ]);

  const resetTenantSensitiveStoreData = () => {
    store.commit('notifications/RESET_STATE');
  };

  const vatRateByTenantSettings = computed(() => {
    if (isVatRateImprovmentsAvailable.value) {
      return priceViewMode.value !== ETenantPriceViewMode.without_vat ? EVatRate.vat_20 : EVatRate.vat_none;
    }

    // При удалении FF isVatRateImprovmentsAvailable - выпилить отовсюду поле vatRateNoneMode(рудимент)
    const currentTenantInfo: TCurrentTenantInfoResponse = store.getters['tenants/currentTenantInfo'];
    const isVatRateNoneMode = currentTenantInfo?.settings.vatRateNoneMode;

    return isVatRateNoneMode ? EVatRate.vat_none : EVatRate.vat_20;
  });

  watch(() => store.state.tenants.currentTenant?.id, () => {
    if (!store.state.tenants.currentTenant?.id) return;
    fetchCommonTenantData().then(() => {
      redirectToInitialRoute();
    });
    rebuildRejectedProtectedRoutes();
    resetTenantSensitiveStoreData();
  });

  return {
    vatRateByTenantSettings,

    fetchCommonTenantData,
  };
};

export default useTenantServices;
