import tt from '@/i18n/utils/translateText';
import { EApprovalSubjectType } from '@/contexts/approvalsContext/domain/constants';
import { EOrderInfoDrawerTab } from '@/components/OrderInfoDrawer/constants';
import { joinString } from '@/utils';

import { ENotificationType } from './constants';

type TStringRenderer = (data: Record<string, string> | null) => string;

type TNotificationAction = {
  text: string,
  path: string | TStringRenderer,
};

type TNotificationSchemeItem = {
  template: string | TStringRenderer,
  actions: TNotificationAction[],
};
type TNotificationScheme = { [key in ENotificationType]: TNotificationSchemeItem };

type TOrderPayload = {
  orderIid?: string,
  folderSlug?: string,
  activeTab?: EOrderInfoDrawerTab,
  runUid?: string,
  executionRequestUid?: string,
};

/**
 * Все переходы и тексты взяты из таблицы "CTX-Y События для отправки уведомлений" https://confluence.trucker.group/pages/viewpage.action?pageId=2083553338
 */

const getApprovalPath = (approvalSubjectType?: string, approvalId?: string) => {
  if (!approvalSubjectType && !approvalId) return '';
  const idParam = approvalId ? `?id=${approvalId}` : '';
  const type = approvalSubjectType === EApprovalSubjectType.claim ? 'claims' : 'surcharges';

  return `/approvals/${type}${idParam}`;
};

const getExecutionRequestPath = (executionRequestUid?: string) => {
  const executionRequestUidParam = executionRequestUid ? `?execution_request_id=${executionRequestUid}` : '';
  return `/execution_requests${executionRequestUidParam}`;
};

const getOrderPath = (payload: TOrderPayload) => {
  const {
    orderIid,
    folderSlug,
    activeTab,
    runUid,
    executionRequestUid,
  } = payload;
  const orderIidParam = orderIid ? `orderIid=${orderIid}` : '';
  const folderSlugParam = folderSlug ? `folderId=${folderSlug}` : '';
  const activeTabParam = activeTab ? `activeTab=${activeTab}` : '';
  const runUidParam = runUid ? `runUid=${runUid}` : '';
  const executionRequestUidParam = executionRequestUid ? `executionRequestUid=${executionRequestUid}` : '';
  const params = joinString([orderIidParam, folderSlugParam, activeTabParam, runUidParam, executionRequestUidParam], '&');

  return `/orders/only_orders?${params}`;
};

const getNotificationScheme: (payload: Record<string, string> | null) => TNotificationScheme = (payload) => ({
  // partnershipsContext
  [ENotificationType.partnershipsContextNotifyAboutInvitation]: {
    template: tt('notification.scheme.text.invitation', { param: { partnerName: payload?.partnerName } }),
    actions: [{
      text: tt('notification.scheme.action.showRequest'),
      path: '/partnerships/invitations?filter=incoming',
    }],
  },
  [ENotificationType.partnershipsContextCustomersNotifyAboutNewPartnership]: {
    template: tt('notification.scheme.text.newPartnership', { param: { partnerName: payload?.partnerName } }),
    actions: [{
      text: tt('notification.scheme.action.partnershipDetails'),
      path: `/partnerships/partners/${payload?.partnershipId}`,
    }],
  },
  [ENotificationType.partnershipsContextExecutorsNotifyAboutNewPartnership]: {
    template: tt('notification.scheme.text.newPartnership', { param: { partnerName: payload?.partnerName } }),
    actions: [{
      text: tt('notification.scheme.action.partnershipDetails'),
      path: `/partnerships/partners/${payload?.partnershipId}`,
    }],
  },
  // orderingContext
  [ENotificationType.orderingContextCustomersNotifyAboutCompletedOrder]: {
    template: tt('notification.scheme.text.orderCompleted', { param: { orderIid: payload?.orderIid } }),
    actions: [{
      text: tt('notification.scheme.action.goToOrder'),
      path: getOrderPath({
        folderSlug: payload?.folderSlug,
        orderIid: payload?.orderIid,
      }),
    }],
  },
  // freightExecutionContext
  [ENotificationType.freightExecutionContextCustomersNotifyAboutCanceledByExecutorRequest]: {
    template: tt('notification.scheme.text.canceledByExecutorRequest', {
      param: {
        executorName: payload?.executorName,
        executionRequestUid: payload?.executionRequestUid,
      },
    }),
    actions: [{
      text: tt('notification.scheme.action.goToFreightRequest'),
      path: getOrderPath({
        folderSlug: payload?.folderSlug,
        orderIid: payload?.orderIid,
        activeTab: EOrderInfoDrawerTab.orderInfoDrawerExecutionRequests,
      }),
    }],
  },
  [ENotificationType.freightExecutionContextCustomersNotifyAboutCompletedExecutionRequest]: {
    template: tt('notification.scheme.text.completedRequest', { param: { executionRequestUid: payload?.executionRequestUid } }),
    actions: [{
      text: tt('notification.scheme.action.goToOrder'),
      path: getOrderPath({
        folderSlug: payload?.folderSlug,
        orderIid: payload?.orderIid,
      }),
    }],
  },
  [ENotificationType.freightExecutionContextCustomersNotifyAboutNewRequest]: {
    template: tt('notification.scheme.text.newRequest', {
      param: {
        executorName: payload?.executorName,
        executionRequestUid: payload?.executionRequestUid,
      },
    }),
    actions: [{
      text: tt('notification.scheme.action.goToFreightRequest'),
      path: getOrderPath({
        folderSlug: payload?.folderSlug,
        orderIid: payload?.orderIid,
        activeTab: EOrderInfoDrawerTab.orderInfoDrawerExecutionRequests,
      }),
    }],
  },
  [ENotificationType.freightExecutionContextExecutorsNotifyAboutCanceledByCustomerRequest]: {
    template: tt('notification.scheme.text.canceledByCustomerRequest', {
      param: {
        customerName: payload?.customerName,
        executionRequestUid: payload?.executionRequestUid,
      },
    }),
    actions: [{
      text: tt('notification.scheme.action.openFreightRequest'),
      path: getExecutionRequestPath(payload?.executionRequestUid),
    }],
  },
  [ENotificationType.freightExecutionContextExecutorsNotifyAboutChangedAdditionalInformationOnTransitPoint]: {
    template: tt('notification.scheme.text.changedAdditionalInformationOnTransitPoint', { param: { executionRequestUid: payload?.executionRequestUid } }),
    actions: [{
      text: tt('notification.scheme.action.openFreightRequest'),
      path: getExecutionRequestPath(payload?.executionRequestUid),
    }],
  },
  [ENotificationType.freightExecutionContextExecutorsNotifyAboutChangedAddressOnTransitPoint]: {
    template: tt('notification.scheme.text.changedAddressOnTransitPoint', { param: { executionRequestUid: payload?.executionRequestUid } }),
    actions: [{
      text: tt('notification.scheme.action.openFreightRequest'),
      path: getExecutionRequestPath(payload?.executionRequestUid),
    }],
  },
  [ENotificationType.freightExecutionContextExecutorsNotifyAboutChangedCargoInformation]: {
    template: tt('notification.scheme.text.changedCargoInformation', { param: { executionRequestUid: payload?.executionRequestUid } }),
    actions: [{
      text: tt('notification.scheme.action.openFreightRequest'),
      path: getExecutionRequestPath(payload?.executionRequestUid),
    }],
  },
  [ENotificationType.freightExecutionContextExecutorsNotifyAboutChangedDriverRequirements]: {
    template: tt('notification.scheme.text.changedDriverRequirements', { param: { executionRequestUid: payload?.executionRequestUid } }),
    actions: [{
      text: tt('notification.scheme.action.openFreightRequest'),
      path: getExecutionRequestPath(payload?.executionRequestUid),
    }],
  },
  [ENotificationType.freightExecutionContextExecutorsNotifyAboutChangedExecutionRequestRoute]: {
    template: tt('notification.scheme.text.changedExecutionRequestRoute', { param: { executionRequestUid: payload?.executionRequestUid } }),
    actions: [{
      text: tt('notification.scheme.action.openFreightRequest'),
      path: getExecutionRequestPath(payload?.executionRequestUid),
    }],
  },
  [ENotificationType.freightExecutionContextExecutorsNotifyAboutChangedExpectedArrivalTimeOnTransitPoint]: {
    template: tt('notification.scheme.text.changedExpectedArrivalTimeOnTransitPoint', { param: { executionRequestUid: payload?.executionRequestUid } }),
    actions: [{
      text: tt('notification.scheme.action.openFreightRequest'),
      path: getExecutionRequestPath(payload?.executionRequestUid),
    }],
  },
  [ENotificationType.freightExecutionContextExecutorsNotifyAboutChangedInformationOnTransitPoint]: {
    template: tt('notification.scheme.text.changedInformationOnTransitPoint', { param: { executionRequestUid: payload?.executionRequestUid } }),
    actions: [{
      text: tt('notification.scheme.action.openFreightRequest'),
      path: getExecutionRequestPath(payload?.executionRequestUid),
    }],
  },
  [ENotificationType.freightExecutionContextExecutorsNotifyAboutChangedVehicleRequirements]: {
    template: tt('notification.scheme.text.changedVehicleRequirements', { param: { executionRequestUid: payload?.executionRequestUid } }),
    actions: [{
      text: tt('notification.scheme.action.openFreightRequest'),
      path: getExecutionRequestPath(payload?.executionRequestUid),
    }],
  },
  [ENotificationType.freightExecutionContextExecutorsNotifyAboutChangedPublicComment]: {
    template: tt('notification.scheme.text.changedPublicComment', { param: { executionRequestUid: payload?.executionRequestUid } }),
    actions: [{
      text: tt('notification.scheme.action.openFreightRequest'),
      path: getExecutionRequestPath(payload?.executionRequestUid),
    }],
  },
  [ENotificationType.freightExecutionContextApprovalsNotifyAboutPending]: {
    template: tt('notification.scheme.text.approvalPending', { param: { approvalName: payload?.approvalDescription } }),
    actions: [{
      text: tt('notification.scheme.action.openApproval'),
      path: getApprovalPath(payload?.approvalSubjectType, payload?.approvalId),
    }],
  },
  [ENotificationType.freightExecutionContextApprovalsNotifyAboutApproval]: {
    template: tt('notification.scheme.text.approvalApproved', { param: { approvalName: payload?.approvalDescription } }),
    actions: [{
      text: tt('notification.scheme.action.openApproval'),
      path: getApprovalPath(payload?.approvalSubjectType, payload?.approvalId),
    }],
  },
  [ENotificationType.freightExecutionContextApprovalsNotifyAboutRevoking]: {
    template: tt('notification.scheme.text.approvalRevoking', { param: { approvalName: payload?.approvalDescription } }),
    actions: [{
      text: tt('notification.scheme.action.openApproval'),
      path: getApprovalPath(payload?.approvalSubjectType, payload?.approvalId),
    }],
  },
  [ENotificationType.freightExecutionContextApprovalsNotifyAboutMarkedAsInRevision]: {
    template: tt('notification.scheme.text.approvalMarkedAsInRevision', { param: { approvalName: payload?.approvalDescription } }),
    actions: [{
      text: tt('notification.scheme.action.openApproval'),
      path: getApprovalPath(payload?.approvalSubjectType, payload?.approvalId),
    }],
  },
  [ENotificationType.freightExecutionContextApprovalsNotifyAboutRejection]: {
    template: tt('notification.scheme.text.approvalMarkedAsInRejection', { param: { approvalName: payload?.approvalDescription } }),
    actions: [{
      text: tt('notification.scheme.action.openApproval'),
      path: getApprovalPath(payload?.approvalSubjectType, payload?.approvalId),
    }],
  },
  [ENotificationType.freightExecutionContextExecutorsNotifyAboutNewExecutionRequest]: {
    template: tt('notification.scheme.text.newExecutionRequest', { param: { executionRequestUid: payload?.executionRequestUid } }),
    actions: [{
      text: tt('notification.scheme.action.goToFreightRequest'),
      path: getExecutionRequestPath(payload?.executionRequestUid),
    }],
  },
  // runExecutionContext
  [ENotificationType.runExecutionContextCustomersNotifyAboutPlannedRun]: {
    template: tt('notification.scheme.text.plannedRun', { param: { executionRequestUid: payload?.executionRequestUid } }),
    actions: [{
      text: tt('notification.scheme.action.goToFreightRequest'),
      path: getOrderPath({
        folderSlug: payload?.folderSlug,
        orderIid: payload?.orderIid,
        activeTab: EOrderInfoDrawerTab.orderInfoDrawerExecutionRequests,
      }),
    }],
  },
  [ENotificationType.runExecutionContextCustomersNotifyAboutReplacedTransport]: {
    template: tt('notification.scheme.text.replacedTransport', { param: { executionRequestUid: payload?.executionRequestUid } }),
    actions: [{
      text: tt('notification.scheme.action.goToFreightRequest'),
      path: getOrderPath({
        folderSlug: payload?.folderSlug,
        orderIid: payload?.orderIid,
        activeTab: EOrderInfoDrawerTab.orderInfoDrawerExecutionRequests,
      }),
    }],
  },
  [ENotificationType.runExecutionContextCustomersNotifyAboutNewRunIncident]: {
    template: tt('notification.scheme.text.newRunIncident', { param: { runUid: payload?.runUid } }),
    actions: [{
      text: tt('notification.scheme.action.goToRun'),
      path: getOrderPath({
        folderSlug: payload?.folderSlug,
        orderIid: payload?.orderIid,
        activeTab: EOrderInfoDrawerTab.orderInfoDrawerExecutionRequests,
        runUid: payload?.runUid,
        executionRequestUid: payload?.executionRequestUid,
      }),
    }],
  },
  [ENotificationType.runExecutionContextCustomersNotifyAboutResolvedRunIncident]: {
    template: tt('notification.scheme.text.resolvedRunIncident', { param: { runUid: payload?.runUid } }),
    actions: [{
      text: tt('notification.scheme.action.goToRun'),
      path: getOrderPath({
        folderSlug: payload?.folderSlug,
        orderIid: payload?.orderIid,
        activeTab: EOrderInfoDrawerTab.orderInfoDrawerExecutionRequests,
        runUid: payload?.runUid,
        executionRequestUid: payload?.executionRequestUid,
      }),
    }],
  },
  [ENotificationType.runExecutionContextCustomersNotifyAboutCompletedRun]: {
    template: tt('notification.scheme.text.completedRun', {
      param: {
        runUid: payload?.runUid,
        executionRequestUid: payload?.executionRequestUid,
      },
    }),
    actions: [{
      text: tt('notification.scheme.action.goToRun'),
      path: getOrderPath({
        folderSlug: payload?.folderSlug,
        orderIid: payload?.orderIid,
        activeTab: EOrderInfoDrawerTab.orderInfoDrawerExecutionRequests,
        runUid: payload?.runUid,
        executionRequestUid: payload?.executionRequestUid,
      }),
    }],
  },
  [ENotificationType.runExecutionContextCustomersNotifyAboutCompletionOfCanceledRun]: {
    template: tt('notification.scheme.text.completionOfCanceledRun', { param: { runUid: payload?.runUid } }),
    actions: [{
      text: tt('notification.scheme.action.goToRun'),
      path: getOrderPath({
        folderSlug: payload?.folderSlug,
        orderIid: payload?.orderIid,
        activeTab: EOrderInfoDrawerTab.orderInfoDrawerExecutionRequests,
        runUid: payload?.runUid,
        executionRequestUid: payload?.executionRequestUid,
      }),
    }],
  },
  [ENotificationType.runExecutionContextCustomersNotifyAboutCancelationOfCompletedRun]: {
    template: tt('notification.scheme.text.cancelationOfCompletedRun', { param: { runUid: payload?.runUid } }),
    actions: [{
      text: tt('notification.scheme.action.goToRun'),
      path: getOrderPath({
        folderSlug: payload?.folderSlug,
        orderIid: payload?.orderIid,
        activeTab: EOrderInfoDrawerTab.orderInfoDrawerExecutionRequests,
        runUid: payload?.runUid,
        executionRequestUid: payload?.executionRequestUid,
      }),
    }],
  },
  [ENotificationType.runExecutionContextCustomersNotifyAboutCanceledRun]: {
    template: tt('notification.scheme.text.canceledRun', {
      param: {
        runUid: payload?.runUid,
        executionRequestUid: payload?.executionRequestUid,
      },
    }),
    actions: [{
      text: tt('notification.scheme.action.goToRun'),
      path: getOrderPath({
        folderSlug: payload?.folderSlug,
        orderIid: payload?.orderIid,
        activeTab: EOrderInfoDrawerTab.orderInfoDrawerExecutionRequests,
        runUid: payload?.runUid,
        executionRequestUid: payload?.executionRequestUid,
      }),
    }],
  },
  [ENotificationType.freightExecutionContextExecutorsNotifyAboutChangedExtraServicePrice]: {
    template: tt('notification.scheme.text.changedExtraServicePrice', { param: { executionRequestUid: payload?.executionRequestUid } }),
    actions: [{
      text: tt('notification.scheme.action.goToFreightRequest'),
      path: getExecutionRequestPath(payload?.executionRequestUid),
    }],
  },
  [ENotificationType.runExecutionContextExecutorsNotifyAboutPlannedRun]: {
    template: tt('notification.scheme.text.plannedRun', { param: { executionRequestUid: payload?.executionRequestUid } }),
    actions: [{
      text: tt('notification.scheme.action.goToRuns'),
      path: getExecutionRequestPath(payload?.executionRequestUid),
    }],
  },
  [ENotificationType.runExecutionContextExecutorsNotifyAboutReplacedTransport]: {
    template: tt('notification.scheme.text.replacedTransport', { param: { executionRequestUid: payload?.executionRequestUid } }),
    actions: [{
      text: tt('notification.scheme.action.goToRuns'),
      path: getExecutionRequestPath(payload?.executionRequestUid),
    }],
  },
  [ENotificationType.runExecutionContextExecutorsNotifyAboutRunOnDocumentsPreparation]: {
    template: tt('notification.scheme.text.runOnDocumentsPreparation', { param: { runUid: payload?.runUid } }),
    actions: [{
      text: tt('notification.scheme.action.goToRun'),
      path: getExecutionRequestPath(payload?.executionRequestUid),
    }],
  },
  [ENotificationType.runExecutionContextExecutorsNotifyAboutFinishedRunExecution]: {
    template: tt('notification.scheme.text.finishedRunExecution', {
      param: {
        runUid: payload?.runUid,
        executionRequestUid: payload?.executionRequestUid,
      },
    }),
    actions: [{
      text: tt('notification.scheme.action.goToRun'),
      path: getExecutionRequestPath(payload?.executionRequestUid),
    }],
  },
  [ENotificationType.runExecutionContextExecutorsNotifyAboutNewRunIncident]: {
    template: tt('notification.scheme.text.newRunIncident', { param: { runUid: payload?.runUid } }),
    actions: [{
      text: tt('notification.scheme.action.goToRun'),
      path: getExecutionRequestPath(payload?.executionRequestUid),
    }],
  },
  [ENotificationType.runExecutionContextExecutorsNotifyAboutResolvedRunIncident]: {
    template: tt('notification.scheme.text.resolvedRunIncident', { param: { runUid: payload?.runUid } }),
    actions: [{
      text: tt('notification.scheme.action.goToRun'),
      path: getExecutionRequestPath(payload?.executionRequestUid),
    }],
  },
  [ENotificationType.runExecutionContextExecutorsNotifyAboutCompletedRun]: {
    template: tt('notification.scheme.text.finishedRun', {
      param: {
        runUid: payload?.runUid,
        executionRequestUid: payload?.executionRequestUid,
      },
    }),
    actions: [{
      text: tt('notification.scheme.action.goToRuns'),
      path: getExecutionRequestPath(payload?.executionRequestUid),
    }],
  },
  [ENotificationType.freightExecutionContextExecutorsNotifyAboutChangedPrice]: {
    template: tt('notification.scheme.text.changedPrice', { param: { executionRequestUid: payload?.executionRequestUid } }),
    actions: [{
      text: tt('notification.scheme.action.goToFreightRequest'),
      path: getExecutionRequestPath(payload?.executionRequestUid),
    }],
  },
  // targetOffersContext
  [ENotificationType.targetOffersContextCustomersNotifyAboutAcceptedTargetOffer]: {
    template: tt('notification.scheme.text.acceptedTargetOffer', { param: { executorName: payload?.executorName } }),
    actions: [{
      text: tt('notification.scheme.action.goToOffer'),
      path: getOrderPath({
        folderSlug: payload?.folderSlug,
        orderIid: payload?.orderIid,
        activeTab: EOrderInfoDrawerTab.orderInfoDrawerLookups,
      }),
    }],
  },
  [ENotificationType.targetOffersContextCustomersNotifyAboutRejectedTargetOffer]: {
    template: tt('notification.scheme.text.rejectedTargetOffer', { param: { executorName: payload?.executorName } }),
    actions: [{
      text: tt('notification.scheme.action.goToOffer'),
      path: getOrderPath({
        folderSlug: payload?.folderSlug,
        orderIid: payload?.orderIid,
        activeTab: EOrderInfoDrawerTab.orderInfoDrawerLookups,
      }),
    }],
  },
  [ENotificationType.targetOffersContextCustomersNotifyAboutExpiredTargetOffer]: {
    template: tt('notification.scheme.text.expiredTargetOffer', { param: { executorName: payload?.executorName } }),
    actions: [{
      text: tt('notification.scheme.action.goToOffer'),
      path: getOrderPath({
        folderSlug: payload?.folderSlug,
        orderIid: payload?.orderIid,
        activeTab: EOrderInfoDrawerTab.orderInfoDrawerLookups,
      }),
    }],
  },
  [ENotificationType.targetOffersContextExecutorsNotifyAboutNewTargetOffer]: {
    template: tt('notification.scheme.text.newTargetOffer'),
    actions: [{
      text: tt('notification.scheme.action.goToOffer'),
      path: `/executors_lookups/feed?filter=new&drawerOrderId=${payload?.executorsLookupId}&lookupMethodType=TargetOffersContext::TargetOffer`,
    }],
  },
  // spotOffersContext
  [ENotificationType.spotOffersContextCustomersNotifyAboutAcceptedSpotOffer]: {
    template: tt('notification.scheme.text.acceptedSpotOffer'),
    actions: [{
      text: tt('notification.scheme.action.goToAuction'),
      path: getOrderPath({
        folderSlug: payload?.folderSlug,
        orderIid: payload?.orderIid,
        activeTab: EOrderInfoDrawerTab.orderInfoDrawerLookups,
      }),
    }],
  },
  [ENotificationType.spotOffersContextCustomersNotifyAboutExpiredSpotOffer]: {
    template: tt('notification.scheme.text.expiredSpotOffer'),
    actions: [{
      text: tt('notification.scheme.action.goToAuction'),
      path: getOrderPath({
        folderSlug: payload?.folderSlug,
        orderIid: payload?.orderIid,
        activeTab: EOrderInfoDrawerTab.orderInfoDrawerLookups,
      }),
    }],
  },
  [ENotificationType.spotOffersContextExecutorsNotifyAboutPublishedSpotOffer]: {
    template: tt('notification.scheme.text.publishedSpotOffer'),
    actions: [{
      text: tt('notification.scheme.action.viewConditions'),
      path: `/executors_lookups/feed?filter=new&drawerOrderId=${payload?.executorsLookupId}&lookupMethodType=SpotOffersContext::SpotOffer`,
    }],
  },
  // reductionsContext
  [ENotificationType.reductionsContextContextCustomersNotifyAboutExpiredReduction]: {
    template: tt('notification.scheme.text.expiredReduction'),
    actions: [{
      text: tt('notification.scheme.action.goToAuction'),
      path: getOrderPath({
        folderSlug: payload?.folderSlug,
        orderIid: payload?.orderIid,
        activeTab: EOrderInfoDrawerTab.orderInfoDrawerLookups,
      }),
    }],
  },
  [ENotificationType.reductionsContextContextCustomersNotifyAboutPlacedBid]: {
    template: tt('notification.scheme.text.placedBid'),
    actions: [{
      text: tt('notification.scheme.action.goToAuction'),
      path: getOrderPath({
        folderSlug: payload?.folderSlug,
        orderIid: payload?.orderIid,
        activeTab: EOrderInfoDrawerTab.orderInfoDrawerLookups,
      }),
    }],
  },
  [ENotificationType.reductionsContextCustomersNotifyAboutRevokedBid]: {
    template: tt('notification.scheme.text.revokedBid'),
    actions: [{
      text: tt('notification.scheme.action.goToAuction'),
      path: getOrderPath({
        folderSlug: payload?.folderSlug,
        orderIid: payload?.orderIid,
        activeTab: EOrderInfoDrawerTab.orderInfoDrawerLookups,
      }),
    }],
  },
  [ENotificationType.reductionsContextCustomersNotifyAboutExpiredBid]: {
    template: tt('notification.scheme.text.revokedBid'),
    actions: [{
      text: tt('notification.scheme.action.goToAuction'),
      path: getOrderPath({
        folderSlug: payload?.folderSlug,
        orderIid: payload?.orderIid,
        activeTab: EOrderInfoDrawerTab.orderInfoDrawerLookups,
      }),
    }],
  },
  [ENotificationType.reductionsContextContextCustomersNotifyAboutFinishedByTimeReduction]: {
    template: tt('notification.scheme.text.finishedByTimeReduction'),
    actions: [{
      text: tt('notification.scheme.action.goToAuction'),
      path: getOrderPath({
        folderSlug: payload?.folderSlug,
        orderIid: payload?.orderIid,
        activeTab: EOrderInfoDrawerTab.orderInfoDrawerLookups,
      }),
    }],
  },
  [ENotificationType.reductionsContextContextExecutorsNotifyAboutReactivatedBid]: {
    template: tt('notification.scheme.text.reactivatedBid'),
    actions: [{
      text: tt('notification.scheme.action.goToAuction'),
      path: `/executors_lookups/feed?filter=new&drawerOrderId=${payload?.executorsLookupId}&lookupMethodType=ReductionsContext::Reduction`,
    }],
  },
  [ENotificationType.reductionsContextContextExecutorsNotifyAboutKnockedOutBid]: {
    template: tt('notification.scheme.text.knockedOutBid'),
    actions: [{
      text: tt('notification.scheme.action.goToAuction'),
      path: `/executors_lookups/feed?filter=new&drawerOrderId=${payload?.executorsLookupId}&lookupMethodType=ReductionsContext::Reduction`,
    }],
  },
  [ENotificationType.reductionsContextContextExecutorsNotifyAboutPublishedReduction]: {
    template: tt('notification.scheme.text.publishedReduction'),
    actions: [{
      text: tt('notification.scheme.action.goToAuction'),
      path: `/executors_lookups/feed?filter=new&drawerOrderId=${payload?.executorsLookupId}&lookupMethodType=ReductionsContext::Reduction`,
    }],
  },
  [ENotificationType.reductionsContextContextExecutorsNotifyAboutManuallyFinishedReduction]: {
    template: tt('notification.scheme.text.manuallyFinishedReduction'),
    actions: [{
      text: tt('notification.scheme.action.goToAuction'),
      path: `/executors_lookups/feed?filter=new&drawerOrderId=${payload?.executorsLookupId}&lookupMethodType=ReductionsContext::Reduction`,
    }],
  },
  [ENotificationType.reductionsContextContextExecutorsNotifyAboutFinishedByTimeReduction]: {
    template: tt('notification.scheme.text.finishedByTimeOurReduction'),
    actions: [{
      text: tt('notification.scheme.action.goToAuction'),
      path: `/executors_lookups/feed?filter=new&drawerOrderId=${payload?.executorsLookupId}&lookupMethodType=ReductionsContext::Reduction`,
    }],
  },
  // freeOfferingsContext
  [ENotificationType.freeOfferingsContextExecutorsNotifyAboutFreeOfferingOrganized]: {
    template: tt('notification.scheme.text.organizedFreeOffering'),
    actions: [{
      text: tt('notification.scheme.action.goToAuction'),
      path: `/executors_lookups/feed?filter=new&drawerOrderId=${payload?.executorsLookupId}&lookupMethodType=FreeOfferingsContext::FreeOffering`,
    }],
  },
  // executorsLookupContext
  [ENotificationType.executorsLookupContextCustomersNotifyAboutFailedStageStart]: {
    template: tt('notification.scheme.text.failedStageStart'),
    actions: [{
      text: tt('notification.scheme.action.goToLookups'),
      path: getOrderPath({
        folderSlug: payload?.folderSlug,
        orderIid: payload?.orderIid,
        activeTab: EOrderInfoDrawerTab.orderInfoDrawerLookups,
      }),
    }],
  },
  [ENotificationType.executorsLookupContextCustomersNotifyAboutFailedAutomationStepExecution]: {
    template: tt('notification.scheme.text.failedAutomationStepExecution'),
    actions: [{
      text: tt('notification.scheme.action.goToLookups'),
      path: getOrderPath({
        folderSlug: payload?.folderSlug,
        orderIid: payload?.orderIid,
        activeTab: EOrderInfoDrawerTab.orderInfoDrawerLookups,
      }),
    }],
  },
  // tendersContext
  [ENotificationType.tendersContextExecutorsNotifyAboutTenderStageStart]: {
    template: tt('notification.scheme.text.tenderStageStart', {
      param: {
        customerName: payload?.customerName,
        stageType: payload?.stageType,
        dateStart: payload?.startsAt,
        dateEnd: payload?.endsAt,
      },
    }),
    actions: [{
      text: tt('notification.scheme.action.participate'),
      path: `/tenders/${payload?.tenderStageId}`,
    }],
  },
  [ENotificationType.tendersContextExecutorsNotifyAboutTenderStageCancel]: {
    template: tt('notification.scheme.text.tenderStageCancel', {
      param: {
        customerName: payload?.customerName,
        stageType: payload?.stageType,
        dateStart: payload?.startsAt,
        dateEnd: payload?.endsAt,
      },
    }),
    actions: [],
  },
  [ENotificationType.executorsLookupContextExecutorsNotifyAboutNewLookups]: {
    template: tt('notification.scheme.text.newLookups', { param: { filterName: payload?.filterTitle } }),
    actions: [{
      text: tt('notification.scheme.action.goToAuction'),
      path: `/executors_lookups/feed?filter=new&filterId=${payload?.filterId}`,
    }],
  },
});

export default getNotificationScheme;
