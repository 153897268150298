<template>
  <div
    class="localization-button"
    @click.stop="handleChangeLocale"
  >
    <component
      :is="suggestedIcon"
      class="localization-button__icon"
    />
    <span class="localization-button__label">
      {{ tt(`sider.localization.${suggestedLocale}`) }}
    </span>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

import FlagRuSvg from '@/assets/svg/others/flag-ru.svg';
import FlagEnSvg from '@/assets/svg/others/flag-en.svg';
import tt from '@/i18n/utils/translateText';
import useStore from '@/store/useStore';
import { ELocale } from '@/domains/constants';

export default defineComponent({
  name: 'LocalizationButton',
  setup() {
    const store = useStore();

    const currentLocale = computed(() => store.getters['i18n/currentLocale']);
    const suggestedLocale = computed(() => (currentLocale.value === ELocale.en ? ELocale.ru : ELocale.en));
    const suggestedIcon = computed(() => (currentLocale.value === ELocale.en ? FlagRuSvg : FlagEnSvg));

    const handleChangeLocale = () => {
      if (currentLocale.value === ELocale.en) {
        store.dispatch('i18n/setCurrentLocale', ELocale.ru);
      } else {
        store.dispatch('i18n/setCurrentLocale', ELocale.en);
      }
    };

    return {
      suggestedLocale,
      suggestedIcon,

      handleChangeLocale,
      tt,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
