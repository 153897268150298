import {
  EContractDirectionApplicableFor,
  EContractsTableStatus,
  TContract,
  TContractsResponse,
  TPartnershipSide,
} from '@/contexts/partnershipsContext/domain/types';
import { TDirection } from '@/contexts/directionsContext/domain/types';
import { TCustomizationFields } from '@/types';

export enum EContractsTableColumnKey {
  iid = 'iid',
  id = 'id',
  name = 'name',
  partnerType = 'partnerType',
  payer = 'payer',
  servicer = 'servicer',
  servicerCompany = 'servicerCompany',
  status = 'status',
  period = 'period',
}

export enum EContractsTableFilter {
  all = 'all',
  active = 'active',
  inactive = 'inactive',
  archived = 'archived',
}

export type TState = {
  data: TContract[] | TContractsResponse | null,
};

export type TContractsTableData = {
  payer: string,
  period: string,
  payerExternalNumber: string | null | undefined,
  contractType: string,
  id: string,
  name: string,
  number: string,
  applicableFor: (typeof EContractDirectionApplicableFor)[keyof typeof EContractDirectionApplicableFor],
  directions: TDirection[],
  partnershipId: string,
  servicer: TPartnershipSide,
  client: TPartnershipSide,
  status: EContractsTableStatus,
  startsOn: string,
  endsOn: string,
  signedOn: string,
  customAttributes: TCustomizationFields,
};
