<template>
  <BillingPlanSelectionCard
    :title="tt(('billing.planCode.basic'))"
    :subtitle="tt('billing.modal.ifYouAreTheSenderOrRecipientOfTheGoods')"
    :planCode="EBillingPlanCode.shipper"
    withoutPrice
    style="width: 100%;"
    @change="$emit('change', $event)"
  >
    <template #description>
      <li>{{ tt('billing.modal.trackingAndControlOfVehicleArrivalAtLoadingAndUnloadingPoints') }}</li>
      <li>{{ tt('billing.modal.recordingTheDateAndTimeOfArrivalAndDepartureOfTheVehicle') }}</li>
      <li>{{ tt('billing.modal.creationAndSigningOfEtRN') }}</li>
    </template>
  </BillingPlanSelectionCard>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import tt from '@/i18n/utils/translateText';
import { EBillingPlanCode } from '@/contexts/billingContext/domain/constants';

import BillingPlanSelectionCard from '../BillingPlanSelectionCard/index.vue';

export default defineComponent({
  name: 'BillingCardsForShipper',
  components: { BillingPlanSelectionCard },
  emits: ['change'],
  setup() {
    return {
      EBillingPlanCode,

      tt,
    };
  },
});
</script>
