import {
  getDuration,
  getDateDiff,
  getNowMs,
} from '@/utils/dateUtils';
import joinString from '@/utils/joinString';
import { TDateAppFormat } from '@/types';
import tt from '@/i18n/utils/translateText';
import { TExpirationTimerFormat } from '@/composables/useExpirationTimer';

const generateTimeUnits = (firstDate: number | TDateAppFormat | string, secondDate: number | TDateAppFormat) => {
  const dateDiff = getDateDiff(firstDate, secondDate);
  const duration = getDuration(dateDiff);

  return {
    seconds: Math.abs(duration.seconds()),
    minutes: Math.abs(duration.minutes()),
    hours: Math.abs(duration.hours()),
    days: Math.floor(Math.abs(duration.asDays())),
    minusSymbol: dateDiff <= 0 ? '-' : '',
  };
};

const generateDatesIntervalInDigitalFormat = (firstDate: number | TDateAppFormat | string, secondDate: number | TDateAppFormat, withDays: boolean = false) => {
  const transformTimeUnitToDigitalFormat = (time: number) => (time.toString().length === 1 ? `0${time}` : time);

  const {
    days,
    seconds,
    minutes,
    hours,
    minusSymbol,
  } = generateTimeUnits(firstDate, secondDate);

  const minutesString = transformTimeUnitToDigitalFormat(minutes);
  const secondsString = transformTimeUnitToDigitalFormat(seconds);

  if (withDays) {
    const daysString = transformTimeUnitToDigitalFormat(days);
    const hoursString = transformTimeUnitToDigitalFormat(hours);
    return `${minusSymbol}${daysString}:${hoursString}:${minutesString}:${secondsString}`;
  }

  const hoursString = transformTimeUnitToDigitalFormat(hours + days * 24);
  return `${minusSymbol}${hoursString}:${minutesString}:${secondsString}`;
};

const generateDatesIntervalInLetterFormat = (firstDate: number | TDateAppFormat | string, secondDate: number | TDateAppFormat) => {
  const {
    days,
    seconds,
    minutes,
    hours,
    minusSymbol,
  } = generateTimeUnits(firstDate, secondDate);

  const secondsString = seconds ? `${seconds}${tt('shared.shortSeconds')}` : '';
  const minutesString = minutes ? `${minutes}${tt('shared.shortMinutes')}` : '';
  const hoursString = hours ? `${hours}${tt('shared.shortHours')}` : '';
  const daysString = days ? `${days}${tt('shared.shortDays')}` : '';

  let values: string[] = [];

  if (days > 0) {
    values = values.concat([daysString, hoursString]);
  } else if (hours > 0) {
    values = values.concat([hoursString, minutesString]);
  } else {
    values = values.concat([minutesString, secondsString]);
  }

  return joinString([minusSymbol, ...values]);
};

const getDatesInterval = (
  firstDate: number | TDateAppFormat | string,
  secondDate: number | TDateAppFormat = getNowMs(),
  format: TExpirationTimerFormat = 'letter',
) => {
  if (!firstDate) return '';

  // Формат вида: hh:mm:ss
  if (format === 'digital') {
    return generateDatesIntervalInDigitalFormat(firstDate, secondDate);
  }

  // Формат вида: dd:hh:mm:ss
  if (format === 'digitalWithDays') {
    return generateDatesIntervalInDigitalFormat(firstDate, secondDate, true);
  }

  // Формат вида: Nд Nч Nм Nс
  if (format === 'letter') {
    return generateDatesIntervalInLetterFormat(firstDate, secondDate);
  }
};

export default getDatesInterval;
