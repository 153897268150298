import { TGetDateDiff } from '@/utils/dateUtils/domains/types';
// eslint-disable-next-line no-restricted-imports
import dayjs from '@/utils/dateUtils/dayjsConfig';
import { TDateAppFormat } from '@/types';

const getDateDiff: TGetDateDiff = (
  firstDate: number | TDateAppFormat | null | undefined | string,
  // eslint-disable-next-line default-param-last
  secondDate: number | TDateAppFormat | null | undefined | string = dayjs(),
  timeZone?: string,
) => ((timeZone ? dayjs.tz(firstDate, timeZone) : dayjs(firstDate))
  .diff(timeZone ? dayjs.tz(secondDate, timeZone) : dayjs(secondDate)));

export default getDateDiff;
