import { computed } from 'vue';

import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import useStore from '@/store/useStore';
import { EBillingPlanCode } from '@/contexts/billingContext/domain/constants';

export const useView = (planCode: EBillingPlanCode) => {
  const store = useStore();

  const currentTenantInfo = computed(() => store.state.tenants.currentTenantInfo);

  const isBillingTrialPeriodInformationAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.billingTrialPeriodInformation),
  );

  const hasActiveSubscription = computed(() => currentTenantInfo.value?.subscriptions?.length !== 0);

  const hasPastSubscriptions = computed(() => currentTenantInfo.value?.hasPastSubscriptions || false);

  const isBillingTrialPeriodInfoShown = computed(
    () => isBillingTrialPeriodInformationAvailable.value
    && !hasPastSubscriptions.value
    && !hasActiveSubscription.value
    && (planCode === EBillingPlanCode.carrierBasic
      || planCode === EBillingPlanCode.carrierExtended
      || planCode === EBillingPlanCode.freightForwarderBasic
      || planCode === EBillingPlanCode.freightForwarderExtended
    ),
  );
  return { isBillingTrialPeriodInfoShown };
};
